import { propsWithClassNames } from '@genie-fintech/ui/functions'
import { status, StatusVariants } from '@genie-fintech/ui/style/element'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { FC, PropsWithChildren } from 'react'

type Props = TagsNoRef<'span'> & {
  styleVariants: StatusVariants
}

const StatusBadge: FC<PropsWithChildren<Props>> = ({
  styleVariants,
  children,
  ...props
}) => {
  return (
    //  hack to calculate for status font size
    <span style={{ fontSize: '1rem' }}>
      <span {...propsWithClassNames(props, status(styleVariants))}>
        {children}
      </span>
    </span>
  )
}

export default StatusBadge
