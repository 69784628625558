import PublishUnpublishButton from '$components/PublishUnpublishButton'
import { useAppDetailStore } from '$hooks/stores'
import { flexColumn } from '$styles/common.css'
import { Lock } from 'lucide-react'
import { container, desc, icon, title } from './styles.css'
import { status } from '@genie-fintech/ui/style/element'
import Header from '$layouts/LayoutWithSidebar/Header'

const PublishAppSetting = () => {
  const is_published = useAppDetailStore(state => state.appDetail?.is_published)

  return (
    <Header className={container}>
      <span className={icon}>
        <Lock size={20} />
      </span>

      <article className={flexColumn} style={{ flex: 1, gap: 4 }}>
        <p className={title}>Publish Application Setting</p>
        <p className={desc}>
          Publish application settings to manage and configure system
          preferences efficiently.
        </p>
      </article>

      <span
        className={status({
          type: 'faint',
          size: 'tiny',
          kind: is_published ? 'success' : 'neutral'
        })}
      >
        {is_published ? 'PUBLISHED' : 'UNPUBLISHED'}
      </span>

      <PublishUnpublishButton is_published={is_published} />
    </Header>
  )
}

export default PublishAppSetting
