import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { ArrowUpRight, ChevronDown, Trash2 } from 'lucide-react'
import { FC, useCallback } from 'react'
import { drawerContent, drawerContentItem } from './styles.css'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

type MenuLinkButtonProps = {
  label: string
  routeName: ROUTE_NAMES
  onClick: (routeName: ROUTE_NAMES) => void
}
const MenuLinkButton: FC<MenuLinkButtonProps> = ({
  label,
  routeName,
  onClick
}) => {
  return (
    <button className={drawerContentItem} onClick={() => onClick(routeName)}>
      {label}
      <ArrowUpRight size={20} />
    </button>
  )
}

const Line = () => <hr style={{ borderColor: colors.neutral[10] }} />

type Props = {
  onTerminatUser: VoidFunction
}
const HeaderDropdownAction: FC<Props> = ({ onTerminatUser }) => {
  const { route } = useRouteSummary()
  const { userId } = route.params

  const onClick = useCallback(
    (routeName: ROUTE_NAMES) => {
      redirect(routeName, {
        params: { serviceUserId: userId }
      })
    },
    [userId]
  )

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <article className="cursor-pointer">
          <Button className="pointer-events-none">
            Actions
            <ChevronDown size={16} />
          </Button>
        </article>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={drawerContent}
          sideOffset={5}
          align="end"
        >
          <article className="flex flex-col gap-2.5 py-2">
            <MenuLinkButton
              label="Access Applications"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS}
              onClick={onClick}
            />

            <MenuLinkButton
              label="View Full Profile"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Devices Login"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN}
              onClick={onClick}
            />

            <Line />

            <MenuLinkButton
              label="Password Setting"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Multi-Factor Authentication"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA}
              onClick={onClick}
            />

            <Line />

            <MenuLinkButton
              label="Configure Timezone"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <MenuLinkButton
              label="Configure Login User Type"
              routeName={ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL}
              onClick={onClick}
            />

            <Line />

            <button
              className={drawerContentItem}
              style={{ color: colors.danger[markedDefaultKey] }}
              onClick={onTerminatUser}
            >
              Terminate User
              <Trash2 size={20} />
            </button>
          </article>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default HeaderDropdownAction
