import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { flexColumn, line } from './styles.css'
import { customTableContainer, flexRow } from '$styles/common.css'
import {
  status,
  table,
  tableBody,
  tableContainerInner
} from '@genie-fintech/ui/style/element'
import StopAllBlock from './StopAllBlock'
import FailedLoginRow from './FailedLoginRow'
import { useMount, useRequest } from 'ahooks'
import {
  deleteAllFailedLogins,
  FAILED_LOGIN,
  getFailedLogins
} from '$services/api'
import { useCallback, useState } from 'react'
import Loading from '$components/Loading'
import { toast } from 'sonner'
import UnlockHistory from './UnlockHistory'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalServiceUserAttackProtection = () => {
  const userId = useProfileDataStore(state => state.profile?.id)

  const [failedLogins, setFailedLogins] = useState<FAILED_LOGIN[]>([])

  const fetchFailedLoginsRequest = useRequest(getFailedLogins, { manual: true })

  const deleteAllFailedLoginsRequest = useRequest(deleteAllFailedLogins, {
    manual: true
  })

  useMount(() => {
    if (!userId) return
    fetchFailedLoginsRequest.runAsync(userId).then(({ data }) => {
      setFailedLogins(data)
    })
  })

  useBreadcrumb([{ name: 'Attack Protection' }])

  const onRemoveFailedLogin = useCallback((failedLoginId: string) => {
    setFailedLogins(prev => prev.filter(d => d.id !== failedLoginId))
  }, [])

  const onStopAll = useCallback(() => {
    if (!userId) return
    deleteAllFailedLoginsRequest.runAsync(userId).then(() => {
      toast.success('Stopped all lock periods!')
      setFailedLogins([])
    })
  }, [userId, deleteAllFailedLoginsRequest])

  const isEmpty = !failedLogins.length

  const hasMoreThanOne = failedLogins.length > 1

  if (fetchFailedLoginsRequest.loading) return <Loading />

  return (
    <>
      <MainHeader
        title="ATTACK PROTECTION"
        desc="Access guidelines to ensure secure and seamless login."
      />

      <article className={mainContent}>
        <article className={innerMainContent} style={{ gap: 40 }}>
          <article
            className={flexRow}
            style={{
              gap: 10,
              alignItems: 'start',
              justifyContent: 'space-between'
            }}
          >
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>TEMPORARY LOCK PERIOD!</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                Login can be temporary locked due to the activity that violates
                our login threshold. However you can refresh the session again.
              </p>
            </article>

            <UnlockHistory userId={userId} />
          </article>

          {isEmpty && (
            <article>
              <span className={status({ kind: 'grey', type: 'faint' })}>
                There is no temporary lock period activity!
              </span>
            </article>
          )}

          {!isEmpty && (
            <article className={customTableContainer}>
              <main className={tableContainerInner}>
                <table className={table()}>
                  <tbody className={tableBody}>
                    {failedLogins.map((failedLogin, key) => (
                      <FailedLoginRow
                        key={key}
                        userId={userId}
                        data={failedLogin}
                        onRemoveFailedLogin={onRemoveFailedLogin}
                      />
                    ))}
                  </tbody>
                </table>
              </main>
            </article>
          )}

          {hasMoreThanOne && (
            <>
              <hr className={line} />

              <StopAllBlock
                onStopAll={onStopAll}
                loading={deleteAllFailedLoginsRequest.loading}
              />
            </>
          )}
        </article>
      </article>
    </>
  )
}

export default GlobalServiceUserAttackProtection
