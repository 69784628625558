import { FC, SVGProps } from 'react'
import { TagsNoRef } from '@genie-fintech/ui/types'

type SvgProps = TagsNoRef<'svg'>

type TProps = SVGProps<SVGSVGElement> & SvgProps

const VerfiedBadge: FC<TProps> = props => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M21.5 11.0006C21.5 12.0624 20.0514 12.8818 19.7404 13.8406C19.4177 14.8337 20.0965 16.3464 19.4949 17.173C18.8875 18.008 17.235 17.8269 16.4001 18.4343C15.5734 19.0359 15.2385 20.6656 14.2454 20.9891C13.2872 21.3004 12.0618 20.1881 11 20.1881C9.93819 20.1881 8.71275 21.3004 7.75463 20.9891C6.7615 20.6667 6.42637 19.0359 5.59994 18.4343C4.76497 17.8267 3.11253 18.0078 2.50484 17.173C1.90328 16.3464 2.58206 14.8337 2.25963 13.8406C1.94856 12.8818 0.5 12.0624 0.5 11.0006C0.5 9.93875 1.94856 9.11931 2.25963 8.16053C2.58228 7.16741 1.9035 5.65475 2.50506 4.82809C3.11253 3.99312 4.76497 4.17425 5.59994 3.56678C6.42659 2.96522 6.7615 1.33553 7.75463 1.012C8.71275 0.700718 9.93819 1.81306 11 1.81306C12.0618 1.81306 13.2872 0.700718 14.2454 1.012C15.2385 1.33444 15.5736 2.96522 16.4001 3.56678C17.235 4.17447 18.8875 3.99334 19.4952 4.82809C20.0967 5.65475 19.4179 7.16741 19.7404 8.16053C20.0514 9.11931 21.5 9.93875 21.5 11.0006Z"
      fill="#FFCA3A"
    />
    <path
      d="M11.0008 17.9157C14.8198 17.9157 17.9157 14.8198 17.9157 11.0008C17.9157 7.18185 14.8198 4.08594 11.0008 4.08594C7.18185 4.08594 4.08594 7.18185 4.08594 11.0008C4.08594 14.8198 7.18185 17.9157 11.0008 17.9157Z"
      fill="#F99410"
    />
    <path
      d="M10.3982 13.3407C10.2976 13.3407 10.198 13.3209 10.1051 13.2825C10.0121 13.244 9.9277 13.1876 9.85657 13.1165L7.9097 11.1696C7.76613 11.026 7.68551 10.8312 7.68555 10.6281C7.68559 10.4251 7.76629 10.2303 7.90991 10.0868C8.05353 9.94321 8.2483 9.86258 8.45137 9.86262C8.65443 9.86267 8.84917 9.94337 8.99273 10.087L10.398 11.4922L13.0055 8.88452C13.149 8.7409 13.3438 8.6602 13.5468 8.66016C13.7499 8.66012 13.9447 8.74074 14.0883 8.8843C14.2319 9.02787 14.3126 9.2226 14.3127 9.42567C14.3127 9.62873 14.2321 9.8235 14.0885 9.96712L10.9385 13.1171C10.7951 13.2602 10.6008 13.3406 10.3982 13.3407Z"
      fill="#FFFAE3"
    />
  </svg>
)

export default VerfiedBadge
