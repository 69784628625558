import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button } from '@genie-fintech/ui/components'
import { Plus } from 'lucide-react'
import { useCallback } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'
import EmptyData from '$components/EmptyData'
import DashboardAdminMessage from '$components/DashboardAdminMessage'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { useShallow } from 'zustand/react/shallow'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { flexColumn, flexRow } from '$styles/common.css'
import { grid } from './styles.css'
import Card from './Card'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalDashboardUserAccessApps = () => {
  const profile = useProfileDataStore(useShallow(state => state.profile))

  const {
    applications: accessApps = [],
    type: userType,
    id: userId
  } = profile ?? {}

  useBreadcrumb([{ name: 'Access Applications' }])

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onGoToAppListing = useCallback(() => {
    redirect(ROUTE_NAMES.APPS)
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP, {
      params: { dashboardUserId: userId }
    })
  }, [userId])

  const isAdmin = userType === 'admin'

  const isEmpty = !accessApps.length

  return (
    <>
      {!isAdmin && (
        <MainHeader
          title={`ACCESS ${accessApps.length} APPLICATIONS TO USE`}
          desc="This user can access these applications by related assigned roles and groups."
        />
      )}

      <article className={mainContent}>
        <article className={innerMainContent} style={{ gap: 24 }}>
          {!isAdmin && (
            <>
              <article
                className={flexRow}
                style={{ gap: 8, justifyContent: 'space-between' }}
              >
                <article className={flexColumn} style={{ gap: 4 }}>
                  <p className={title.two}>APPLICATIONS LINKS</p>
                  <p
                    className={footnote.one}
                    style={{ color: colors.neutral[60] }}
                  >
                    You can add more application to this user.
                  </p>
                </article>

                <Button
                  styleVariants={{ size: 'big' }}
                  onClick={onAddApplication}
                >
                  <Plus size={16} />
                  Add Application
                </Button>
              </article>

              {isEmpty && (
                <EmptyData type="applications" onClick={onAddApplication} />
              )}

              <article className={grid}>
                {accessApps.map(v => {
                  return <Card key={v.id} app={v} onClick={onClickAppCard} />
                })}
              </article>
            </>
          )}
        </article>
      </article>

      <article className={mainContent} style={{ flex: 1 }}>
        {isAdmin && (
          <article
            className={flexColumn}
            style={{
              gap: 24,
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1
            }}
          >
            <DashboardAdminMessage />

            <Button
              styleVariants={{ type: 'text', size: 'small' }}
              onClick={onGoToAppListing}
            >
              Go to Application Listing
            </Button>
          </article>
        )}
      </article>
    </>
  )
}

export default GlobalDashboardUserAccessApps
