import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService, useDeleteService } from '$hooks/services'
import { useMount } from 'ahooks'
import GroupInfo from './GroupInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import GroupRoles from './GroupRoles'
import GroupUsers from './GroupUsers'
import { redirect, ROUTE_NAMES } from '$router/config'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { useCallback } from 'react'
import { Button } from '@genie-fintech/ui/components'
import { ChevronLeft, PenLine, Trash2 } from 'lucide-react'
import Header from '$layouts/LayoutWithSidebar/Header'
import { title } from '@genie-fintech/ui/style/typography'
import { ButtonVariants } from '@genie-fintech/ui/style/element'
import { container } from './styles.css'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const buttonVariants: ButtonVariants = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small'
}

const AppGroupDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const { group, fetchAppGroupDetail, fetchingAppGroupDetail } =
    useAppGroupService()

  const { deleteGroupAsync, deletingGroup } = useDeleteService()

  useMount(() => {
    if (!appId || !groupId) return
    fetchAppGroupDetail({ application_id: appId, group_id: groupId })
  })

  useBreadcrumb([
    { name: 'Groups', path: ROUTE_NAMES.APP_GROUPS },
    { name: group?.name ?? '' }
  ])

  const onDeleteGroup = useCallback(() => {
    if (!appId || !groupId) return
    deleteGroupAsync({ appId, groupId })
  }, [appId, groupId, deleteGroupAsync])

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUPS, { params })
  }, [params])

  const onEdit = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUP_EDIT, { params })
  }, [params])

  if (fetchingAppGroupDetail || !group) return <Loading />

  return (
    <>
      <Header style={{ gap: 8 }}>
        <Button styleVariants={buttonVariants} onClick={onBack}>
          <ChevronLeft size={16} />
          Back to listing
        </Button>

        <span className={title.two} style={{ paddingLeft: 4 }}>
          {group.name}
        </span>

        <span style={{ flex: 1 }} />

        <Button styleVariants={buttonVariants} onClick={onEdit}>
          <PenLine size={16} />
          Edit
        </Button>
      </Header>

      <article className={container}>
        <GroupInfo group={group} />
      </article>

      <article className={container}>
        <GroupRoles groupName={group.name} />
      </article>

      <article className={container}>
        <GroupUsers groupName={group.name} />
      </article>

      <article className={container}>
        <article className={content}>
          <DangerZone type="group">
            <DeleteDoubleConfirmPopUp
              item={{ name: group.name, type: 'group' }}
              loading={deletingGroup}
              onExecute={onDeleteGroup}
            >
              <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                Delete Group
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        </article>
      </article>
    </>
  )
}

export default AppGroupDetail
