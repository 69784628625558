import { TAppSelectListResponse } from '$services/api'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { FC } from 'react'
import { appCard } from '../styles.css'
import AppLogo from '$components/AppLogo'
import { title } from '@genie-fintech/ui/style/typography'
import { flexColumn } from '$styles/common.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

type Props = {
  app: TAppSelectListResponse['data'][number]
  onClick: (appId: number) => void
}

const Card: FC<Props> = ({ app, onClick }) => {
  const isMounted = useIsMounted()

  return (
    <button
      type="button"
      className={appCard}
      data-ready={trueOrUndefined(isMounted)}
      onClick={() => onClick(app.id)}
    >
      <AppLogo imgUrl={app.logo?.url} brand={app.brand} size="big" />
      <article className={flexColumn}>
        <p className={title.six}>{app.name}</p>
      </article>
    </button>
  )
}

export default Card
