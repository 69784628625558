import { useIsMounted } from '@genie-fintech/ui/hooks'
import { container as innerContainer, grid } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { flexColumn, flexRow } from '$styles/common.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { SERVICE_USER_TYPE, TAppUserDetail } from '$services/api'
import { FC } from 'react'
import { getPhone } from '$app/utils'
import { format } from 'date-fns'
import { container } from '../styles.css'
import { status } from '@genie-fintech/ui/style/element'
import Table, { Column } from '$components/Table'
import { Avatar } from '@genie-fintech/ui/components'

const { colors } = themeVars

type Props = {
  user: TAppUserDetail
}

type ColumnData = TAppUserDetail['roles'][number]

const UserInfo: FC<Props> = ({ user }) => {
  const isMounted = useIsMounted()

  const isInternalUser = user.user_type === SERVICE_USER_TYPE.INTERNAL

  const columns: Column<ColumnData>[] = [
    {
      name: 'No',
      render: ({ index }) => index + 1
    },
    {
      name: 'Role Name',
      dataIndex: 'name',
      render: ({ record }) => {
        return (
          <article className={flexRow} style={{ gap: 8 }}>
            <Avatar size={40} />
            {record.name}
          </article>
        )
      }
    },
    {
      name: 'Country',
      dataIndex: 'country',
      render: ({ record }) => {
        return (
          <span
            className={status({
              kind: 'tertiary',
              size: 'small',
              type: 'faint'
            })}
            style={{
              fontSize: '1rem', // hack to calculate for status font size
              textTransform: 'uppercase'
            }}
          >
            <span className={footnote.two}>
              {record.country.name.toLocaleUpperCase()}
            </span>
          </span>
        )
      }
    },
    {
      name: 'Group',
      dataIndex: 'group',
      render: ({ record }) => record.group.name
    }
  ]

  return (
    <>
      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>USER INFO</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              View detailed information about each group to manage roles and
              permissions effectively.
            </p>
          </article>

          <article className={grid}>
            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                User Name
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {user.name}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Date of Birth
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {user.date_of_birth
                  ? format(user.date_of_birth, 'dd MMM yyyy')
                  : '-'}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Gender
              </label>
              <span
                className={title.three}
                style={{
                  color: colors.text.light,
                  textTransform: 'capitalize'
                }}
              >
                {user.gender}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Email
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {user.email}
              </span>
            </article>

            <article className="flex flex-col gap-0.5">
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Phone Number
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {getPhone(user.phone_code, user.phone_no)}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Address
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {user.address ?? '-'}
              </span>
            </article>
          </article>
        </article>
      </article>

      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article
            className={flexRow}
            style={{ gap: 8, justifyContent: 'space-between' }}
          >
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>LOGIN USER TYPE</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                The login behavior may vary depending on the selected login user
                type.
              </p>
            </article>

            <span
              className={status({
                kind: isInternalUser ? 'secondary' : 'primary',
                size: 'max',
                type: 'faint',
                inline: true
              })}
            >
              {isInternalUser ? 'Internal User' : 'External User'}
            </span>
          </article>
        </article>
      </article>

      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>ROLES, REGIONS & GROUPS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              View the assigned group and region for each role to ensure proper
              access and alignment.
            </p>
          </article>

          <Table columns={columns} data={user.roles} />
        </article>
      </article>
    </>
  )
}

export default UserInfo
