import { body, title as titleStyle } from '@genie-fintech/ui/style/typography'
import { container, innerContainer } from './styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const MainHeader = ({ title, desc }: { title: string; desc: string }) => {
  const isMounted = useIsMounted()

  return (
    <header className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className={innerContainer}>
        <p className={titleStyle.four} style={{ textTransform: 'uppercase' }}>
          {title}
        </p>
        <p className={body.three}>{desc}</p>
      </article>
    </header>
  )
}

export default MainHeader
