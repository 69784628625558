import { APPS_RESPONSE } from '$services/apps'
import { flexRow } from '$styles/common.css'
import { status } from '@genie-fintech/ui/style/element'
import { APP_ENV_STATE_DATA } from './constants'

type Props = {
  environment: APPS_RESPONSE['data'][number]['environment']
}

const AppEnvironment = ({ environment }: Props) => {
  if (!environment) return null

  const kind = APP_ENV_STATE_DATA[environment.key]

  return (
    <article className={flexRow} style={{ gap: 4 }}>
      <span
        className={status({ type: 'faint', size: 'tiny', kind })}
        style={{ textTransform: 'uppercase' }}
      >
        {environment?.value}
      </span>
    </article>
  )
}

export default AppEnvironment
