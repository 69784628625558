import AppEnvironment from '$components/AppEnvironment'
import AppLogo from '$components/AppLogo'
import Table, { Column } from '$components/Table'
import { title } from '@genie-fintech/ui/style/typography'
import { flexColumn, flexRow } from '$styles/common.css'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { Button } from '@genie-fintech/ui/components'
import { SERVICE_USER_ROLES_BY_APP } from '$services/api'
import { useToggle } from 'ahooks'
import StatusBadge from '$components/StatusBadge'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { viewButton } from '$components/Table/styles.css'
import { useState } from 'react'
import PreviewPermissionDrawer from '$blocks/PreviewPermissionDrawer'

type Props = {
  data: SERVICE_USER_ROLES_BY_APP
}

type Role = SERVICE_USER_ROLES_BY_APP['roles'][number]

const RoleListByApp = ({ data }: Props) => {
  const [show, { toggle }] = useToggle()

  const [ref] = useAutoAnimate()

  const [selectedRole, setSelectedRole] = useState<Role>()

  const columns: Column<Role>[] = [
    {
      name: 'Role Name',
      dataIndex: 'name'
    },
    {
      name: 'Permission',
      render: ({ record }) => {
        return (
          <button
            className={viewButton}
            onClick={() => setSelectedRole(record)}
          >
            View
          </button>
        )
      }
    },
    {
      name: 'Country',
      dataIndex: 'country',
      render: ({ record }) => {
        return (
          <StatusBadge
            styleVariants={{ kind: 'tertiary', size: 'small', type: 'faint' }}
          >
            {record.country.name.toLocaleUpperCase()}
          </StatusBadge>
        )
      }
    },
    {
      name: 'Group',
      dataIndex: 'group',
      render: ({ record }) => record.group.name
    }
  ]

  const Icon = show ? ChevronUp : ChevronDown

  return (
    <>
      <article className={mainContent}>
        <article ref={ref} className={innerMainContent} style={{ gap: 24 }}>
          <article className={flexRow} style={{ gap: 12 }}>
            <AppLogo
              imgUrl={data.logo?.url}
              brand={data.brand}
              size="big"
              isPublished={data.is_published}
            />

            <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
              <p className={title.six}>{data.name}</p>
              <AppEnvironment environment={data.environment} />
            </article>

            <Button
              onClick={toggle}
              styleVariants={{ type: 'outlined', kind: 'neutral' }}
            >
              <Icon size={20} />
            </Button>
          </article>

          {show && <Table columns={columns} data={data.roles} />}
        </article>
      </article>

      <PreviewPermissionDrawer
        role={selectedRole}
        appId={data.id}
        onClose={() => setSelectedRole(undefined)}
      />
    </>
  )
}
export default RoleListByApp
