import { pluralize } from '$app/utils'
import Pagination from '$components/Pagination'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppPermissionService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import Header from '$layouts/LayoutWithSidebar/Header'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppPermissionListResponse } from '$services/api'
import { flexColumn, flexRow } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { format } from 'date-fns'
import { Network, Plus, Table2 } from 'lucide-react'
import { useCallback, useEffect } from 'react'
import { innerMain, main } from './styles.css'
import SearchBox from '$components/SearchBox'
import EmptyData from '$components/EmptyData'
import Table, { Column } from '$components/Table'
import Link from '$router/Link'
import { tableCellLink } from '$components/Table/styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

type ColumnData = TAppPermissionListResponse['data'][number]

const { colors } = themeVars

const AppPermissionList = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const {
    fetchAppPermissionList,
    fetchingAppPermissionList,
    permissions: { list, meta }
  } = useAppPermissionService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppPermissionList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppPermissionList, page, perPage, debouncedSearchValue])

  useBreadcrumb([{ name: 'Permissions' }])

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSION_CREATE, { params })
  }, [params])

  const handleOnClickImport = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT, { params })
  }, [params])

  const isEmpty = !list.length && !fetchingAppPermissionList

  const columns: Column<ColumnData>[] = [
    {
      name: 'No',
      render: ({ index }) => (page - 1) * perPage + index + 1
    },
    {
      name: 'Permission Name',
      dataIndex: 'name',
      render: ({ record }) => (
        <Link
          to={ROUTE_NAMES.APP_PERMISSION_DETAIL}
          options={{ params: { ...params, permissionId: record.id } }}
          className={tableCellLink}
        >
          {record.name}
        </Link>
      )
    },
    {
      name: 'Module Name',
      dataIndex: 'module_name'
    },
    {
      name: 'Created At',
      dataIndex: 'created_at',
      render: ({ record }) => (
        <span className="uppercase">
          {record.created_at ? format(record.created_at, 'dd MMM yyyy') : 'N/A'}
        </span>
      )
    }
  ]

  return (
    <>
      <Header style={{ gap: 8 }}>
        <Network size={20} />

        <span className={title.two} style={{ paddingLeft: 4 }}>
          Permissions
        </span>

        <span style={{ flex: 1 }} />

        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
          onClick={handleOnClickImport}
        >
          <Table2 size={14} />
          Import CSV File
        </Button>

        <Button onClick={handleOnClickAddNew} styleVariants={{ size: 'small' }}>
          <Plus size={16} />
          Add Permission
        </Button>
      </Header>

      <main className={main}>
        <article className={innerMain}>
          <header className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>{appName}'s PERMISSIONS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Configure and review modules and their associated permissions for
              precise access control.
            </p>
          </header>

          <article className={flexColumn} style={{ gap: 16, flex: 1 }}>
            <article className={flexRow} style={{ gap: 4 }}>
              <article style={{ flex: 1, maxWidth: 560 }}>
                <SearchBox
                  value={searchValue}
                  onChange={updateSearchValue}
                  placeholder="Search here..."
                />
              </article>

              <span style={{ flex: 1 }} />

              {!!meta?.total && (
                <span
                  className={title.six}
                  style={{
                    color: colors.text.disabled,
                    textTransform: 'uppercase'
                  }}
                >
                  {pluralize(meta.total, 'permission')}
                </span>
              )}
            </article>

            {isEmpty && (
              <EmptyData type="permission" onClick={handleOnClickAddNew} />
            )}

            {!isEmpty && (
              <Table
                columns={columns}
                data={list}
                loading={fetchingAppPermissionList}
              />
            )}

            {!!list.length && (
              <Pagination meta={meta} pagerProps={pagerProps} />
            )}
          </article>
        </article>
      </main>
    </>
  )
}

export default AppPermissionList
