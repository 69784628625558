import { FC, PropsWithChildren } from 'react'
import {
  circle,
  container,
  divider,
  line,
  subContainer,
  actionBy
} from './styles.css'
import { formatDateWithGMT } from '$app/utils'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import {
  footnote,
  title as titleStyle
} from '@genie-fintech/ui/style/typography'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

type Props = {
  date: string
  title: string
  email: string
  role: string
}

const TimelineItem: FC<PropsWithChildren<Props>> = ({
  date,
  title,
  email,
  role,
  children
}) => {
  const isMounted = useIsMounted()

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <span className={footnote.two} style={{ color: colors.text.disabled }}>
        {formatDateWithGMT(date)}
      </span>

      <article className={divider}>
        <span className={circle} />
        <span className={line} />
      </article>

      <article className={subContainer}>
        <article className={flexColumn} style={{ gap: 2 }}>
          <p className={titleStyle.six} style={{ color: colors.text.light }}>
            {title}
          </p>
          <p className={actionBy}>
            <span>by {email}</span>
            <span>·</span>
            <span style={{ textTransform: 'capitalize' }}>{role}</span>
          </p>
        </article>

        {!!children && (
          <article style={{ display: 'grid' }}>{children}</article>
        )}
      </article>
    </article>
  )
}

export default TimelineItem
