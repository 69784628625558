import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { redirect, ROUTE_NAMES } from '$router/config'
import { flexColumn, flexRow } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { Lock } from 'lucide-react'
import { useCallback } from 'react'
import { lockIcon } from './styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const DashboardUserPasswordSetting = () => {
  const userId = useProfileDataStore(state => state.profile?.id)

  useBreadcrumb([{ name: 'Password Setting' }])

  const onUpdatePassword = useCallback(() => {
    redirect(ROUTE_NAMES.UPDATE_PASSWORD, { params: { userId } })
  }, [userId])

  return (
    <>
      <MainHeader
        title="PASSWORD SETTING"
        desc="Manage and review user's password for secure the system administration."
      />

      <article className={mainContent}>
        <article className={innerMainContent} style={{ gap: 40 }}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>PASSWORD</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Need a new password? Click the button below to update and ensure
              your account stays secure.
            </p>
          </article>

          <article className={flexColumn} style={{ gap: 20 }}>
            <article className={lockIcon}>
              <Lock size={24} />
            </article>

            <p className={title.six} style={{ color: colors.text.light }}>
              The password is encrypted and can not be seen.
            </p>

            <article className={flexRow} style={{ gap: 20 }}>
              <Button
                styleVariants={{ type: 'outlined', size: 'small' }}
                onClick={onUpdatePassword}
              >
                Update
              </Button>
            </article>
          </article>
        </article>
      </article>
    </>
  )
}

export default DashboardUserPasswordSetting
