import { on, onOneOf } from '$router/config'
import React from 'react'
import { GLOBAL_SETTING_SIDE_MENUS } from '$layouts/LayoutWithSidebar/constants'
import LayoutWithSidebar from '$layouts/LayoutWithSidebar'
import { GLOBAL_SETTING_ROUTES } from './constants'

const GlobalSettingRoutes = () => {
  const isGlobalSettingRoutes = onOneOf(GLOBAL_SETTING_ROUTES.map(v => v.name))

  if (!isGlobalSettingRoutes) return null

  return (
    <LayoutWithSidebar menus={GLOBAL_SETTING_SIDE_MENUS}>
      {GLOBAL_SETTING_ROUTES.map(
        ({ name, Component }) =>
          on(name) && <React.Fragment key={name}>{Component}</React.Fragment>
      )}
    </LayoutWithSidebar>
  )
}

export default GlobalSettingRoutes
