import { getPhone } from '$app/utils'
import { TAppGroupDetail } from '$services/api'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { container, grid } from './styles.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { status } from '@genie-fintech/ui/style/element'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { FC } from 'react'

const { colors } = themeVars

type Props = {
  group: TAppGroupDetail
}

const GroupInfo: FC<Props> = ({ group }) => {
  const isMounted = useIsMounted()

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className={flexColumn} style={{ gap: 4 }}>
        <p className={title.two}>GROUP INFO</p>
        <p className={footnote.one} style={{ color: colors.neutral[60] }}>
          View detailed information about each group to manage roles and
          permissions effectively.
        </p>
      </article>

      <article className={grid}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <label className={title.six}>Country</label>
          <span>
            <span
              className={status({
                kind: 'tertiary',
                size: 'small',
                type: 'faint'
              })}
            >
              {group.country.name.toLocaleUpperCase()}
            </span>
          </span>
        </article>

        <article className={flexColumn} style={{ gap: 4 }}>
          <label className={footnote.two} style={{ color: colors.neutral[60] }}>
            Group Name
          </label>
          <span className={title.three} style={{ color: colors.text.light }}>
            {group.name}
          </span>
        </article>

        <article className="flex flex-col gap-0.5">
          <label className={footnote.two} style={{ color: colors.neutral[60] }}>
            Phone Number
          </label>
          <span className={title.three} style={{ color: colors.text.light }}>
            {getPhone(group.phone_code, group.phone_no)}
          </span>
        </article>

        <article className={flexColumn} style={{ gap: 4 }}>
          <label className={footnote.two} style={{ color: colors.neutral[60] }}>
            Description
          </label>
          <span className={title.three} style={{ color: colors.text.light }}>
            {group.description ?? '-'}
          </span>
        </article>
      </article>
    </article>
  )
}

export default GroupInfo
