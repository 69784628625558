import Modal from '$components/Modal'
import { useModal } from '$components/Modal/hooks/useModal'
import { Button } from '@genie-fintech/ui/components'
import { ChevronLeft, History } from 'lucide-react'
import { flexColumn, header, main, title } from './styles.css'
import { useEffect, useState } from 'react'
import EmptyData from '$components/EmptyData'
import { useRequest } from 'ahooks'
import { getWebhookEventHistories, WEBHOOK_EVENT_HISTORY } from '$services/api'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import Loading from '$components/Loading'
import ScrollPagination from '$components/ScrollPagination'
import { modalContent } from '../../styles.css'
import AuditLogItem from './AuditLogItem'

const PER_PAGE = 15

const AuditLogContent = () => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const [histories, setHistories] = useState<WEBHOOK_EVENT_HISTORY[]>([])

  const fetchWebhookEventHistoriesRequest = useRequest(
    getWebhookEventHistories,
    { manual: true }
  )

  const fetchWebhookEventHistoriesAsync =
    fetchWebhookEventHistoriesRequest.runAsync

  const { meta } = fetchWebhookEventHistoriesRequest.data ?? {}

  const { pagerProps } = useApiListingParams({
    perPage: PER_PAGE,
    total: meta?.total
  })

  const { currentPage, jump } = pagerProps

  useEffect(() => {
    if (!appId) return

    fetchWebhookEventHistoriesAsync({
      appId,
      page: currentPage,
      per_page: PER_PAGE
    }).then(({ data }) => {
      setHistories(prev => (currentPage === 1 ? data : [...prev, ...data]))
    })
  }, [appId, currentPage, fetchWebhookEventHistoriesAsync])

  const isEmpty =
    !histories.length && !fetchWebhookEventHistoriesRequest.loading

  const initialLoading =
    currentPage === 1 &&
    fetchWebhookEventHistoriesRequest.loading &&
    !histories.length

  return (
    <>
      {initialLoading && (
        <article className={flexColumn} style={{ minHeight: 300 }}>
          <Loading />
        </article>
      )}

      {isEmpty && (
        <article className={flexColumn} style={{ minHeight: 300 }}>
          <EmptyData type="audit log" />
        </article>
      )}

      {histories.map((history, index) => (
        <AuditLogItem key={index} data={history} />
      ))}

      <ScrollPagination
        loading={fetchWebhookEventHistoriesRequest.loading}
        onTrigger={jump}
        meta={meta}
      />
    </>
  )
}

const AuditLog = () => {
  const modalProps = useModal({ closeOnClickOutside: false, isFocusMode: true })

  const { openModal, closeModal } = modalProps

  return (
    <>
      <Button
        styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        onClick={openModal}
      >
        <History size={16} />
        Audit Log
      </Button>

      <Modal {...modalProps} contentProps={{ className: modalContent }}>
        <header className={header}>
          <Button
            styleVariants={{
              kind: 'neutral',
              type: 'outlined',
              size: 'small',
              category: 'icon'
            }}
            onClick={closeModal}
          >
            <ChevronLeft size={20} />
          </Button>
          <p className={title}>Webhooks URL Action History</p>
        </header>

        <main className={main}>
          <AuditLogContent />
        </main>
      </Modal>
    </>
  )
}

export default AuditLog
