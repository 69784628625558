import { title } from '@genie-fintech/ui/style/typography'
import {
  modalFooter,
  header,
  main,
  modalMain,
  modalDescription,
  publicKeyContainerInner,
  publicKeyContainer
} from './styles.css'
import {
  description as descriptionStyle,
  flexColumn,
  warningIconBox
} from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useAppDetailStore } from '$hooks/stores'
import { AlertTriangle, Download, Key } from 'lucide-react'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { format } from 'date-fns'
import { usePublicKeyService } from '$hooks/services/usePublicKeyService'
import { useCallback } from 'react'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useModal } from '$components/Modal/hooks/useModal'
import Modal from '$components/Modal'
import { useShallow } from 'zustand/react/shallow'

const { colors } = themeVars

const PublicKeyBlock = () => {
  const isMounted = useIsMounted()

  const { publicKey, updatePublicKey } = useAppDetailStore(
    useShallow(state => ({
      publicKey: state.public_key,
      updatePublicKey: state.updatePublicKey
    }))
  )

  const client_id = useAppDetailStore(state => state.appDetail?.client_id)

  const modalActions = useModal()

  const { closeModal } = modalActions

  const {
    generatePublicKeyAsync,
    generatingPublicKey,
    fetchDownloadPublicKeyURLAsync,
    fetchingDownloadPublicKeyURL
  } = usePublicKeyService()

  const has_generated_public_key = !!publicKey

  const onGenerate = useCallback(() => {
    if (!client_id) return
    generatePublicKeyAsync({ client_id, force: has_generated_public_key }).then(
      ({ data }) => {
        updatePublicKey(data.public_key)
      }
    )
  }, [
    client_id,
    generatePublicKeyAsync,
    has_generated_public_key,
    updatePublicKey
  ])

  const onDownload = useCallback(() => {
    if (!client_id) return
    fetchDownloadPublicKeyURLAsync({ client_id }).then(
      async ({ data: { url } }) => {
        const link = document.createElement('a')
        link.href = url
        link.download = `public_key_${client_id}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    )
  }, [client_id, fetchDownloadPublicKeyURLAsync])

  const description = (() => {
    const result = ['Generated by Carro SSO']
    if (publicKey?.updated_at) {
      result.push(`on ${format(publicKey.updated_at, 'MMM dd, yyyy')}`)
    }
    if (publicKey?.generated_by) {
      result.push(`with action taken from @${publicKey.generated_by}`)
    }
    return result.join(' ')
  })()

  const loading = generatingPublicKey || fetchingDownloadPublicKeyURL

  const onConfirm = useCallback(() => {
    closeModal()
    onGenerate()
  }, [closeModal, onGenerate])

  return (
    <article
      className={publicKeyContainer}
      data-ready={trueOrUndefined(isMounted)}
    >
      <article className={publicKeyContainerInner}>
        <header className={header}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <h3 className={title.two} style={{ textTransform: 'uppercase' }}>
              Public Key
            </h3>
            <p className={descriptionStyle}>
              A secure public key to share safely without exposing private data.
            </p>
          </article>

          {!has_generated_public_key && (
            <Button
              styleVariants={{
                kind: 'neutral',
                size: 'small',
                type: 'outlined'
              }}
              disabled={loading}
              onClick={onGenerate}
            >
              {generatingPublicKey && <Spinner />}
              Generate
            </Button>
          )}

          {has_generated_public_key && (
            <>
              <Button
                styleVariants={{
                  kind: 'neutral',
                  size: 'small',
                  type: 'outlined'
                }}
                disabled={loading}
                onClick={modalActions.openModal}
              >
                {generatingPublicKey && <Spinner />}
                Regenerate
              </Button>

              <Modal {...modalActions}>
                <article className={flexColumn}>
                  <main className={modalMain}>
                    <span className={warningIconBox}>
                      <AlertTriangle size={20} />
                    </span>

                    <article className={flexColumn} style={{ gap: 4 }}>
                      <p className={title.four}>
                        You are about to regenerate public key!
                      </p>
                      <p className={modalDescription}>
                        Are you sure? This is an irreversible action.
                      </p>
                    </article>
                  </main>
                  <footer className={modalFooter}>
                    <Button
                      styleVariants={{
                        size: 'small',
                        kind: 'neutral',
                        type: 'outlined'
                      }}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      styleVariants={{ size: 'small' }}
                      onClick={onConfirm}
                      autoFocus
                    >
                      Confirm
                    </Button>
                  </footer>
                </article>
              </Modal>
            </>
          )}
        </header>

        {has_generated_public_key && (
          <main className={main}>
            <span className={warningIconBox}>
              <Key size={20} />
            </span>

            <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
              <h6 className={title.six} style={{ color: colors.text.light }}>
                The public key has been generated for this application.
              </h6>
              <p className={descriptionStyle}>{description}</p>
            </article>

            <Button
              disabled={loading}
              styleVariants={{
                size: 'small',
                kind: 'neutral',
                type: 'text',
                category: 'icon'
              }}
              onClick={onDownload}
            >
              {fetchingDownloadPublicKeyURL && <Spinner />}
              <Download size={20} />
            </Button>
          </main>
        )}
      </article>
    </article>
  )
  3
}

export default PublicKeyBlock
