import { FC, PropsWithChildren } from 'react'
import { content, overlay } from './styles.css'
import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { useModal } from './hooks/useModal'
import { useDelayUnmount } from './hooks/useDelayUnmount'
import { Portal } from '@genie-fintech/ui/components'

type Props = ReturnType<typeof useModal> & {
  overlayProps?: TagsNoRef<'section'>
  contentProps?: TagsNoRef<'article'>
}

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  overlayProps,
  contentProps,
  ...modalProps
}) => {
  const { containerRef, open, isFocusMode, onOverlayClick } = modalProps

  const isUnmounted = useDelayUnmount({ open })

  return (
    <Portal>
      <section
        ref={containerRef}
        role="dialog"
        aria-modal="true"
        aria-hidden={trueOrUndefined(!open)}
        onClick={onOverlayClick}
        {...propsWithClassNames(overlayProps, overlay({ open, isFocusMode }))}
      >
        <article {...propsWithClassNames(contentProps, content)}>
          {!isUnmounted && children}
        </article>
      </section>
    </Portal>
  )
}

export default Modal
