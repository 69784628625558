import { tablePagination } from '$components/Table/styles.css'
import { APIWithPagerMeta } from '$services/api'
import { Pager } from '@genie-fintech/ui/components'
import { usePager } from '@genie-fintech/ui/hooks'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'

const { colors } = themeVars

interface IProps {
  pagerProps: ReturnType<typeof usePager>
  meta?: APIWithPagerMeta['meta']
}

const Pagination = ({ pagerProps, meta }: IProps) => {
  return (
    <footer className={tablePagination}>
      {meta && (
        <p className={title.five} style={{ color: colors.neutral[60] }}>
          {meta.from} - {meta.to} of {meta.total} Results
        </p>
      )}

      <Pager align="right" {...pagerProps} />
    </footer>
  )
}

export default Pagination
