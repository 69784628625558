import { title } from '@genie-fintech/ui/style/typography'
import { container, header, main } from './styles.css'
import { description } from '$styles/common.css'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import {
  BaseText,
  BaseTextProps
} from '@genie-fintech/ui/components/hook-fields'
import { hideInputAppearance } from '@genie-fintech/ui/style/atoms'
import FooterAction from '$components/FooterAction'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { useMount, useRequest } from 'ahooks'
import { getAttackProtection, patchAttackProtection } from '$services/api'
import Loading from '$components/Loading'
import { toast } from 'sonner'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const numberRule = z
  .string()
  .trim()
  .min(1, 'Required!')
  .refine(val => {
    const regex = /^\d+$/
    return regex.test(val)
  }, 'Invalid Number!')
  .transform(val => `${+val}`)

const schema = z.object({
  temporary_lock_period: z.object({
    id: z.string(),
    value: numberRule
  }),
  max_password_attempts: z.object({
    id: z.string(),
    value: numberRule
  })
})

type TField = z.infer<typeof schema>

const defaultValues: TField = {
  temporary_lock_period: { id: '', value: '' },
  max_password_attempts: { id: '', value: '' }
}

const inputProps: BaseTextProps['inputProps'] = {
  type: 'number',
  min: 0,
  className: hideInputAppearance
}

const AttackProtection = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const fetchAttackProtectionRequest = useRequest(getAttackProtection, {
    manual: true
  })

  const updateAttackProtectionRequest = useRequest(patchAttackProtection, {
    manual: true
  })

  useBreadcrumb([{ name: 'Attack Protection' }])

  useMount(() => {
    fetchAttackProtectionRequest.runAsync().then(({ data }) => {
      const modifiedData = data.reduce(
        (r, c) => ({ ...r, [c.key]: { id: c.id, value: c.value } }),
        {} as TField
      )
      reset(modifiedData)
    })
  })

  const onSubmit = useCallback(
    (values: TField) => {
      const payload = Object.entries(values).map(([key, value]) => ({
        ...value,
        key
      }))
      updateAttackProtectionRequest
        .runAsync(payload)
        .then(() => {
          toast.success('Updated attack protection policy!')
        })
        .finally(() => {
          reset(values)
        })
    },
    [updateAttackProtectionRequest, reset]
  )

  if (fetchAttackProtectionRequest.loading) return <Loading />

  return (
    <article className={container}>
      <header className={header}>
        <p className={title.two} style={{ textTransform: 'uppercase' }}>
          Login Threshold
        </p>
        <p className={description}>
          For user’s account security is our priority. Set limits to block
          suspicious traffic during end-user authentication. By logging in, user
          agrees to comply with our security policies and data protection
          guidelines.
        </p>
      </header>

      <form className={main} onSubmit={handleSubmit(onSubmit)}>
        <BaseText
          name="temporary_lock_period.value"
          control={control}
          label="Temporary Lock Period"
          required
          affix={{ post: 'min' }}
          inputProps={inputProps}
        />

        <BaseText
          name="max_password_attempts.value"
          control={control}
          label="Max Password Attempts"
          required
          inputProps={inputProps}
          description="Users can wrongly put password as specified attempts."
        />

        <FooterAction fullWidth={false}>
          <SaveWithShortCutButton
            disabled={!isDirty}
            loading={updateAttackProtectionRequest.loading}
          />
        </FooterAction>
      </form>
    </article>
  )
}

export default AttackProtection
