import { formatNumber } from '$app/utils'
import TodayCard from '$blocks/AppHome/components/TodayCard'
import { flexColumn, flexRow } from '$styles/common.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getLast30minsOption } from './utils'
import { TAppHome } from '$services/api'
import { useMemo } from 'react'
import { container } from './styles.css'
import { footnote, headline, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { status } from '@genie-fintech/ui/style/element'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

interface IProps {
  recent_active_users: TAppHome['recent_active_user_counts']
}

const Last30MinUserChart = ({ recent_active_users }: IProps) => {
  const isMounted = useIsMounted()

  const recent_active_users_count = useMemo(
    () => recent_active_users.reduce((r, c) => r + c, 0),
    [recent_active_users]
  )

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article
        style={{ display: 'flex', gap: 16, justifyContent: 'space-between' }}
      >
        <article className={flexColumn} style={{ gap: 8 }}>
          <p className={title.three} style={{ color: colors.text.light }}>
            ACTIVE USERS IN LAST 30 MINS
          </p>
          <article className={flexRow} style={{ gap: 8 }}>
            <span className={headline.four}>
              {formatNumber(recent_active_users_count)}
            </span>
            <span
              className={status({
                kind: 'primary',
                size: 'tiny',
                type: 'faint',
                material: 'dot'
              })}
            >
              TODAY
            </span>
          </article>
          <p
            className={footnote.one}
            style={{ color: colors.neutral[60], maxWidth: 269 }}
          >
            View active users in the last 30 minutes for real-time engagement
            insights.
          </p>
        </article>

        <TodayCard />
      </article>

      <article>
        <HighchartsReact
          highcharts={Highcharts}
          options={getLast30minsOption(recent_active_users)}
        />
      </article>
    </article>
  )
}

export default Last30MinUserChart
