import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useRequest } from 'ahooks'
import { AppAuditLog, getAppAuditLogs } from '$services/api'
import ScrollPagination from '$components/ScrollPagination'
import { useEffect, useState } from 'react'
import { useApiListingParams } from '$hooks/actions'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import { flexColumn } from '$styles/common.css'
import TimelineItem from '$components/TimelineItem'

const PER_PAGE = 15

const newActions = ['updated', 'created']

const getDescription = (item: AppAuditLog) => {
  const result = [item.description]

  const isNewAction = newActions.some(action => item.action.includes(action))

  const name = isNewAction
    ? item.log_details?.new_value?.name
    : item.log_details?.old_value?.name

  if (name) result.push(`(${name})`)

  return result.join(' ')
}

const DrawerContent = () => {
  const [auditLogs, setAuditLogs] = useState<AppAuditLog[]>([])

  const { route } = useRouteSummary()
  const { appId } = route.params

  const { loading, runAsync, data } = useRequest(getAppAuditLogs, {
    manual: true
  })

  const { meta } = data ?? {}

  const { pagerProps } = useApiListingParams({
    perPage: PER_PAGE,
    total: meta?.total
  })

  const { currentPage, jump } = pagerProps

  useEffect(() => {
    if (!appId) return

    runAsync({
      appId,
      page: currentPage,
      per_page: PER_PAGE
    }).then(({ data }) => {
      setAuditLogs(prev => (currentPage === 1 ? data : [...prev, ...data]))
    })
  }, [appId, currentPage, runAsync])

  const initialLoading = currentPage === 1 && loading && !auditLogs.length

  const hasData = !!auditLogs.length

  const isEmpty = !auditLogs.length && !loading

  return (
    <>
      {initialLoading && <Loading />}

      {isEmpty && <EmptyData type="audit log" />}

      {hasData && (
        <article className={flexColumn} style={{ flex: 1 }}>
          {auditLogs.map((item, index) => {
            return (
              <TimelineItem
                key={index}
                date={item.timestamp}
                title={getDescription(item)}
                email={item.performed_by.email}
                role={item.performed_by.role}
              />
            )
          })}
        </article>
      )}

      <ScrollPagination loading={loading} onTrigger={jump} meta={meta} />
    </>
  )
}

export default DrawerContent
