import { copy, tooltipProps } from '$app/utils'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useBoolean } from 'ahooks'
import { Check, LucideIcon } from 'lucide-react'
import { copyItem } from './styles.css'
import React, { useCallback } from 'react'

const { colors } = themeVars

type Props = {
  label: string
  value: string
  Icon: LucideIcon
}

const CopyItem = ({ label, value, Icon: _Icon_ }: Props) => {
  const [isCopied, { setTrue, setFalse }] = useBoolean(false)

  const Icon = isCopied ? Check : _Icon_

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      copy(value, setTrue)
      e.preventDefault()
    },
    [value, setTrue]
  )

  const onBlur = useCallback(() => {
    setFalse()
  }, [setFalse])

  return (
    <button
      type="button"
      onClick={onClick}
      onBlur={onBlur}
      className={copyItem}
      {...tooltipProps(isCopied ? 'Copied' : 'Copy')}
    >
      {label}

      <Icon
        size={12}
        style={{ color: isCopied ? colors.success[70] : colors.text.disabled }}
      />
    </button>
  )
}

export default CopyItem
