import { PublicKey, TAppBasicSetting } from '$services/api'
import { create } from 'zustand'

interface IAppDetailState {
  appDetail?: TAppBasicSetting
  updateAppDetail: (appDetail: IAppDetailState['appDetail']) => void
  updateAppDetailPartially: (
    appDetail: Partial<IAppDetailState['appDetail']>
  ) => void

  public_key: PublicKey | null
  updatePublicKey: (public_key: IAppDetailState['public_key']) => void
}

export const useAppDetailStore = create<IAppDetailState>()(set => ({
  appDetail: undefined,
  updateAppDetail: appDetail => set({ appDetail }),
  updateAppDetailPartially: appDetail =>
    set(state => ({
      appDetail: state.appDetail
        ? { ...state.appDetail, ...appDetail }
        : undefined
    })),

  public_key: null,
  updatePublicKey: public_key => set({ public_key })
}))
