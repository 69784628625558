import { APIWithPagerMeta, TAppPermissionDetail } from '$services/api'
import { flexColumn, flexRow } from '$styles/common.css'
import { Avatar } from '@genie-fintech/ui/components'
import { status } from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { FC } from 'react'
import { grid, container as innerContainer } from './styles.css'
import { container } from '../styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import Table, { Column } from '$components/Table'
import Pagination from '$components/Pagination'
import { useApiListingParams } from '$hooks/actions'

const { colors } = themeVars

type ColumnData = TAppPermissionDetail['roles'][number]

type Props = {
  permission: TAppPermissionDetail
}

const PermissionInfo: FC<Props> = ({ permission }) => {
  const isMounted = useIsMounted()

  const { pagerProps, perPage } = useApiListingParams({
    total: permission.roles.length,
    perPage: 10
  })

  const { currentPage } = pagerProps

  const columns: Column<ColumnData>[] = [
    {
      name: 'No',
      render: ({ index }) => perPage * (currentPage - 1) + index + 1
    },
    {
      name: 'Role Name',
      dataIndex: 'name',
      render: ({ record }) => {
        return (
          <article className={flexRow} style={{ gap: 8 }}>
            <Avatar size={40} />
            {record.name}
          </article>
        )
      }
    },
    {
      name: 'Country',
      dataIndex: 'country',
      render: ({ record }) => {
        return (
          <span
            className={status({
              kind: 'tertiary',
              size: 'small',
              type: 'faint'
            })}
            style={{
              fontSize: '1rem', // hack to calculate for status font size
              textTransform: 'uppercase'
            }}
          >
            <span className={footnote.two}>
              {record.country.name.toLocaleUpperCase()}
            </span>
          </span>
        )
      }
    },
    {
      name: 'Group',
      dataIndex: 'group',
      render: ({ record }) => record.group.name
    }
  ]

  const roles = permission.roles.slice(
    perPage * (currentPage - 1),
    perPage * currentPage
  )

  const meta: APIWithPagerMeta['meta'] = {
    total: permission.roles.length,
    from: perPage * (currentPage - 1) + 1,
    to: Math.min(perPage * currentPage, permission.roles.length),
    current_page: currentPage,
    last_page: Math.ceil(permission.roles.length / perPage),
    per_page: perPage
  }

  return (
    <>
      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>PERMISSION INFO</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Review detailed permissions to manage user access and
              functionality within the system.
            </p>
          </article>

          <article className={grid}>
            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Permission Name
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {permission.name}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Module Name
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {permission.module_name}
              </span>
            </article>

            <article className={flexColumn} style={{ gap: 4 }}>
              <label
                className={footnote.two}
                style={{ color: colors.neutral[60] }}
              >
                Description
              </label>
              <span
                className={title.three}
                style={{ color: colors.text.light }}
              >
                {permission.description ?? '-'}
              </span>
            </article>
          </article>
        </article>
      </article>

      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>ROLES, REGIONS & GROUPS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              View the assigned group and region for each role to ensure proper
              access and alignment.
            </p>
          </article>

          <Table columns={columns} data={roles} />

          <Pagination pagerProps={pagerProps} meta={meta} />
        </article>
      </article>
    </>
  )
}

export default PermissionInfo
