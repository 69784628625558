import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { FC, PropsWithChildren } from 'react'
import { mainContentHeader } from './styles.css'

type Props = TagsNoRef<'header'>

const Header: FC<PropsWithChildren<Props>> = props => {
  const isMounted = useIsMounted()

  return (
    <header
      data-ready={trueOrUndefined(isMounted)}
      {...propsWithClassNames(props, mainContentHeader)}
    />
  )
}

export default Header
