import { Plus } from 'lucide-react'
import {
  buttonStyle,
  container,
  descStyle,
  subContainer,
  titleStyle
} from './styles.css'
import { FC } from 'react'

type Props = {
  title?: string
  description?: string
  buttonProps?: {
    label: string
    onClick: VoidFunction
  }
}

const Empty: FC<Partial<Props>> = ({
  title = 'No Data',
  description,
  buttonProps
}) => {
  return (
    <article className={container} style={{ gap: 16 }} aria-label="Empty State">
      {buttonProps && (
        <button
          className={buttonStyle}
          onClick={buttonProps.onClick}
          aria-label={buttonProps.label}
        >
          <Plus size={48} aria-hidden="true" />
          <span>{buttonProps.label}</span>
        </button>
      )}

      <div className={subContainer}>
        <h2 className={titleStyle}>{title}</h2>
        {description && <p className={descStyle}>{description}</p>}
      </div>
    </article>
  )
}

export default Empty
