import { useCountryStore } from '$hooks/stores'
import { Tabs } from '@genie-fintech/ui/components'
import { FC } from 'react'

type Props = {
  value: string
  onChange: (value: string) => void
  showAll?: boolean
}

const CountryTabs: FC<Props> = ({ value, onChange, showAll }) => {
  const countryOptions = useCountryStore(state => state.countryOptions)

  const options = showAll
    ? [{ label: 'All', value: '' }, ...countryOptions]
    : countryOptions

  return (
    <Tabs.Root
      value={value}
      onValueChange={onChange}
      format={{ type: 'segmented' }}
    >
      <Tabs.List styleVariants={{ hAlign: 'left' }}>
        {options.map((v, k) => (
          <Tabs.Trigger key={k} value={v.value}>
            {v.label}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
    </Tabs.Root>
  )
}

export default CountryTabs
