import {
  customTableCell,
  customTableContainer,
  customTableHead,
  description as descriptionStyle
} from '$styles/common.css'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { cellTitleText, tableContainer, tableHeaderCell } from './styles.css'
import { useRequest } from 'ahooks'
import { getActivityLogs, TID } from '$services/api'
import { useApiListingParams } from '$hooks/actions'
import { useEffect } from 'react'
import { ACTIVITY_ACTION } from '$app/constants'
import { flexColumn } from '../styles.css'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import { formatDateWithGMT } from '$app/utils'
import Pagination from '$components/Pagination'
import { useIsMounted } from '@genie-fintech/ui/hooks'

const PER_PAGE = 10
interface IAdditionalData {
  email: string
  location: string
  ip_address: string
  locked_until: string
}

const HistoryTable = ({ userId }: { userId?: TID }) => {
  const isMounted = useIsMounted()

  const activityLogRequest = useRequest(getActivityLogs<IAdditionalData>, {
    manual: true
  })

  const { data: list = [], meta } = { ...activityLogRequest.data }

  const { pagerProps, perPage } = useApiListingParams({
    total: meta?.total,
    perPage: PER_PAGE
  })

  const fetchActivityLogAsync = activityLogRequest.runAsync

  useEffect(() => {
    if (!userId) return

    fetchActivityLogAsync({
      page: pagerProps.currentPage,
      per_page: perPage,
      action: ACTIVITY_ACTION.UNLOCK_USER_FAILED_LOGIN,
      auditable_id: userId
    })
  }, [fetchActivityLogAsync, pagerProps.currentPage, perPage, userId])

  const isLoading = activityLogRequest.loading || !isMounted

  const isEmpty = !isLoading && !list.length

  const hasData = !isLoading && !!list.length

  const hasMoreThanOnePage = meta?.last_page && meta.last_page > 1

  return (
    <article className={tableContainer}>
      {isLoading && <Loading />}

      {isEmpty && <EmptyData type="unlock history" />}

      {hasData && (
        <>
          <article className={customTableContainer} style={{ flex: 1 }}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={tableHeaderCell}>Lock period</th>
                    <th className={tableHeaderCell}>User who unlock</th>
                  </tr>
                </thead>

                <tbody className={tableBody}>
                  {list.map((item, key) => {
                    const {
                      additional_data: { location, locked_until, ip_address },
                      created_by,
                      date
                    } = item

                    const location_and_ip = [location, ip_address]
                      .filter(Boolean)
                      .join(' - ')

                    return (
                      <tr key={key} className={tableRow}>
                        <td className={customTableCell}>
                          <article className={flexColumn}>
                            <p className={cellTitleText}>
                              {formatDateWithGMT(locked_until)}
                            </p>
                            <p className={descriptionStyle}>
                              {location_and_ip}
                            </p>
                          </article>
                        </td>
                        <td className={customTableCell}>
                          <article className={flexColumn}>
                            <p className={cellTitleText}>{created_by.email}</p>
                            <p className={descriptionStyle}>
                              {formatDateWithGMT(date)}
                            </p>
                          </article>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </main>
          </article>

          {hasMoreThanOnePage && (
            <Pagination meta={meta} pagerProps={pagerProps} />
          )}
        </>
      )}
    </article>
  )
}

export default HistoryTable
