import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppLaunchpadService } from '$hooks/services'
import { TAppLaunchpad } from '$services/api'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { Icon } from '@genie-fintech/ui/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import Loading from '$components/Loading'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import { flexColumn, flexRow } from '$styles/common.css'
import { useAppDetailStore } from '$hooks/stores'
import DescriptionTableColumn from '$components/DescriptionTableColumn'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { pluralize } from '$app/utils'
import Header from '$layouts/LayoutWithSidebar/Header'
import { form, innerMain, main } from './styles.css'
import { GalleryHorizontalEnd, Plus } from 'lucide-react'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import EmptyData from '$components/EmptyData'
import Table, { Column } from '$components/Table'
import Drawer from '$components/Drawer'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const schema = z.object({
  name: z.string().trim().min(1, 'Required!'),
  url: z.string().trim().min(1, 'Required!').url('Invalid URL!'),
  description: z.string().trim()
})

type TFormValues = z.infer<typeof schema>

const INITIAL_DEFAULT_VALUES: TFormValues = {
  name: '',
  url: '',
  description: ''
}

const AppLaunchPadSetting = () => {
  const [selectedID, setSelectedID] = useState<number>()

  const [open, setOpen] = useState(false)

  const { route } = useRouteSummary()

  const { appId } = route.params

  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: INITIAL_DEFAULT_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const {
    launchpads,
    fetchAppLaunchpads,
    fetchingAppLaunchpads,
    updateAppLuanchpadsAsync,
    updatingAppLuanchpads
  } = useAppLaunchpadService()

  useMount(() => {
    if (!appId) return
    fetchAppLaunchpads({ application_id: appId })
  })

  useBreadcrumb([{ name: 'Launchpads' }])

  const handleOpenDrawer = useCallback((id?: number) => {
    setOpen(true)
    setSelectedID(id)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setOpen(false)
    setSelectedID(undefined)
    reset(INITIAL_DEFAULT_VALUES)
  }, [reset])

  const handleOnSubmit = useCallback(
    (formValues: TFormValues) => {
      if (!appId) return

      const payload = (() => {
        if (selectedID !== undefined) {
          return launchpads.map((v, k) => {
            if (selectedID === k) return formValues
            return v
          })
        }

        return [...launchpads, formValues]
      })()

      updateAppLuanchpadsAsync(appId, payload).then(handleCloseDrawer)
    },
    [appId, selectedID, launchpads, updateAppLuanchpadsAsync, handleCloseDrawer]
  )

  const handleOnEdit = useCallback(
    (key: number) => {
      handleOpenDrawer(key)
      reset(launchpads[key])
    },
    [launchpads, reset, handleOpenDrawer]
  )

  const handleOnDelete = useCallback(
    (key: number) => {
      if (!appId) return

      setSelectedID(key)

      const payload = launchpads.filter((_, index) => index !== key)
      updateAppLuanchpadsAsync(appId, payload).then(() => {
        setSelectedID(undefined)
      })
    },
    [appId, launchpads, updateAppLuanchpadsAsync]
  )

  const isEmpty = !fetchingAppLaunchpads && !launchpads.length
  const hasData = !fetchingAppLaunchpads && !!launchpads.length

  const columns: Column<TAppLaunchpad>[] = [
    {
      name: 'NO',
      render: ({ index }) => index + 1
    },
    {
      name: 'NAME',
      dataIndex: 'name'
    },
    {
      name: 'URL',
      dataIndex: 'url'
    },
    {
      name: 'DESCRIPTION',
      dataIndex: 'description',
      render: ({ record }) => (
        <DescriptionTableColumn desc={record.description} />
      )
    },
    {
      name: 'Action',
      render: ({ index }) => (
        <article className={flexRow} style={{ gap: 8 }}>
          <Button
            styleVariants={{
              kind: 'neutral',
              category: 'icon',
              type: 'text'
            }}
            onClick={() => {
              handleOnEdit(index)
            }}
          >
            <Icon namespace="EditPencil" />
          </Button>

          {launchpads.length > 1 && (
            <DeleteWithConfirm
              loading={selectedID === index && updatingAppLuanchpads}
              onConfirm={() => {
                handleOnDelete(index)
              }}
            />
          )}
        </article>
      )
    }
  ]

  return (
    <>
      <Header style={{ gap: 8 }}>
        <GalleryHorizontalEnd size={20} />

        <span className={title.two} style={{ paddingLeft: 4 }}>
          Launchpads
        </span>

        <span style={{ flex: 1 }} />

        <Button
          onClick={() => handleOpenDrawer()}
          styleVariants={{ size: 'small' }}
        >
          <Plus size={16} />
          Add Launchpad
        </Button>
      </Header>

      {fetchingAppLaunchpads && <Loading />}

      <main className={main}>
        <article className={innerMain}>
          <header className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>{appName}'s LAUNCHPADS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Access key tools and resources quickly through customizable
              launchpads.
            </p>
          </header>

          {isEmpty && (
            <EmptyData type="launchpad" onClick={() => handleOpenDrawer()} />
          )}

          {hasData && (
            <article className={flexColumn} style={{ gap: 16 }}>
              <article
                className={flexRow}
                style={{ justifyContent: 'flex-end' }}
              >
                <p
                  className={title.six}
                  style={{ color: colors.text.disabled }}
                >
                  {pluralize(launchpads.length, 'LAUNCHPAD')}
                </p>
              </article>

              <Table
                columns={columns}
                data={launchpads}
                loading={fetchingAppLaunchpads}
              />
            </article>
          )}
        </article>
      </main>

      <Drawer
        direction="right"
        dismissible={false}
        open={open}
        onOpenChange={setOpen}
        title={`${selectedID !== undefined ? 'Edit' : 'Add'} Launchpad`}
        withCloseButton
      >
        <form className={form} onSubmit={handleSubmit(handleOnSubmit)}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className="text-xl font-semibold">Launchpad</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Access key tools and resources quickly through customizable
              launchpads.
            </p>
          </article>

          <article className={flexColumn} style={{ gap: 20, flex: 1 }}>
            <BaseHookFieldText
              name="name"
              control={control}
              label="Name"
              required
            />

            <BaseHookFieldText
              name="url"
              control={control}
              label="URL"
              required
            />

            <Textarea
              name="description"
              control={control}
              label="Description"
            />
          </article>

          <Button
            styleVariants={{ inline: false }}
            type="submit"
            disabled={!isDirty || updatingAppLuanchpads}
          >
            {updatingAppLuanchpads && <Spinner />}
            {selectedID === undefined && <Icon namespace="Add" />}
            {selectedID === undefined ? 'Add New' : 'Save Changes'}
          </Button>
        </form>
      </Drawer>
    </>
  )
}

export default AppLaunchPadSetting
