import { formatDateWithGMT } from '$app/utils'
import Loading from '$components/Loading'
import ModKey from '$components/ModKey'
import Pagination from '$components/Pagination'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useGlobalAppUserService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import TableRowLink from '$router/TableRowLink'
import { SERVICE_USER_TYPE, TGlobalAppUserListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  pageDescription,
  pageHeader
} from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow,
  status
} from '@genie-fintech/ui/style/element'
import { useCallback, useEffect, useRef } from 'react'
import {
  container,
  headerStyle,
  mainStyle,
  searchBoxStyle,
  statusStyle,
  tableCellStyle
} from './styles.css'
import { toClassNames } from '@genie-fintech/ui/functions'
import StatusBadge from '$components/StatusBadge'

const columns: {
  key: Exclude<keyof TGlobalAppUserListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'NAME' },
  { key: 'email', value: 'EMAIL' },
  { key: 'is_verified', value: 'Email verification' },
  { key: 'status', value: 'STATUS' },
  { key: 'last_login_at', value: 'LAST SIGNED IN' },
  { key: 'user_type', value: 'Type' }
]

const GlobalAppUser = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fetchGlobalAppUserList,
    fetchingGlobalAppUserList,
    globalUsers: { list, meta }
  } = useGlobalAppUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  useEffect(() => {
    fetchGlobalAppUserList({
      page,
      per_page: perPage,
      q: debouncedSearchValue
    })
  }, [fetchGlobalAppUserList, page, perPage, debouncedSearchValue])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(e.currentTarget.value)
    },
    [updateSearchValue]
  )

  return (
    <article className={container}>
      <header className={headerStyle}>
        <h3 className={pageHeader}>
          Total Application Users - {meta?.total.toLocaleString()}
        </h3>
        <p className={pageDescription}>
          All the users of applications in Carro SSO are here.
        </p>
      </header>

      <main className={mainStyle}>
        <div className={searchBoxStyle}>
          <BaseText
            inputRef={inputRef}
            containerProps={{ className: 'min-w-[250px]' }}
            affix={{
              pre: <Icon namespace="Search" width={16} />,
              post: searchValue ? (
                <button onClick={() => updateSearchValue('')}>
                  <Icon namespace="Cross" width={18} />
                </button>
              ) : (
                <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                  <span>
                    <ModKey />
                  </span>
                  <span>K</span>
                </p>
              )
            }}
            inputProps={{
              type: 'text',
              value: searchValue,
              onChange: handleOnChange,
              placeholder: 'Search here...'
            }}
          />
        </div>
        <article className={customTableContainer}>
          <main className={tableContainerInner}>
            <table className={table({ separator: 'line' })}>
              <thead className={customTableHead}>
                <tr className={tableRow}>
                  <th className={customTableCell} style={{ width: 0 }}>
                    NO
                  </th>
                  {columns.map((col, key) => (
                    <th
                      key={key}
                      className={customTableCell}
                      style={{ textTransform: 'uppercase' }}
                    >
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={tableBody}>
                {list.map((data, rowKey) => (
                  <TableRowLink
                    key={rowKey}
                    className={tableRow}
                    to={ROUTE_NAMES.GLOBAL_SERVICE_USER_INDEX}
                    options={{
                      params: { serviceUserId: data.id }
                    }}
                  >
                    <td
                      className={toClassNames(tableCellStyle, customTableCell)}
                    >
                      {(page - 1) * perPage + rowKey + 1}
                    </td>
                    {columns.map((col, colKey) => (
                      <td
                        key={colKey}
                        className={toClassNames(
                          tableCellStyle,
                          customTableCell
                        )}
                      >
                        {(() => {
                          if (col.key === 'last_login_at')
                            return formatDateWithGMT(data.last_login_at)
                          if (col.key === 'is_verified')
                            return (
                              <span
                                className={toClassNames(
                                  statusStyle(),
                                  status({
                                    kind: data.is_verified
                                      ? 'neutral'
                                      : 'warning',
                                    size: 'big',
                                    type: 'faint'
                                  })
                                )}
                              >
                                {data.is_verified ? (
                                  'Email verified'
                                ) : (
                                  <>
                                    <Icon
                                      namespace={'Warning'}
                                      color="warning.60"
                                      width={16}
                                      height={16}
                                    />{' '}
                                    Unverified
                                  </>
                                )}
                              </span>
                            )
                          if (col.key === 'status')
                            return (
                              <StatusBadge
                                styleVariants={{
                                  kind:
                                    data.status === 'active'
                                      ? 'success'
                                      : 'grey',
                                  type: 'faint',
                                  size: 'tiny'
                                }}
                              >
                                {data.status?.toLocaleUpperCase()}
                              </StatusBadge>
                            )
                          if (col.key === 'user_type')
                            return (
                              <StatusBadge
                                styleVariants={{
                                  kind:
                                    data.user_type ===
                                    SERVICE_USER_TYPE.INTERNAL
                                      ? 'secondary'
                                      : 'primary',
                                  type: 'faint',
                                  size: 'tiny'
                                }}
                              >
                                {data.user_type?.toLocaleUpperCase()}
                              </StatusBadge>
                            )
                          return data[col.key] ?? 'N/A'
                        })()}
                      </td>
                    ))}
                  </TableRowLink>
                ))}

                {!list.length && !fetchingGlobalAppUserList && (
                  <tr className={tableRow}>
                    <td
                      colSpan={columns.length + 1}
                      style={{ textAlign: 'center' }}
                      className={customTableCell}
                    >
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </main>
        </article>

        {fetchingGlobalAppUserList && <Loading />}

        {!!list.length && <Pagination meta={meta} pagerProps={pagerProps} />}
      </main>
    </article>
  )
}

export default GlobalAppUser
