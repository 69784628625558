import { CF_TURNSTILE_SITEKEY } from '$services/environments'
import { useTheme } from '@genie-fintech/ui/hooks'
import { useEffect, useRef, useState } from 'react'

type Options = {
  disabled?: boolean
  autoReset?: boolean
  onReset?: VoidFunction
  onError?: VoidFunction
  onSuccess: Required<Turnstile.RenderParameters>['callback']
}

const sitekey = CF_TURNSTILE_SITEKEY

export const useCFTurnstile = <TargetRef extends HTMLElement>({
  disabled,
  autoReset = true,
  onSuccess,
  onReset,
  onError
}: Options) => {
  const [token, setToken] = useState<string>()
  const [widgetId, setWidgetId] = useState<string>()
  const targetRef = useRef<TargetRef>(null)

  const {
    mode: { isDarkMode, isAuto }
  } = useTheme()

  const theme = (() => {
    if (isAuto) return 'auto'
    if (isDarkMode) return 'dark'
    return 'light'
  })()

  const onErrorCallback = () => {
    onError?.()

    if (!autoReset) return

    reset()
  }

  const reset = () => {
    if (!widgetId) return

    turnstile.reset(widgetId)
    onReset?.()
  }

  useEffect(() => {
    if (disabled) return

    const target = targetRef.current

    let id: typeof widgetId

    if (!target) return

    turnstile.ready(() => {
      const newId = turnstile.render(target, {
        sitekey,
        theme,
        size: 'flexible',
        callback: token => {
          setToken(token)
          onSuccess(token)
        },
        ['timeout-callback']: onErrorCallback,
        ['expired-callback']: onErrorCallback,
        ['error-callback']: onErrorCallback
      })

      if (!newId) return

      id = newId

      setWidgetId(id)
    })

    return () => {
      if (!id) return
      setWidgetId(undefined)
      turnstile.remove(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  return {
    targetRef,
    widgetId,
    token,
    reset
  }
}

export default useCFTurnstile
