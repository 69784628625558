import { Button, Spinner } from '@genie-fintech/ui/components'
import * as Dialog from '@radix-ui/react-dialog'
import Switch from '$components/Switch'
import { themeVars } from '@genie-fintech/ui/style/theme'

import WarningIcon from '$components/WarningIcon'
import { getPublishUnpublishApp, TAppBasicSetting } from '$services/api'
import { useRequest, useToggle } from 'ahooks'
import { useCallback } from 'react'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { toast } from 'sonner'
import { useAppDetailStore } from '$hooks/stores'

const { colors } = themeVars

interface Iprops {
  is_published?: TAppBasicSetting['is_published']
}

const PublishUnpublishButton = ({ is_published }: Iprops) => {
  const { route } = useRouteSummary()
  const { appId } = route.params

  const [open, { toggle }] = useToggle()

  const updateAppDetailPartially = useAppDetailStore(
    state => state.updateAppDetailPartially
  )

  const { runAsync, loading } = useRequest(getPublishUnpublishApp, {
    manual: true
  })

  const onConfirm = useCallback(() => {
    if (!appId) return
    runAsync({ appId, status: is_published ? 'unpublish' : 'publish' }).then(
      () => {
        toggle()
        toast.success(
          `App has been ${is_published ? 'unpublished' : 'published'}!`
        )
        updateAppDetailPartially({ is_published: !is_published })
      }
    )
  }, [appId, is_published, runAsync, toggle, updateAppDetailPartially])

  const title = `Are you sure, you want to ${is_published ? 'unpublish' : 'publish'} the application?`

  const desc = `The application will be ${is_published ? 'unpublished' : 'published'}. Application users can ${is_published ? 'not' : ''} be seen or used.`

  return (
    <Dialog.Root open={open}>
      <article className="w-[50px] flex items-center justify-center">
        <Switch checked={!!is_published} onChange={toggle} />
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col max-w-[480px]"
          aria-describedby=""
        >
          <Dialog.Title></Dialog.Title>

          <article className="flex flex-col gap-6 p-8">
            <WarningIcon />

            <article className="flex flex-col gap-4">
              <p className="text-xl font-semibold">{title}</p>

              <p className="text-sm" style={{ color: colors.neutral[70] }}>
                {desc}
              </p>
            </article>
          </article>

          <article className="flex justify-end gap-2 p-4">
            <Button
              onClick={toggle}
              styleVariants={{ kind: 'neutral', type: 'outlined' }}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              onClick={onConfirm}
              disabled={loading}
              className="capitalize"
              autoFocus
            >
              {loading && <Spinner />}
              {is_published ? 'Unpublish' : 'Publish'}
            </Button>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PublishUnpublishButton
