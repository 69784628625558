import Table, { Column } from '$components/Table'
import ActionLink from '$components/Table/ActionLink'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { ROUTE_NAMES } from '$router/config'
import { getAppPermissionListByRoleId } from '$services/api'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useRequest } from 'ahooks'
import { useEffect } from 'react'
import { container, innerContainer } from '../styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { flexColumn, flexRow } from '$styles/common.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import SearchBox from '$components/SearchBox'
import { pluralize } from '$app/utils'
import Pagination from '$components/Pagination'

const { colors } = themeVars

type Permission = Awaited<
  ReturnType<typeof getAppPermissionListByRoleId>
>['data'][number]

const PermissionTable = () => {
  const isMounted = useIsMounted()

  const { route } = useRouteSummary()
  const { appId, roleId } = route.params

  const { run, loading, data } = useRequest(getAppPermissionListByRoleId, {
    manual: true
  })

  const { data: permissions = [], meta } = data ?? {}

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !roleId) return

    run({
      application_id: appId,
      roleId,
      q: debouncedSearchValue,
      page,
      per_page: perPage
    })
  }, [appId, roleId, run, debouncedSearchValue, page, perPage])

  const permissionColumns: Column<Permission>[] = [
    {
      name: 'No',
      render: ({ index }) => index + 1
    },
    {
      name: 'Permission Name',
      dataIndex: 'name'
    },
    {
      name: 'Module Name',
      dataIndex: 'module'
    },
    {
      name: '',
      dataIndex: 'id',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_PERMISSION_DETAIL}
          options={{
            params: { ...route.params, permissionId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <article className={container}>
      <article
        className={innerContainer}
        data-ready={trueOrUndefined(isMounted)}
      >
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.two}>ASSIGNED MODULES & PERMISSIONS</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            Configure and review modules and their associated permissions for
            precise access control.
          </p>
        </article>

        <article className={flexColumn} style={{ gap: 8 }}>
          <article className={flexRow} style={{ gap: 4 }}>
            <article style={{ flex: 1, maxWidth: 560 }}>
              <SearchBox
                value={searchValue}
                onChange={updateSearchValue}
                placeholder="Search here..."
              />
            </article>

            <span style={{ flex: 1 }} />

            {!!meta?.total && (
              <span
                className={title.six}
                style={{
                  color: colors.text.disabled,
                  textTransform: 'uppercase'
                }}
              >
                {pluralize(meta.total, 'permission')}
              </span>
            )}
          </article>

          <Table
            data={permissions}
            columns={permissionColumns}
            loading={loading}
          />

          {!!meta?.total && <Pagination meta={meta} pagerProps={pagerProps} />}
        </article>
      </article>
    </article>
  )
}

export default PermissionTable
