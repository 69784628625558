import { z } from 'zod'

export const schema = z.object({
  country_code: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  name: z.string().trim(),
  phone_code: z.string(),
  currency_code: z.string(),
  currency_name: z.string().trim().min(1, 'Requried!'),
  currency_symbol: z.string().trim().min(1, 'Requried!'),
  timezone: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!')
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  country_code: { value: '', label: '' },
  phone_code: '',
  timezone: { value: '', label: '' },
  currency_name: '',
  currency_code: '',
  currency_symbol: ''
}
