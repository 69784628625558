import { on, onOneOf } from '$router/config'
import React from 'react'
import { APPLICATION_ROUTES } from './constants'
import { APP_SIDE_MENUS } from '$layouts/LayoutWithSidebar/constants'
import AppDetail from './AppDetail'
import LayoutWithSidebar from '$layouts/LayoutWithSidebar'

const ApplicationRoutes = () => {
  const isApplicationRoutes = onOneOf(APPLICATION_ROUTES.map(v => v.name))

  if (!isApplicationRoutes) return null

  const FullPageRoutes = APPLICATION_ROUTES.filter(v => !v.withSidebar)
  const WithSidebarRoutes = APPLICATION_ROUTES.filter(v => v.withSidebar)

  return (
    <>
      {onOneOf(FullPageRoutes.map(v => v.name)) && (
        <>
          {APPLICATION_ROUTES.map(
            ({ name, Component }) =>
              on(name) && (
                <React.Fragment key={name}>{Component}</React.Fragment>
              )
          )}
        </>
      )}

      {onOneOf(WithSidebarRoutes.map(v => v.name)) && (
        <AppDetail>
          <LayoutWithSidebar showAppSwitcher menus={APP_SIDE_MENUS}>
            {WithSidebarRoutes.map(
              ({ name, Component }) =>
                on(name) && (
                  <React.Fragment key={name}>{Component}</React.Fragment>
                )
            )}
          </LayoutWithSidebar>
        </AppDetail>
      )}
    </>
  )
}

export default ApplicationRoutes
