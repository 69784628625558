import { Controller, useForm, useWatch } from 'react-hook-form'
import { formContainer, formFooter, createAppFormTitle } from './styles.css'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useCallback } from 'react'
import { description, flexColumn } from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Asterisk from '$components/Asterisk'
import Select from '$components/Select'
import { APP_ENVIRONMENT_OPTIONS } from '$app/constants'
import ErrorField from '$components/ErrorField'
import DragAndDropFileUpload from '$components/DragAndDropFileUpload'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { schema, TField, defaultValues } from './constants'

interface IProps {
  onClose: VoidFunction
  onSubmit: (values: TField) => void
  loading: boolean
}

const CreateAppForm: FC<IProps> = ({
  //onClose,
  onSubmit: onCreate,
  loading
}) => {
  const { control, handleSubmit, setValue } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const brand = useWatch({ name: 'brand', control })

  const fileKey = useWatch({ name: 'key', control })

  const onUploadLogo = useCallback(
    (key: string) => {
      setValue('key', key, { shouldDirty: true })
    },
    [setValue]
  )

  const onChangeBrand = useCallback(
    (brand: string) => {
      setValue('brand', brand, { shouldDirty: true })
    },
    [setValue]
  )

  // const onBack = useCallback(() => {
  //   reset(defaultValues)
  //   onClose()
  // }, [reset, onClose])

  const onSubmit = useCallback(
    (values: TField) => {
      onCreate(values)
    },
    [onCreate]
  )

  return (
    <form className={formContainer} onSubmit={handleSubmit(onSubmit)}>
      <header className={flexColumn} style={{ gap: 4 }}>
        <p className={createAppFormTitle}>Create Application</p>
        <p className={description}>
          You can explore the data table format by downloading this template.
        </p>
      </header>

      <main className={flexColumn} style={{ gap: 16 }}>
        <BaseText
          name="name"
          control={control}
          label="Application Name"
          required
        />

        <Controller
          name="environment"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <article className="flex flex-col gap-y-1">
                <label className="text-sm font-medium">
                  Environment
                  <Asterisk />
                </label>
                <Select
                  {...field}
                  options={APP_ENVIRONMENT_OPTIONS.map(v => ({
                    label: v.value.toLocaleUpperCase(),
                    value: `${v.key}`
                  }))}
                  error={!!error?.message}
                />
                <ErrorField text={error?.message} />
              </article>
            )
          }}
        />

        <DragAndDropFileUpload
          brand={brand}
          logo={{ key: fileKey }}
          onUploadLogo={onUploadLogo}
          onChangeBrand={onChangeBrand}
        />
      </main>

      <footer className={formFooter}>
        <Button
          styleVariants={{ size: 'small' }}
          type="submit"
          disabled={loading}
        >
          {loading && <Spinner />}
          Create
        </Button>
      </footer>
    </form>
  )
}

export default CreateAppForm
