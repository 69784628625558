import { ROUTE_NAMES } from '$router/config'
import { useMount, useRequest, useUnmount } from 'ahooks'
import { APP_USER_MENUS, DASHBOARD_USER_MENUS } from '../constants'
import { USER_TYPE } from '../types'
import { getGlobalDashboardUserDetail, getServiceUserInfo } from '$services/api'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useProfileDataStore } from './useProfileDataStore'

type Props = {
  userType: USER_TYPE
}

export const useProfileData = ({ userType }: Props) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { serviceUserId, dashboardUserId } = params

  const updateProfile = useProfileDataStore(state => state.updateProfile)

  const isServiceUser = userType === USER_TYPE.SERVICE_USER
  const isDashboardUser = userType === USER_TYPE.DASHBOARD_USER

  const serviceUserDetailRequest = useRequest(getServiceUserInfo, {
    manual: true
  })

  const dashboardUserDetailRequest = useRequest(getGlobalDashboardUserDetail, {
    manual: true
  })

  useMount(() => {
    if (serviceUserId) {
      serviceUserDetailRequest
        .runAsync({ userId: serviceUserId })
        .then(({ data }) => {
          updateProfile(data)
        })
    }
  })

  useMount(() => {
    if (dashboardUserId) {
      dashboardUserDetailRequest.runAsync(dashboardUserId).then(({ data }) => {
        updateProfile(data)
      })
    }
  })

  useUnmount(() => {
    updateProfile(undefined)
  })

  const menus = (() => {
    if (isServiceUser) return APP_USER_MENUS
    if (isDashboardUser) return DASHBOARD_USER_MENUS
    return []
  })()

  const breadcrumbCategory = (() => {
    if (isServiceUser) return ROUTE_NAMES.GLOBAL_APP_USERS
    if (isDashboardUser) return ROUTE_NAMES.GLOBAL_DASHBOARD_USERS
    return undefined
  })()

  const isDataFetching =
    serviceUserDetailRequest.loading || dashboardUserDetailRequest.loading

  return {
    profile: {
      id: '96d48137-02e0-4e9e-966b-c131',
      name: 'Eleanor Pena',
      email: 'eleanorpena@gmail.com',
      is_active: false,
      user_type: 'internal',
      email_verifed: true
    },
    menus,
    breadcrumbCategory,
    isDataFetching
  }
}
