import { ROUTE_NAMES } from '$router/config'
import GlobalServiceUserAccessApps from '$pages/GlobalServiceUser/AccessApps'
import GlobalServiceUserDetail from '$pages/GlobalServiceUser/Detail'
import GlobalServiceUserDevicesLogin from '$pages/GlobalServiceUser/DevicesLogin'
import GlobalServiceUserPasswordSetting from '$pages/GlobalServiceUser/PasswordSetting'
import GlobalServiceUserMFASetting from '$pages/GlobalServiceUser/MFASetting'
import GlobalServiceUserAttackProtection from '$pages/GlobalServiceUser/AttackProtection'
import GlobalServiceUserRolesPermissionGroups from '$pages/GlobalServiceUser/RolesPermissionGroup'

export const APPLICATION_USER_ROUTES: {
  name: ROUTE_NAMES
  Component: JSX.Element
}[] = [
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS,
    Component: <GlobalServiceUserAccessApps />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL,
    Component: <GlobalServiceUserDetail />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_ROLES_PERMISSION_GROUPS,
    Component: <GlobalServiceUserRolesPermissionGroups />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN,
    Component: <GlobalServiceUserDevicesLogin />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING,
    Component: <GlobalServiceUserPasswordSetting />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA,
    Component: <GlobalServiceUserMFASetting />
  },
  {
    name: ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACK_PROTECTION,
    Component: <GlobalServiceUserAttackProtection />
  }
]
