import { useState, useEffect, useCallback, FC } from 'react'
import { ArrowUp } from 'lucide-react'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { button } from './styles.css'

type Props = {
  scrollThreshold?: number
}

const ScrollTopButton: FC<Props> = ({ scrollThreshold = 100 }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > scrollThreshold)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollThreshold])

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    <button
      type="button"
      className={button}
      data-visible={trueOrUndefined(isVisible)}
      onClick={scrollToTop}
      aria-label="Scroll to top"
    >
      <ArrowUp size={24} />
    </button>
  )
}

export default ScrollTopButton
