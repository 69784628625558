import CountryForm from '$blocks/Country/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/Country/Form/constants'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useCountryStore } from '$hooks/stores'

const CountryEdit = () => {
  const {
    route: {
      params: { countryId }
    }
  } = useRouteSummary()

  const countries = useCountryStore(state => state.countries)

  console.log({ countries })

  const defaultValues: TFormValues = (() => {
    return (
      countries
        .map(c => ({
          ...c,
          country_code: { label: c.country_code, value: c.country_code },
          timezone: { label: c.timezone, value: c.timezone }
        }))
        .find(d => countryId && d.id === +countryId) ?? DEFAULT_FORM_VALUES
    )
  })()

  return <CountryForm defaultValues={defaultValues} />
}

export default CountryEdit
