import Loading from '$components/Loading'
import { useApiListingParams } from '$hooks/actions'
import { useAppRoleService } from '$hooks/services'
import { TAppRoleListResponse, TID } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  borderNeutral20
} from '$styles/common.css'
import { Checkbox, Tabs } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useEffect, useState } from 'react'
import { useCountryStore } from '$hooks/stores'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { cn } from '$app/utils'
import Pagination from '$components/Pagination'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { viewButton } from '$components/Table/styles.css'
import PreviewPermissionDrawer from '$blocks/PreviewPermissionDrawer'

const { colors } = themeVars

const columns: {
  key: keyof Omit<TAppRoleListResponse['data'][number], 'id'> | 'permission'
  value: string
}[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'display_name', value: 'DISPLAY NAME' },
  { key: 'permission', value: 'PERMISSION' },
  { key: 'group', value: 'GROUP' }
]

interface IProps {
  onChange: (id: TID[]) => void
  roles: TID[]
  errorMessage?: string
  appId?: TID
}

const UserRoles = ({ onChange, roles, errorMessage, appId }: IProps) => {
  const [selectedCountryId, setSelectedCountryId] = useState('')

  const countryOptions = useCountryStore(state => state.countryOptions)

  const {
    fetchAppRoleList,
    fetchingAppRoleList,
    roles: { list, meta }
  } = useAppRoleService()

  const [selectedRole, setSelectedRole] = useState<{
    id: number
    name: string
  }>()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppRoleList({
      page,
      per_page: perPage,
      application_id: appId,
      country_id: selectedCountryId ?? undefined,
      q: debouncedSearchValue
    })
  }, [
    appId,
    fetchAppRoleList,
    page,
    perPage,
    selectedCountryId,
    debouncedSearchValue
  ])

  return (
    <>
      <article
        className={cn(
          'flex flex-col rounded-lg gap-6 px-12 py-7',
          defaultBackground,
          borderNeutral20
        )}
      >
        <article className="flex flex-col gap-1">
          <p className="font-semibold">ASSIGNED ROLES</p>
          <p className="text-xs text-[--colors-neutral-50]">
            Define and assign roles to control access and permissions within the
            system.
          </p>
          {errorMessage && (
            <p
              className="text-xs font-medium"
              style={{ color: colors.danger[markedDefaultKey] }}
            >
              {errorMessage}
            </p>
          )}
        </article>

        <article className="flex flex-col gap-2">
          <article className="flex gap-2 items-center justify-between">
            <article>
              <BaseText
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: searchValue ? (
                    <button onClick={() => updateSearchValue('')}>
                      <Icon namespace="Cross" width={18} />
                    </button>
                  ) : undefined
                }}
                inputProps={{
                  type: 'text',
                  value: searchValue,
                  onChange: e => updateSearchValue(e.currentTarget.value),
                  placeholder: 'Search here...'
                }}
              />
            </article>

            {!!meta?.total && (
              <p className="text-[--colors-text-disabled] text-sm font-medium">
                {meta.total} ROLE{meta.total > 1 && 'S'}
              </p>
            )}
          </article>

          <article>
            <Tabs.Root
              value={selectedCountryId}
              onValueChange={setSelectedCountryId}
              format={{ type: 'segmented' }}
            >
              <Tabs.List styleVariants={{ hAlign: 'left' }}>
                {[{ label: 'All', value: '' }, ...countryOptions].map(
                  (v, k) => (
                    <Tabs.Trigger key={k} value={`${v.value}`}>
                      {v.label}
                    </Tabs.Trigger>
                  )
                )}
              </Tabs.List>
            </Tabs.Root>
          </article>
        </article>

        <article className={customTableContainer}>
          <main className={tableContainerInner}>
            <table className={table({ separator: 'line' })}>
              <thead className={customTableHead}>
                <tr className={tableRow}>
                  <th style={{ width: 0 }} className={customTableCell}>
                    <Checkbox
                      boxProps={{
                        checked:
                          !!list.length &&
                          list.every(d => roles.includes(d.id)),
                        onCheckedChange: checked => {
                          if (checked) {
                            onChange([...roles, ...list.map(v => v.id)])
                            return
                          }

                          onChange(
                            roles.filter(d => !list.map(v => v.id).includes(+d))
                          )
                        }
                      }}
                    />
                  </th>
                  <th className={customTableCell} style={{ width: 0 }}>
                    NO
                  </th>
                  {columns.map((col, key) => (
                    <th key={key} className={customTableCell}>
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className={tableBody}>
                {list.map((data, rowKey) => (
                  <tr key={rowKey} className={tableRow}>
                    <td className={customTableCell}>
                      <Checkbox
                        disabled={!onChange}
                        boxProps={{
                          checked: roles.includes(data.id),
                          onCheckedChange: checked => {
                            if (checked) {
                              onChange([...roles, data.id])
                              return
                            }

                            onChange(roles.filter(d => d !== data.id))
                          }
                        }}
                      />
                    </td>
                    <td className={customTableCell}>
                      {(page - 1) * perPage + rowKey + 1}
                    </td>
                    {columns.map((col, colKey) => (
                      <td key={colKey} className={customTableCell}>
                        {(() => {
                          if (col.key === 'group') {
                            return data.group.name
                          }

                          if (col.key === 'permission') {
                            return (
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedRole({
                                    id: data.id,
                                    name: data.name
                                  })
                                }}
                                style={{ fontSize: 14, fontWeight: 500 }} // to replace with typography class
                                className={viewButton}
                              >
                                View
                              </button>
                            )
                          }

                          return data[col.key] ?? 'N/A'
                        })()}
                      </td>
                    ))}
                  </tr>
                ))}

                {!list.length && !fetchingAppRoleList && (
                  <tr className={tableRow}>
                    <td
                      colSpan={columns.length + 2}
                      style={{ textAlign: 'center' }}
                      className={customTableCell}
                    >
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </main>

          {fetchingAppRoleList && <Loading />}
        </article>

        {!!list.length && (
          <footer className="flex items-center gap-2 justify-between bg-[--colors-area-high] p-4">
            <Pagination meta={meta} pagerProps={pagerProps} />
          </footer>
        )}
      </article>

      <PreviewPermissionDrawer
        role={selectedRole}
        appId={appId}
        onClose={() => setSelectedRole(undefined)}
      />
    </>
  )
}

export default UserRoles
