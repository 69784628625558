import DeleteWithConfirm from '$components/DeleteWithConfirm'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { deleteWebhook } from '$services/api'
import { useRequest } from 'ahooks'
import { useCallback } from 'react'
import { toast } from 'sonner'

type Props = {
  webhookId: string
  onDeleteCallBackFn?: (webhookId: string) => void
}

const DeleteWebhookButton = ({ webhookId, onDeleteCallBackFn }: Props) => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const { loading: removingWebhook, runAsync: removeWebhookAsync } = useRequest(
    deleteWebhook,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Webhook has been deleted!')
      }
    }
  )

  const onDeleteWebhook = useCallback(() => {
    if (!appId) return

    removeWebhookAsync(appId, webhookId).then(() => {
      onDeleteCallBackFn?.(webhookId)
    })
  }, [appId, removeWebhookAsync, webhookId, onDeleteCallBackFn])

  return (
    <DeleteWithConfirm onConfirm={onDeleteWebhook} loading={removingWebhook} />
  )
}

export default DeleteWebhookButton
