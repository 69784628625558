import { useController } from 'react-hook-form'

import { FieldContainer } from '@genie-fintech/ui/components/fields'
import { resolveErrorMessage } from '@genie-fintech/ui/components/hook-fields/common/functions'
import { HookField } from '@genie-fintech/ui/components/hook-fields/common/types'

import { CF_TURNSTILE_ENABLED } from '$app/constants'
import useCFTurnstile from '$hooks/actions/useCFTurnstile'

export const CFTurnstile: HookField = ({
  name,
  control,
  disabled: explictDisabled,
  controllerOptions
}) => {
  const disabled = explictDisabled || !CF_TURNSTILE_ENABLED

  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { onChange } = field

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const { targetRef } = useCFTurnstile<HTMLDivElement>({
    disabled,
    onSuccess: onChange,
    onError: () => onChange(''),
    onReset: () => onChange('')
  })

  if (disabled) return <></>

  return (
    <FieldContainer disabled={disabled} error={hasError} message={errorMessage}>
      {() => <div ref={targetRef} />}
    </FieldContainer>
  )
}

export default CFTurnstile
