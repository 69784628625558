import EnterTransitionBlock from '$components/EnterTransitionBlock'
import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { description, flexColumn, flexRow } from '$styles/common.css'
import { Button, Checkbox, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { zodResolver } from '@hookform/resolvers/zod'
import { AlertTriangle, Clock, Lock, LogOut, MapPin } from 'lucide-react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import { useGlobalServiceUserService } from '$hooks/services'
import { useBoolean, useCountDown, useMount } from 'ahooks'
import { grid, lockIcon, resetLinkWarningBadge } from './styles.css'
import { BaseText } from '@genie-fintech/ui/components/fields'
import {
  checkValueIsNumberOnly,
  formatDateWithGMT,
  formatTime
} from '$app/utils'
import { useCallback, useMemo, useState } from 'react'
import { TGlobalServiceUserPasswordSetting } from '$services/api'
import { toast } from 'sonner'
import Switch from '$components/Switch'
import PopupModal from '$components/PopupModal'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import CopyButton from '$components/CopyButon/v2'
import { redirect, ROUTE_NAMES } from '$router/config'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import Loading from '$components/Loading'
import OTPSetting from './blocks/OTPSetting'
import { useShallow } from 'zustand/react/shallow'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { toClassNames } from '@genie-fintech/ui/functions'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

enum ACTION_TYPE {
  FORCE_UPDATE,
  FORCE_LOGOUT
}

const GlobalServiceUserPasswordSetting = () => {
  const [actionType, setActionType] = useState<ACTION_TYPE>()

  const [resetLinkExpiredAt, setResetLinkExpiredAt] = useState<number>()

  const [resetLinkURL, setResetLinkURL] = useState('')

  const [open, { setTrue, setFalse }] = useBoolean()

  const profile = useProfileDataStore(useShallow(state => state.profile))

  const {
    id: userId,
    location,
    ip_address,
    password_updated_at
  } = profile ?? {}

  const {
    fetchServiceUserPasswordSettingAsync,
    fetchingServiceUserPasswordSetting,
    passwordSetting,
    saveServiceUserPasswordSettingAsync,
    savingServiceUserPasswordSetting,
    makeforceLogoutServiceUserAsync,
    makingforceLogoutServiceUser,
    generateResetPasswordLinkAsync,
    generatingResetPasswordLink,
    deleteResetPasswordLinkAsync,
    deletingResetPasswordLink,
    updateforceUpdateServiceUserAsync,
    updatingforceUpdateServiceUser
  } = useGlobalServiceUserService()

  const targetDate = useMemo(
    () =>
      resetLinkExpiredAt ? Date.now() + resetLinkExpiredAt * 1000 : undefined,
    [resetLinkExpiredAt]
  )

  const [, { seconds, minutes }] = useCountDown({ targetDate })

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  useBreadcrumb([{ name: 'Password Setting' }])

  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { isDirty },
    resetField
  } = methods

  const has_password_expiration_days_value = useWatch({
    name: 'has_password_expiration_days',
    control
  })

  const resetFormValue = useCallback(
    (value: TGlobalServiceUserPasswordSetting) => {
      reset({
        has_password_expiration_days: !!value.password_expiration_days,
        password_expiration_days: `${value.password_expiration_days}`,
        access_token_expires_in: `${value.access_token_expires_in || ''}`,
        password_history_depth: `${value.password_history_depth}`,
        should_reset_password: value.should_reset_password
      })
    },
    [reset]
  )

  const fetchPasswordSetting = useCallback(async () => {
    if (!userId) return
    await fetchServiceUserPasswordSettingAsync({ userId }).then(({ data }) => {
      resetFormValue(data)
      setResetLinkExpiredAt(data.password_reset_link_expired_in ?? undefined)
    })
  }, [userId, fetchServiceUserPasswordSettingAsync, resetFormValue])

  useMount(fetchPasswordSetting)

  const onReset = useCallback(() => {
    if (!passwordSetting) return
    resetFormValue(passwordSetting)
  }, [passwordSetting, resetFormValue])

  const updatePasswordSetting = useCallback(
    (payload: Parameters<typeof saveServiceUserPasswordSettingAsync>[1]) => {
      if (!userId) return

      saveServiceUserPasswordSettingAsync(userId, payload).then(() => {
        fetchPasswordSetting()
        toast.success('Successfully Updated')
      })
    },
    [userId, saveServiceUserPasswordSettingAsync, fetchPasswordSetting]
  )

  const onSave = useCallback(
    (value: TFormValues) => {
      if (!userId) return

      const payload = {
        access_token_expires_in: value.access_token_expires_in
          ? +value.access_token_expires_in
          : null,
        password_history_depth: +value.password_history_depth,
        password_expiration_days: +value.password_expiration_days
      }

      updatePasswordSetting(payload)
    },
    [userId, updatePasswordSetting]
  )

  const onOpenModal = useCallback(
    (type: ACTION_TYPE) => {
      setActionType(type)
      setTrue()
    },
    [setTrue]
  )

  const onConfirm = useCallback(() => {
    if (!passwordSetting || !userId) return

    if (actionType === ACTION_TYPE.FORCE_UPDATE) {
      updateforceUpdateServiceUserAsync(userId, {
        should_reset_password: !passwordSetting.should_reset_password
      })
        .then(fetchPasswordSetting)
        .then(() => {
          toast.success('Successfully updated!')
        })
    }

    if (actionType === ACTION_TYPE.FORCE_LOGOUT) {
      makeforceLogoutServiceUserAsync(userId).then(() => {
        toast.success('Successfully force logout!')
      })
    }
  }, [
    actionType,
    passwordSetting,
    updateforceUpdateServiceUserAsync,
    fetchPasswordSetting,
    userId,
    makeforceLogoutServiceUserAsync
  ])

  const onGenerateResetLink = useCallback(() => {
    if (!userId) return

    generateResetPasswordLinkAsync(userId).then(({ data }) => {
      setResetLinkURL(data.url)
      setResetLinkExpiredAt(3600)
    })
  }, [userId, generateResetPasswordLinkAsync])

  const onDeleteResetLink = useCallback(() => {
    if (!userId) return

    deleteResetPasswordLinkAsync(userId).then(() => {
      setResetLinkURL('')
      setResetLinkExpiredAt(undefined)
    })
  }, [userId, deleteResetPasswordLinkAsync])

  const onUpdatePassword = useCallback(() => {
    redirect(ROUTE_NAMES.UPDATE_PASSWORD, {
      params: { userId },
      queryParams: { source: ROUTE_NAMES.GLOBAL_APP_USER_DETAIL }
    })
  }, [userId])

  const isUpdatingPasswordSetting =
    savingServiceUserPasswordSetting || fetchingServiceUserPasswordSetting

  if (!passwordSetting) return <Loading />

  return (
    <>
      <PopupModal open={open} onConfirm={onConfirm} onClose={setFalse} />

      <MainHeader
        title="PASSWORD SETTING"
        desc="Manage and review user's password for secure the system administration."
      />

      <article
        className={mainContent}
        style={{ borderBottom: `1px solid ${colors.neutral[10]}` }}
      >
        <article className={innerMainContent} style={{ gap: 40 }}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>PASSWORD</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Need a new password? Click the link below to update or reset it
              and ensure your account stays secure.
            </p>
          </article>

          <article className={grid}>
            <article className={flexColumn} style={{ gap: 20 }}>
              <article className={lockIcon}>
                <Lock size={24} />
              </article>

              <p className={title.six} style={{ color: colors.text.light }}>
                The password is encrypted and can not be seen.
                <br />
                However, you can still update or generate reset password link.
              </p>

              <article className={flexRow} style={{ gap: 20 }}>
                <Button
                  styleVariants={{ type: 'outlined', size: 'small' }}
                  onClick={onUpdatePassword}
                >
                  Update
                </Button>

                <span
                  className={title.six}
                  style={{ color: colors.text.disabled }}
                >
                  OR
                </span>

                <Button
                  styleVariants={{ type: 'text', size: 'small' }}
                  disabled={!!resetLinkExpiredAt || generatingResetPasswordLink}
                  onClick={onGenerateResetLink}
                >
                  Generate Reset Link
                </Button>
              </article>

              {!!resetLinkExpiredAt && (
                <article className={flexColumn} style={{ gap: 12 }}>
                  <article
                    className={flexRow}
                    style={{
                      padding: '8px 16px',
                      borderRadius: 8,
                      border: `1px solid ${colors.neutral[10]}`
                    }}
                  >
                    <article
                      className={flexColumn}
                      style={{ gap: 4, flex: 1, minWidth: 0 }}
                    >
                      <p
                        className={footnote.two}
                        style={{ color: colors.neutral[70] }}
                      >
                        {resetLinkURL ? 'Genereated' : ''} Reset Password Link
                      </p>

                      {!!resetLinkURL && (
                        <p
                          style={{
                            fontSize: 10,
                            color: colors.neutral[70],
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          #{resetLinkURL}
                        </p>
                      )}
                    </article>

                    {!!resetLinkURL && (
                      <CopyButton
                        color="text.disabled"
                        size={16}
                        value={resetLinkURL}
                      />
                    )}

                    <DeleteWithConfirm
                      onConfirm={onDeleteResetLink}
                      loading={deletingResetPasswordLink}
                    />
                  </article>

                  <article
                    className={flexRow}
                    style={{ gap: 4, justifyContent: 'space-between' }}
                  >
                    <Button
                      styleVariants={{
                        type: 'text',
                        kind: 'neutral',
                        size: 'small'
                      }}
                      style={{ pointerEvents: 'none' }}
                    >
                      <Clock size={16} />
                      <span>
                        {formatTime(minutes)}:{formatTime(seconds)} min
                      </span>
                    </Button>

                    <article className={resetLinkWarningBadge}>
                      <AlertTriangle size={16} />
                      Generated link has expiration time.
                    </article>
                  </article>
                </article>
              )}
            </article>

            <article
              className={flexColumn}
              style={{ gap: 8, justifyContent: 'end' }}
            >
              <p
                className={footnote.three}
                style={{ color: colors.text.light }}
              >
                PASSWORD LAST UPDATED
              </p>

              <article
                className={toClassNames(footnote.two, flexRow)}
                style={{
                  gap: 6,
                  padding: '2px 0',
                  color: colors.warning[markedDefaultKey]
                }}
              >
                <Clock size={16} />
                <span style={{ flex: 1 }}>Last Updated</span>
                <span>{formatDateWithGMT(password_updated_at)}</span>
              </article>

              <article
                className={toClassNames(flexRow, footnote.one)}
                style={{
                  color: colors.text.light,
                  gap: 6
                }}
              >
                <MapPin size={16} />
                <span style={{ flex: 1 }}>Location</span>
                <span>{location}</span>
              </article>

              <article
                className={toClassNames(flexRow, footnote.one)}
                style={{
                  color: colors.text.light,
                  gap: 6
                }}
              >
                <MapPin size={16} />
                <span style={{ flex: 1 }}>IP Address</span>
                <span>{ip_address}</span>
              </article>
            </article>
          </article>
        </article>
      </article>

      <article
        className={mainContent}
        style={{ borderBottom: `1px solid ${colors.neutral[10]}` }}
      >
        <article className={innerMainContent} style={{ gap: 40 }}>
          <OTPSetting userId={userId} />
        </article>
      </article>

      <article
        className={mainContent}
        style={{ borderBottom: `1px solid ${colors.neutral[10]}` }}
      >
        <form
          className={innerMainContent}
          style={{ gap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <article className={grid}>
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>PASSWORD SECURITY CONFIGURATION</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                As part of our security measures, we require some settings as
                configuration methods to protect your account.
              </p>
            </article>

            <article className={flexColumn} style={{ gap: 16 }}>
              <Controller
                name="has_password_expiration_days"
                control={control}
                render={({ field }) => {
                  return (
                    <Checkbox
                      label="Password update required"
                      styleVariants={{ bordered: true, padded: true }}
                      boxProps={{
                        checked: field.value,
                        onCheckedChange: checked => {
                          field.onChange(checked)
                          resetField('password_expiration_days')
                          setValue(
                            'password_expiration_days',
                            checked
                              ? `${passwordSetting.password_expiration_days ?? 0}`
                              : '0'
                          )
                        }
                      }}
                    />
                  )
                }}
              />

              <Controller
                name="password_expiration_days"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <BaseText
                      label="Password expiry every"
                      affix={{ post: 'days' }}
                      inputProps={{
                        value: field.value,
                        onChange: e => {
                          const { value } = e.currentTarget
                          if (value && !checkValueIsNumberOnly(value)) return
                          field.onChange(value)
                        }
                      }}
                      disabled={!has_password_expiration_days_value}
                      message={error?.message}
                      error={!!error?.message}
                      required={!!has_password_expiration_days_value}
                    />
                  )
                }}
              />

              <Controller
                name="access_token_expires_in"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <BaseText
                      label="Daily login required"
                      affix={{ post: 'days' }}
                      inputProps={{
                        value: field.value,
                        onChange: e => {
                          const { value } = e.currentTarget
                          if (value && !checkValueIsNumberOnly(value)) return
                          field.onChange(value)
                        }
                      }}
                      required
                      message={error?.message}
                      error={!!error?.message}
                    />
                  )
                }}
              />

              <Controller
                name="password_history_depth"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <BaseText
                      label="Previous passwords can not be reused"
                      affix={{ post: 'Passwords' }}
                      inputProps={{
                        value: field.value,
                        onChange: e => {
                          const { value } = e.currentTarget
                          if (value && !checkValueIsNumberOnly(value)) return
                          field.onChange(value)
                        }
                      }}
                      required
                      message={error?.message}
                      error={!!error?.message}
                    />
                  )
                }}
              />
            </article>
          </article>

          {isDirty && (
            <EnterTransitionBlock
              className={flexRow}
              style={{ gap: 10, justifyContent: 'flex-end' }}
            >
              <Button
                styleVariants={{ type: 'outlined', size: 'small' }}
                onClick={onReset}
                disabled={isUpdatingPasswordSetting}
              >
                Reset
              </Button>

              <Button
                type="submit"
                disabled={isUpdatingPasswordSetting}
                styleVariants={{ size: 'small' }}
              >
                {isUpdatingPasswordSetting && <Spinner />}
                Save Changes
              </Button>
            </EnterTransitionBlock>
          )}
        </form>
      </article>

      <article className={mainContent}>
        <article className={innerMainContent}>
          <article className={grid}>
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}> FORCE TO CONFIGURATION </p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                As part of our security measures, we require some settings by
                force method to protect your account.
              </p>
            </article>

            <article className={flexColumn} style={{ gap: 12 }}>
              <article
                className="flex items-center p-4 gap-4 rounded border justify-between"
                style={{ borderColor: colors.neutral[10] }}
              >
                <p className={title.six} style={{ color: colors.text.light }}>
                  FORCE TO UPDATE PASSWORD
                </p>

                {updatingforceUpdateServiceUser ? (
                  <Spinner />
                ) : (
                  <Switch
                    checked={!!passwordSetting?.should_reset_password}
                    onChange={() => onOpenModal(ACTION_TYPE.FORCE_UPDATE)}
                  />
                )}
              </article>

              <article
                className="flex items-center gap-4 rounded p-4"
                style={{ background: colors.alphaDanger[0] }}
              >
                <article className="flex flex-col gap-1 flex-1">
                  <p className={title.two}>FORCE TO LOG OUT</p>
                  <p className={description}>
                    In case of any emergency for securring the account.
                  </p>
                </article>

                <Button
                  onClick={() => onOpenModal(ACTION_TYPE.FORCE_LOGOUT)}
                  styleVariants={{
                    type: 'text',
                    kind: 'danger',
                    size: 'small'
                  }}
                  disabled={makingforceLogoutServiceUser}
                >
                  Log Out
                  {makingforceLogoutServiceUser ? (
                    <Spinner />
                  ) : (
                    <LogOut size={16} />
                  )}
                </Button>
              </article>
            </article>
          </article>
        </article>
      </article>

      {/* <main className={mainSubContainerStyle} style={{ gap: 40 }}>
        <article className="flex flex-col gap-1">
          <p className={title.two}>PASSWORD</p>
          <p className={description}>
            Need a new password? Click the link below to update or reset it
            and ensure your account stays secure.
          </p>
        </article>

        <article className="flex flex-col gap-5">
          <article className="flex flex-col gap-4">
            <article
              className="inline-flex w-10 h-10 rounded-lg items-center justify-center"
              style={{
                background: colors.area.low,
                color: colors.neutral[60]
              }}
            >
              <Lock size={24} />
            </article>

            <p className={title.six} style={{ color: colors.text.light }}>
              The password is encrypted and can not be seen.
              <br />
              However, you can still update or generate reset password link.
            </p>
          </article>

          <article className="flex items-center gap-5">
            <Button
              styleVariants={{ type: 'outlined', size: 'small' }}
              onClick={onUpdatePassword}
            >
              Update
            </Button>

            <span
              className={title.six}
              style={{ color: colors.text.disabled }}
            >
              OR
            </span>

            <Button
              styleVariants={{ type: 'text', size: 'small' }}
              disabled={!!resetLinkExpiredAt || generatingResetPasswordLink}
              onClick={onGenerateResetLink}
            >
              Generate Reset Link
            </Button>
          </article>

          {!!resetLinkExpiredAt && (
            <article className="flex flex-col gap-3 max-w-[400px]">
              <article
                className={cn(
                  'flex items-center px-4 py-2 rounded-md border',
                  shadow.small
                )}
              >
                <article className="flex flex-col gap-1 flex-1 min-w-0">
                  <p
                    className={footnote.two}
                    style={{ color: colors.neutral[70] }}
                  >
                    {resetLinkURL ? 'Genereated' : ''} Reset Password Link
                  </p>

                  {!!resetLinkURL && (
                    <p
                      className="truncate"
                      style={{ fontSize: 10, color: colors.neutral[70] }}
                    >
                      #{resetLinkURL}
                    </p>
                  )}
                </article>

                {!!resetLinkURL && (
                  <CopyButton
                    color="text.disabled"
                    size={16}
                    value={resetLinkURL}
                  />
                )}

                <DeleteWithConfirm
                  onConfirm={onDeleteResetLink}
                  loading={deletingResetPasswordLink}
                />
              </article>

              <article className="flex items-center justify-between gap-1">
                <Button
                  styleVariants={{
                    type: 'text',
                    kind: 'neutral',
                    size: 'small'
                  }}
                  className="pointer-events-none"
                >
                  <Clock size={16} />
                  <span>
                    {formatTime(minutes)}:{formatTime(seconds)} min
                  </span>
                </Button>

                <article className={resetLinkWarningBadge}>
                  <AlertTriangle size={16} />
                  Generated link has expiration time.
                </article>
              </article>
            </article>
          )}
        </article>

        <article className="flex flex-col gap-2">
          <p className={footnote.three} style={{ color: colors.text.light }}>
            PASSWORD LAST UPDATED
          </p>

          <article
            className={cn(
              'flex items-center gap-1.5 py-0.5 max-w-[400px]',
              footnote.two
            )}
            style={{ color: colors.warning[markedDefaultKey] }}
          >
            <Clock size={16} />
            <span className="flex-1">Last Updated</span>
            <span>{formatDateWithGMT(password_updated_at)}</span>
          </article>

          <article
            className={cn(
              'flex items-center gap-1.5 max-w-[400px]',
              footnote.one
            )}
            style={{ color: colors.text.light }}
          >
            <MapPin size={16} />
            <span className="flex-1">Location</span>
            <span>{location}</span>
          </article>

          <article
            className={cn(
              'flex items-center gap-1.5 max-w-[400px]',
              footnote.one
            )}
            style={{ color: colors.text.light }}
          >
            <MapPin size={16} />
            <span className="flex-1">IP Address</span>
            <span>{ip_address}</span>
          </article>
        </article>

        <Line />

        <OTPSetting userId={userId} />

        <Line />

        

        <Line />

        
      </main> */}
    </>
  )
}

export default GlobalServiceUserPasswordSetting
