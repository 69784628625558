import Link, { LinkProps } from '$router/Link'
import { flexRow } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { ArrowUpRight } from 'lucide-react'
import { FC } from 'react'

const ActionLink: FC<LinkProps> = ({ to, options }) => {
  return (
    <article className={flexRow} style={{ justifyContent: 'flex-end' }}>
      <Link to={to} options={options} style={{ justifySelf: 'end' }}>
        <Button
          styleVariants={{
            type: 'text',
            category: 'icon',
            kind: 'neutral'
          }}
        >
          <ArrowUpRight size={20} />
        </Button>
        <span />
      </Link>
    </article>
  )
}

export default ActionLink
