import { FC } from 'react'

import { UsePagerReturn } from '../../hooks'
import { TagsNoRef, ToReferences } from '../../types'
import { propsWithClassNames } from '../../functions'

import {
  NavVariants,
  controls,
  controlsContent,
  nav,
  pages,
  separator
} from './style.css'
import { Icon } from '../../icons'

type ButtonProps = Omit<TagsNoRef<'button'>, 'type'>

type VNodes = {
  navRef: HTMLElement
}
type References = Partial<ToReferences<VNodes>>

type PagerData = Pick<
  UsePagerReturn,
  'hasPreviousPage' | 'hasNextPage' | 'info' | 'jump' | 'toNext' | 'toPrevious'
>

export type PagerProps = PagerData & {
  navProps?: TagsNoRef<'nav'>
  previousButtonProps?: ButtonProps
  nextButtonProps?: ButtonProps
} & Pick<NavVariants, 'align'> &
  References

export const Pager: FC<PagerProps> = ({
  hasPreviousPage,
  hasNextPage,
  info,
  jump,
  toNext,
  toPrevious,

  previousButtonProps,
  nextButtonProps,
  navProps,
  align,
  navRef
}) => (
  <nav ref={navRef} {...propsWithClassNames(navProps, nav({ align }))}>
    <button
      title="previous"
      type="button"
      disabled={!hasPreviousPage}
      onClick={toPrevious}
      {...propsWithClassNames(previousButtonProps, controls)}
    >
      <span className={controlsContent}>
        <Icon namespace="Backward" />
      </span>
    </button>

    {info.map(({ isSeparator, text, isActive: active, page }) => (
      <button
        type="button"
        key={text}
        className={isSeparator ? separator : pages({ active })}
        onClick={() => {
          if (!page) return

          jump(page)
        }}
      >
        <span className={controlsContent}>
          {isSeparator ? <Icon namespace="MoreHorizontal" /> : text}
        </span>
      </button>
    ))}

    <button
      title="next"
      type="button"
      disabled={!hasNextPage}
      onClick={toNext}
      {...propsWithClassNames(nextButtonProps, controls)}
    >
      <span className={controlsContent}>
        <Icon namespace="Forward" />
      </span>
    </button>
  </nav>
)

export default Pager
