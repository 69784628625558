import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import PublishAppSetting from '$blocks/AppHome/sections/PublishAppSetting'
import AppSummaryCard from '$blocks/AppHome/sections/AppSummaryCard'
import { ItemType } from '$blocks/AppHome/sections/AppSummaryCard/types'
import Last30MinUserChart from '$blocks/AppHome/sections/Last30MinUserChart'
import DailyActiveUserChart from '$blocks/AppHome/sections/DailyActiveUserChart'
import Locations from '$blocks/AppHome/sections/Locations'
import Devices from '$blocks/AppHome/sections/Devices'
import {
  container,
  innerContainer,
  threeColumnsContainer,
  twoColumnsContainer
} from './styles.css'
import { useMount } from 'ahooks'
import Loading from '$components/Loading'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const AppHome = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { fetchAppHome, appHome, fetchingAppHome } = useAppHomeService()

  const {
    user_count = 0,
    group_count = 0,
    role_count = 0,
    today_active_user_count = 0,
    recent_active_user_counts = [],
    active_users = []
  } = appHome ?? {}

  useBreadcrumb([{ name: 'Home' }])

  useMount(() => {
    if (!appId) return
    fetchAppHome({ application_id: appId })
  })

  if (fetchingAppHome) return <Loading />

  return (
    <>
      <PublishAppSetting />

      <article className={container}>
        <article className={innerContainer}>
          <article className={threeColumnsContainer}>
            <AppSummaryCard item={ItemType.USER} count={user_count} />
            <AppSummaryCard item={ItemType.ROLE} count={role_count} />
            <AppSummaryCard item={ItemType.GROUP} count={group_count} />
          </article>

          <article className={twoColumnsContainer}>
            <Last30MinUserChart
              recent_active_users={recent_active_user_counts}
            />

            <DailyActiveUserChart
              active_users={active_users}
              today_active_user_count={today_active_user_count}
            />
          </article>

          <article className={twoColumnsContainer}>
            <Locations />

            <Devices />
          </article>
        </article>
      </article>
    </>
  )
}

export default AppHome
