import { Button, Spinner } from '@genie-fintech/ui/components'
import { container, innerContainer, line, modalFooter } from './styles.css'
import * as Dialog from '@radix-ui/react-dialog'
import { useRequest, useToggle } from 'ahooks'
import { useCallback } from 'react'
import {
  BaseText as BaseHookFieldText,
  Password
} from '@genie-fintech/ui/components/hook-fields'
import { Controller, useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { putServiceUserPhone } from '$services/api'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'
import Select from '$components/Select'
import { useCountryStore } from '$hooks/stores'
import { getPhone } from '$app/utils'
import { Icon } from '@genie-fintech/ui/icons'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { flexColumn } from '$styles/common.css'

const { colors } = themeVars

const ChangePhoneSetting = () => {
  const isMounted = useIsMounted()

  const [modalOpen, { toggle }] = useToggle()

  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const profile = useProfileDataStore(useShallow(state => state.profile))

  const updateProfilePartially = useProfileDataStore(
    state => state.updateProfilePartially
  )

  const { phone_code, phone_no, id: userId = '' } = profile ?? {}

  const {
    runAsync: updateServiceUserPhone,
    loading: updatingServiceUserPhone
  } = useRequest(putServiceUserPhone, {
    manual: true,
    onSuccess: (_, [{ phone_code, phone_no }]) => {
      updateProfilePartially({ phone_code, phone_no })
      toggle()
      reset(DEFAULT_FORM_VALUES)
      toast.success('Phone has been updated!')
    }
  })

  const onCancel = useCallback(() => {
    reset(DEFAULT_FORM_VALUES)
    toggle()
  }, [reset, toggle])

  const onContinue = useCallback(
    ({ phone_code, ...payload }: TField) => {
      updateServiceUserPhone({
        userId,
        phone_code: phone_code.value,
        ...payload
      })
    },
    [updateServiceUserPhone, userId]
  )

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const hasPhone = phone_code || phone_no

  return (
    <>
      <article className={container} data-ready={trueOrUndefined(isMounted)}>
        <article className={innerContainer}>
          <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
            <p className={title.two}>ADD/EDIT PHONE NUMBER</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              You can update your phone number with anytime.
            </p>
          </article>

          <p className={title.five} style={{ color: colors.text.disabled }}>
            {getPhone(phone_code, phone_no)}
          </p>

          <Button
            onClick={toggle}
            styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
          >
            {!hasPhone && <Icon namespace="Add" />}
            {hasPhone ? 'Change' : 'Add Phone Number'}
          </Button>
        </article>
      </article>

      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[500px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            <form onSubmit={handleSubmit(onContinue)}>
              <main className={flexColumn} style={{ padding: 32, gap: 24 }}>
                <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
                  <p className={title.two}>
                    {hasPhone ? 'ENTER NEW PHONE NUMBER' : 'ADD PHONE NUMBER'}
                  </p>
                  <p
                    className={footnote.one}
                    style={{ color: colors.neutral[60] }}
                  >
                    {hasPhone
                      ? 'Enter new phone number to continue the changes.'
                      : 'Enter phone number to continue the process.'}
                  </p>
                </article>

                <article className={flexColumn} style={{ gap: 32 }}>
                  <Password
                    name="password"
                    control={control}
                    label="Password"
                    description="Enter your login password to confirm the changes."
                    required
                  />

                  <hr className={line} />

                  <article className={flexColumn} style={{ gap: 16 }}>
                    {hasPhone && (
                      <article className="relative">
                        <BaseText
                          label="Current Phone Number"
                          inputProps={{
                            className: 'pl-[100px]',
                            defaultValue: phone_no ?? '',
                            readOnly: true
                          }}
                          disabled
                        />

                        <article className="absolute bottom-0 left-0 w-[100px]">
                          <Select
                            value={{
                              label: phone_code ?? '',
                              value: phone_code ?? ''
                            }}
                            options={phoneOptions}
                            disabled
                            type="sub"
                          />
                        </article>
                      </article>
                    )}

                    <article className="relative">
                      <BaseHookFieldText
                        name="phone_no"
                        control={control}
                        label={hasPhone ? 'New Phone Number' : 'Phone Number'}
                        inputProps={{ className: 'pl-[100px]' }}
                        required
                      />

                      <article className="absolute top-[27px] left-0 w-[100px]">
                        <Controller
                          name="phone_code"
                          control={control}
                          render={({ field }) => {
                            return (
                              <Select
                                value={field.value}
                                onChange={field.onChange}
                                options={phoneOptions}
                                type="sub"
                                menuPlacement="top"
                              />
                            )
                          }}
                        />
                      </article>
                    </article>
                  </article>
                </article>
              </main>

              <footer className={modalFooter}>
                <Button
                  type="button"
                  styleVariants={{
                    kind: 'neutral',
                    type: 'outlined',
                    size: 'small'
                  }}
                  onClick={onCancel}
                  disabled={updatingServiceUserPhone}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styleVariants={{ size: 'small' }}
                  disabled={updatingServiceUserPhone}
                >
                  {updatingServiceUserPhone && <Spinner />}
                  Continue
                </Button>
              </footer>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default ChangePhoneSetting
