import { create } from 'zustand'
import { BREADCRUMB } from '../types'

interface IBreadcrumbState {
  breadcrumb: BREADCRUMB[]
  updateBreadcrumb: (breadcrumb: IBreadcrumbState['breadcrumb']) => void
}

export const useBreadcrumbStore = create<IBreadcrumbState>()(set => ({
  breadcrumb: [],
  updateBreadcrumb: breadcrumb => set({ breadcrumb })
}))
