import { useTheme } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import { container } from './styles.css'

const ThemeToggleButton = () => {
  const {
    mode: { isDarkMode },
    toggleTheme
  } = useTheme()

  return (
    <button type="button" className={container} onClick={toggleTheme}>
      <Icon
        namespace={isDarkMode ? 'Dark' : 'Light'}
        color="absolute.light"
        width={16}
      />
    </button>
  )
}

export default ThemeToggleButton
