import { redirect, ROUTE_NAMES } from '$router/config'
import { useCallback, useState } from 'react'
import QueryUser from './QueryUser'
import UserForm from './Form'
import PreviewScreen from './PreviewScreen'
import { TSavedUserInfo } from './types'
import { useRouteSummary } from '$contexts/RouteContext/hooks'

const AppUserCreate = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const [isQueryUserSuccess, setIsQueryUserSuccess] = useState(false)

  const [userId, setUserId] = useState('')

  const [userEmail, setUserEmail] = useState('')

  const [savedUserInfo, setSavedUserInfo] = useState<
    TSavedUserInfo | undefined
  >(undefined)

  const onQuerySuccess = useCallback((email: string, id?: string) => {
    setUserId(id ?? '')
    setIsQueryUserSuccess(true)
    setUserEmail(email)
  }, [])

  const onQueryAgain = useCallback(() => {
    setUserId('')
    setIsQueryUserSuccess(false)
  }, [])

  const onSuccess = useCallback(
    (value: TSavedUserInfo) => {
      setSavedUserInfo(value)
      if (userId) redirect(ROUTE_NAMES.APP_USERS, { params })
    },
    [userId, params]
  )

  return (
    <article className="flex flex-col w-full max-w-[1056px] mx-auto gap-2">
      {!isQueryUserSuccess && (
        <QueryUser email={userEmail} onSuccess={onQuerySuccess} />
      )}

      {isQueryUserSuccess && !savedUserInfo && (
        <UserForm
          userId={userId}
          onQueryAgain={onQueryAgain}
          onSuccess={onSuccess}
          email={userEmail}
        />
      )}

      {savedUserInfo && !userId && (
        <PreviewScreen
          userId={savedUserInfo.id}
          name={savedUserInfo.name}
          email={userEmail}
          password={savedUserInfo.password}
          userType="service"
        />
      )}
    </article>
  )
}

export default AppUserCreate
