import { PERMISSIONS } from '$app/constants'
import { ROUTE_NAMES, RouteNames } from '$router/config'
import {
  Folders,
  GalleryHorizontalEnd,
  GitFork,
  Globe2,
  Home,
  Key,
  ListTree,
  LucideIcon,
  Network,
  Settings,
  Settings2,
  ShieldCheck,
  Users2
} from 'lucide-react'

export type MENU_ITEM = {
  name: string
  path: RouteNames
  Icon: LucideIcon
  subPath?: RouteNames[]
  permissions: PERMISSIONS[]
}

export type MENU = {
  title: string
  menus: MENU_ITEM[]
}

export const APP_SIDE_MENUS: MENU[] = [
  {
    title: '',
    menus: [
      {
        name: 'Home',
        path: ROUTE_NAMES.APP_HOME,
        Icon: Home,
        permissions: []
      },
      {
        name: 'Basic Setting',
        path: ROUTE_NAMES.APP_BASIC_SETTING,
        Icon: Settings2,
        permissions: [PERMISSIONS.VIEW_APPLICATION_BASIC]
      },
      {
        name: 'Advanced Setting',
        path: ROUTE_NAMES.APP_ADVANCED_SETTING,
        Icon: Settings,
        permissions: [PERMISSIONS.VIEW_APPLICATION_ADVANCED]
      },
      {
        name: 'Webhooks',
        path: ROUTE_NAMES.APP_WEBHOOK_SETTING,
        Icon: GitFork,
        permissions: []
      },
      {
        name: 'Launchpad',
        path: ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
        Icon: GalleryHorizontalEnd,
        permissions: [PERMISSIONS.VIEW_APPLICATION_LAUNCHPAD]
      },
      {
        name: 'Group',
        path: ROUTE_NAMES.APP_GROUPS,
        Icon: Folders,
        subPath: [
          ROUTE_NAMES.APP_GROUP_CREATE,
          ROUTE_NAMES.APP_GROUP_DETAIL,
          ROUTE_NAMES.APP_GROUP_EDIT
        ],
        permissions: [PERMISSIONS.VIEW_APPLICATION_GROUP]
      },
      {
        name: 'Users',
        path: ROUTE_NAMES.APP_USERS,
        Icon: Users2,
        subPath: [
          ROUTE_NAMES.APP_USER_CREATE,
          ROUTE_NAMES.APP_USER_DETAIL,
          ROUTE_NAMES.APP_USER_ASSIGN_ROLES
        ],
        permissions: [PERMISSIONS.VIEW_APPLICATION_USER]
      },
      {
        name: 'Roles',
        path: ROUTE_NAMES.APP_ROLES,
        Icon: ListTree,
        subPath: [
          ROUTE_NAMES.APP_ROLE_CREATE,
          ROUTE_NAMES.APP_ROLE_DETAIL,
          ROUTE_NAMES.APP_ROLE_EDIT
        ],
        permissions: [PERMISSIONS.VIEW_APPLICATION_ROLE]
      },
      {
        name: 'Permissions',
        path: ROUTE_NAMES.APP_PERMISSIONS,
        Icon: Network,
        subPath: [
          ROUTE_NAMES.APP_PERMISSION_CREATE,
          ROUTE_NAMES.APP_PERMISSION_DETAIL,
          ROUTE_NAMES.APP_PERMISSION_EDIT
        ],
        permissions: [PERMISSIONS.VIEW_APPLICATION_PERMISSION]
      }
    ]
  }
]

export const GLOBAL_SETTING_SIDE_MENUS: MENU[] = [
  {
    title: '',
    menus: [
      {
        name: 'Countries',
        path: ROUTE_NAMES.COUNTRIES,
        Icon: Globe2,
        subPath: [ROUTE_NAMES.COUNTRY_CREATE, ROUTE_NAMES.COUNTRY_EDIT],
        permissions: [PERMISSIONS.VIEW_COUNTRY]
      }
    ]
  },
  {
    title: 'Security',
    menus: [
      {
        name: 'Password Policy',
        path: ROUTE_NAMES.PASSWORD_POLICY,
        Icon: Key,
        subPath: [],
        permissions: []
      },
      {
        name: 'Attack Protection',
        path: ROUTE_NAMES.ATTACK_PROTECTION,
        Icon: ShieldCheck,
        subPath: [],
        permissions: []
      }
    ]
  }
]
