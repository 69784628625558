import { FC, PropsWithChildren } from 'react'
import { MENU } from './constants'
import {
  aside,
  asideHeader,
  container,
  footer,
  main,
  menuContainer,
  menuLinksContainer,
  menuSubContainer,
  menuTitle,
  toggleButton
} from './styles.css'
import AppSwitcher from '$components/AppSwitcher'
import MenuLink from '$components/MenuLink'
import { useToggle } from 'ahooks'
import useResponsive from '$hooks/actions/useResponsive'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { ChevronsLeft, ChevronsRight } from 'lucide-react'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import AppAuditLog from '$blocks/AppAuditLog'
import { onOneOf } from '$router/config'
import { APPLICATION_ROUTES } from '$router/ApplicationRoutes/constants'

type Props = {
  menus: MENU[]
  showAppSwitcher?: boolean
}

const Header = () => {
  const isMounted = useIsMounted()

  return (
    <header className={asideHeader} data-ready={trueOrUndefined(isMounted)}>
      <AppSwitcher />
    </header>
  )
}

const LayoutWithSidebar: FC<PropsWithChildren<Props>> = ({
  children,
  showAppSwitcher,
  menus
}) => {
  const responsive = useResponsive()

  const isMounted = useIsMounted()

  const [collapse, { toggle }] = useToggle(!responsive.bigTablet)

  const Icon = collapse ? ChevronsRight : ChevronsLeft

  const isApplicationRoutes = onOneOf(APPLICATION_ROUTES.map(v => v.name))

  return (
    <article
      className={container}
      data-ready={trueOrUndefined(isMounted)}
      data-collapse={trueOrUndefined(collapse)}
    >
      <aside className={aside}>
        {showAppSwitcher && <Header />}

        <article className={menuContainer}>
          {menus.map(({ title, menus }) => (
            <article key={title} className={menuSubContainer}>
              {title && <h1 className={menuTitle}>{title}</h1>}

              <article className={menuLinksContainer}>
                {menus.map(menu => (
                  <MenuLink key={menu.name} menu={menu} />
                ))}
              </article>
            </article>
          ))}
        </article>

        {isApplicationRoutes && <AppAuditLog />}

        <footer className={footer}>
          <button onClick={toggle} className={toggleButton}>
            <Icon size={20} />
          </button>
        </footer>
      </aside>

      <main className={main} children={children} />
    </article>
  )
}

export default LayoutWithSidebar
