import { formatNumber } from '$app/utils'
import TodayCard from '$blocks/AppHome/components/TodayCard'
import { TAppHome } from '$services/api'
import { flexColumn, flexRow } from '$styles/common.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getDailyActiveUserOption } from './utils'
import { container } from './styles.css'
import { footnote, headline, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { status } from '@genie-fintech/ui/style/element'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

interface IProps {
  active_users: TAppHome['active_users']
  today_active_user_count: TAppHome['today_active_user_count']
}

const DailyActiveUserChart = ({
  today_active_user_count,
  active_users
}: IProps) => {
  const isMounted = useIsMounted()

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article
        style={{ display: 'flex', gap: 16, justifyContent: 'space-between' }}
      >
        <article className={flexColumn} style={{ gap: 8 }}>
          <p className={title.three} style={{ color: colors.text.light }}>
            APPLICATION DAILY ACTIVE USERS
          </p>
          <article className={flexRow} style={{ gap: 8 }}>
            <span className={headline.four}>
              {formatNumber(today_active_user_count)}
            </span>
            <span
              className={status({
                kind: 'primary',
                size: 'tiny',
                type: 'faint',
                material: 'dot'
              })}
            >
              TODAY
            </span>
          </article>
          <p
            className={footnote.one}
            style={{ color: colors.neutral[60], maxWidth: 269 }}
          >
            Analyze daily active users to track application engagement and usage
            trends.
          </p>
        </article>

        <TodayCard />
      </article>

      <article>
        <HighchartsReact
          highcharts={Highcharts}
          options={getDailyActiveUserOption(active_users)}
        />
      </article>
    </article>
  )
}

export default DailyActiveUserChart
