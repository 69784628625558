import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { useMount, useRequest } from 'ahooks'
import RoleListByApp from './RoleListByApp'
import { getServiceUserRolesPermissionsGroups } from '$services/api'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import React from 'react'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const Line = () => (
  <hr style={{ borderTop: `1px solid ${colors.neutral[10]}` }} />
)

const GlobalServiceUserRolesPermissionGroups = () => {
  const profile = useProfileDataStore(state => state.profile)
  const { id, name } = profile ?? {}

  const { run, loading, data } = useRequest(
    getServiceUserRolesPermissionsGroups,
    {
      manual: true
    }
  )

  useBreadcrumb([{ name: 'Roles, Permission, Groups' }])

  useMount(() => {
    if (!id) return
    run(id)
  })

  const { data: list = [] } = data || {}

  const isEmpty = !list.length && !loading

  return (
    <>
      <MainHeader
        title="Roles, Permission, Groups"
        desc="Manage and review user accounts for efficient system administration."
      />

      <article className={flexColumn} style={{ flex: 1 }}>
        <header className={mainContent} style={{ paddingBottom: 0 }}>
          <article className={innerMainContent} style={{ gap: 4 }}>
            <h2 className={title.two}>{name}'s Roles, Permission, Groups</h2>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              You can see the user’s related information based on each
              application level.
            </p>
          </article>
        </header>

        {loading && <Loading />}

        {isEmpty && <EmptyData type="data" />}

        {list.map((data, index) => (
          <React.Fragment key={index}>
            <RoleListByApp data={data} />
            <Line />
          </React.Fragment>
        ))}
      </article>
    </>
  )
}

export default GlobalServiceUserRolesPermissionGroups
