export const APP_API = import.meta.env.VITE_APP_API

export const APP_API_VERSION = import.meta.env.VITE_APP_API_VERSION

export const APP_CLIENT_ID = import.meta.env.VITE_APP_CLIENT_ID

export const APP_WEB_VIEW = import.meta.env.VITE_APP_WEB_VIEW

export const APP_AUTH = import.meta.env.VITE_APP_AUTH

export const APP_TITLE = import.meta.env.VITE_APP_TITLE

export const APP_LAUNCHPAD_URL = import.meta.env.VITE_APP_LAUNCHPAD_URL

export const CF_TURNSTILE_SITEKEY = import.meta.env.VITE_CF_TURNSTILE_SITEKEY

export const APP_PUSHER_KEY = import.meta.env.VITE_APP_PUSHER_KEY
export const APP_PUSHER_HOST = import.meta.env.VITE_APP_PUSHER_HOST
export const APP_PUSHER_AUTH = import.meta.env.VITE_APP_PUSHER_AUTH

export const APP_DOCS_URL = import.meta.env.VITE_APP_DOCS_URL
