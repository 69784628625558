import { useRoute } from '$contexts/RouteContext/hooks'
import { redirect, RedirectOptions, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'

type Props = {
  to: ROUTE_NAMES
  options?: RedirectOptions
}

const Navigate = ({ to, options }: Props) => {
  const currentRoute = useRoute()

  const redirectOptions = (() => {
    if (typeof options == 'function') {
      const { params, hash, queryParams } = currentRoute

      return options({
        hash,
        params,
        queryParams
      })
    }

    return options
  })()

  useMount(() => {
    redirect(to, redirectOptions)
  })

  return null
}

export default Navigate
