import { separator } from './styles.css'
import { flexRow } from '$styles/common.css'
import SidebarToggleMenu from '$components/SideBarToggleMenu'
import Link from '$router/Link'
import { ROUTE_NAMES } from '$router/config'
import ThemeToggleButton from '$components/ThemeToggleButton'
import Profile from '$components/Profile'
import { Logo } from '$assets/svg'
import { container } from './styles.css'
import Breadcrumb from '$components/Breadcrumb'

const NavbarContent = () => {
  return (
    <article className={container}>
      <article className={flexRow} style={{ gap: 16 }}>
        <SidebarToggleMenu />

        <Link to={ROUTE_NAMES.APPS}>
          <span style={{ cursor: 'pointer' }}>
            <Logo color="absolute.light" />
          </span>
        </Link>

        <span className={separator} />

        <Breadcrumb />
      </article>

      <article className={flexRow} style={{ gap: 8 }}>
        <ThemeToggleButton />

        <Profile />
      </article>
    </article>
  )
}

export default NavbarContent
