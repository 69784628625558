import { formatNumber } from '$app/utils'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ItemType } from './types'
import { INFO } from './constants'
import { useAppDetailStore } from '$hooks/stores'
import { Button } from '@genie-fintech/ui/components'
import { useCallback, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { redirect } from '$router/config'
import {
  container,
  icon,
  subLowerContainer,
  subUpperContainer
} from './styles.css'
import { footnote, headline, title } from '@genie-fintech/ui/style/typography'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'

const { colors } = themeVars

interface IProps {
  item: ItemType
  count: number
}

const AppSummaryCard = ({ item, count }: IProps) => {
  const { appId, appName } = useAppDetailStore(
    useShallow(state => {
      const { id: appId, name: appName } = state.appDetail ?? {}
      return { appId, appName }
    })
  )

  const isMounted = useIsMounted()

  const { Icon, desc, redirectPath } = INFO[item]

  const label = useMemo(
    () => ['TOTAL', `${appName}'s`, `${item}s`.toLocaleUpperCase()].join(' '),
    [appName, item]
  )

  const onClick = useCallback(() => {
    redirect(redirectPath, { params: { appId } })
  }, [redirectPath, appId])

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className={subUpperContainer}>
        <div className={icon}>
          <Icon size={24} />
        </div>

        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.three} style={{ color: colors.text.light }}>
            {label}
          </p>
          <p className={headline.four}>{formatNumber(count)}</p>
        </article>

        <p className={footnote.one} style={{ color: colors.neutral[60] }}>
          {desc}
        </p>
      </article>

      <article className={subLowerContainer}>
        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined' }}
          onClick={onClick}
        >
          View All
        </Button>
      </article>
    </article>
  )
}

export default AppSummaryCard
