import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { FC, useCallback, useMemo } from 'react'
import { postApp } from '$services/api'
import { useBoolean, useRequest } from 'ahooks'
import { toast } from 'sonner'
import { redirect, ROUTE_NAMES } from '$router/config'
import CreateAppForm from './CreateAppForm'
import { TField } from './constants'
import GeneratePublicKeyPrompt from './GeneratePublicKeyPrompt'
import { usePublicKeyService } from '$hooks/services/usePublicKeyService'
import { useModal } from '$components/Modal/hooks/useModal'
import { useAppDetailStore } from '$hooks/stores'
import { ModalV2 } from '$components/Modal-V2'
import {
  modalContainerWrapper,
  modalContentWrapper,
  modalNavButton,
  modalNavButtonWrapper,
  modalNavContainer
} from '$components/Modal-V2/index.css'
import { buttonContainer } from './styles.css'

enum STATE {
  CREATE_APP,
  GENERATE_PUBLIC_KEY
}

type Props = ReturnType<typeof useModal>

const CreateApplication: FC<Props> = () => {
  const {
    run: saveApp,
    loading: savingApp,
    data
  } = useRequest(postApp, {
    manual: true,
    onSuccess: () => {
      toast.success(`You've created app successfully!`)
    }
  })

  const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean()

  const { generatePublicKeyAsync, generatingPublicKey } = usePublicKeyService()

  const updatePublicKey = useAppDetailStore(state => state.updatePublicKey)

  const { id: appId, client_id } = { ...data?.data }

  const state: STATE = useMemo(() => {
    if (client_id) return STATE.GENERATE_PUBLIC_KEY
    return STATE.CREATE_APP
  }, [client_id])

  const onCreate = useCallback(
    ({ key, environment, ...payload }: TField) => {
      saveApp({
        ...payload,
        logo: { key },
        environment: +environment.value
      })
    },
    [saveApp]
  )

  const onRedirectAppBasicSetting = useCallback(() => {
    redirect(ROUTE_NAMES.APP_BASIC_SETTING, { params: { appId } })
  }, [appId])

  const onGenerate = useCallback(() => {
    if (!client_id) return
    generatePublicKeyAsync({ client_id, force: false }).then(({ data }) => {
      updatePublicKey(data.public_key)
      onRedirectAppBasicSetting()
      closeModal()
    })
  }, [
    client_id,
    generatePublicKeyAsync,
    updatePublicKey,
    onRedirectAppBasicSetting,
    closeModal
  ])

  return (
    <>
      <article className={buttonContainer}>
        <Button onClick={openModal} styleVariants={{ size: 'big' }}>
          <Icon namespace="Add" />
          <span className="whitespace-nowrap">Create Application</span>
        </Button>
      </article>

      <ModalV2
        open={open}
        contentClassName={modalContainerWrapper}
        contentStyle={{
          maxWidth: 672
        }}
      >
        {/* Nav Controls */}
        {state === STATE.CREATE_APP && (
          <div className={modalNavContainer}>
            <section className={modalNavButtonWrapper}>
              <button
                className={modalNavButton({ iconOnly: true })}
                onClick={closeModal}
                autoFocus={false}
              >
                <Icon namespace="Left" width="20" height="20" /> Back
              </button>
            </section>
          </div>
        )}
        {/* Body Contents */}
        <div className={modalContentWrapper}>
          {state === STATE.CREATE_APP && (
            <CreateAppForm
              onClose={closeModal}
              loading={savingApp}
              onSubmit={onCreate}
            />
          )}
          {state === STATE.GENERATE_PUBLIC_KEY && (
            <GeneratePublicKeyPrompt
              onGenerate={onGenerate}
              onNotNow={onRedirectAppBasicSetting}
              loading={generatingPublicKey}
            />
          )}
        </div>
      </ModalV2>
    </>
  )
}

export default CreateApplication
