import { APP_BRANDS } from '$app/constants'
import { cn, formatFileSize, TUploadFileData, uploadFile } from '$app/utils'
import AppLogo from '$components/AppLogo'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import Loading from '$components/Loading'
import { errorMessageResolver } from '$services/api'
import { APP_LOGO } from '$services/apps'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import React, {
  useState,
  useCallback,
  DragEvent,
  useMemo,
  useEffect
} from 'react'
import { toast } from 'sonner'

const { colors } = themeVars

interface IDragAndDropFileUploadProps {
  logo?: APP_LOGO
  brand?: string
  onUploadLogo?: (key: string) => void
  onChangeBrand?: (brand: string) => void
}

const ALLOWED_FILE_TYPE = [
  { name: 'JPG', type: 'image/jpeg' },
  { name: 'PNG', type: 'image/png' },
  { name: 'WEBP', type: 'image/webp' }
]

const DragAndDropFileUpload = ({
  logo,
  brand,
  onUploadLogo,
  onChangeBrand
}: IDragAndDropFileUploadProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [dragActive, setDragActive] = useState<boolean>(false)

  const [uploading, setUploading] = useState(false)

  const { url, key: fileKey } = logo ?? {}

  useEffect(() => {
    if (!fileKey && selectedFile) {
      setSelectedFile(null)
    }
  }, [fileKey, selectedFile])

  useEffect(() => {
    const fetchFile = async (s3Url: string) => {
      try {
        setUploading(true)
        // Fetch the file from S3 URL
        const response = await fetch(s3Url)
        const blob = await response.blob()

        // Convert blob to File
        const fileObject = new File([blob], 'app_logo', { type: blob.type })
        setSelectedFile(fileObject)
      } catch (error) {
        console.error(errorMessageResolver(error))
      } finally {
        setUploading(false)
      }
    }

    // Call the function with your S3 URL
    if (url) fetchFile(url)
  }, [url])

  const handleOnDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true)
      return
    }

    if (event.type === 'dragleave') {
      setDragActive(false)
      return
    }
  }, [])

  const handleOnChangeBrand = useCallback(
    (brandValue: string) => {
      onChangeBrand?.(brandValue)
    },
    [onChangeBrand]
  )

  const handleOnSelectFile = useCallback(
    (fileList: FileList | null) => {
      const [file] = fileList ?? []

      if (!file) return null

      if (!ALLOWED_FILE_TYPE.map(v => v.type).includes(file.type)) {
        toast.error('Oops! file type is not supported!')
        return null
      }

      setUploading(true)

      uploadFile(file)
        .then((value: TUploadFileData) => {
          onUploadLogo?.(value.key)
          setSelectedFile(file)
        })
        .catch(err => {
          toast.error(errorMessageResolver(err))
        })
        .finally(() => {
          setUploading(false)
        })
    },
    [onUploadLogo]
  )

  const handleOnDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      setDragActive(false)

      handleOnSelectFile(event.dataTransfer.files)
    },
    [handleOnSelectFile]
  )

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleOnSelectFile(event.currentTarget.files)
    },
    [handleOnSelectFile]
  )

  const handleOnConfirmDelete = useCallback(() => {
    setSelectedFile(null)
    onUploadLogo?.('')
  }, [onUploadLogo])

  const previewUrl = useMemo(() => {
    return selectedFile ? URL.createObjectURL(selectedFile) : undefined
  }, [selectedFile])

  return (
    <article className="flex flex-1 flex-col gap-y-2">
      <label
        className="text-sm font-medium"
        style={{ color: colors.neutral[60] }}
      >
        Which platform is for?
      </label>

      <article className="flex gap-2 items-center">
        {APP_BRANDS.map((v, k) => {
          const isActive = v.name === brand
          return (
            <button
              key={k}
              type="button"
              onClick={() => handleOnChangeBrand(v.name)}
              // TODO: refactor className with vanilla extract css
              className={cn(
                'inline-flex p-2 rounded-lg duration-200',
                isActive
                  ? 'border border-[--colors-primary-default] ring-1 ring-[--colors-primary-default]'
                  : 'border border-[--colors-neutral-20]'
              )}
            >
              <AppLogo brand={v.name} />
            </button>
          )
        })}
      </article>

      <label
        className="text-sm font-medium"
        style={{ color: colors.neutral[60] }}
      >
        Upload application logo or choose from above platform list
      </label>

      <article
        onDragEnter={handleOnDrag}
        onDragOver={handleOnDrag}
        onDragLeave={handleOnDrag}
        onDrop={handleOnDrop}
        className={cn(
          'flex flex-col items-center gap-y-4 border-2 py-10 rounded-lg bg-[--colors-alphaArea-disabled] relative',
          dragActive
            ? 'border-dashed border-[--colors-neutral-default]'
            : 'border-solid border-[--colors-neutral-20]'
        )}
      >
        {uploading && <Loading />}

        <AppLogo imgUrl={previewUrl} brand={brand} size="big" />

        <p
          className="text-sm font-medium"
          style={{ color: colors.text.disabled }}
        >
          Drop image here
        </p>

        <label
          htmlFor="hiddenFileInput"
          className="flex items-center gap-x-2 text-sm font-medium cursor-pointer"
          style={{ color: colors.primary[markedDefaultKey] }}
        >
          <span>Or upload file</span>

          <Icon namespace="Upload" width="18" />

          <input
            type="file"
            value=""
            id="hiddenFileInput"
            onChange={handleFileChange}
            className="hidden"
            accept={ALLOWED_FILE_TYPE.join(',')}
          />
        </label>
      </article>

      {previewUrl && (
        <article className="flex items-center gap-x-2 bg-[--colors-area-high] rounded-[16px] p-3 border border-[--colors-neutral-20]">
          <AppLogo imgUrl={previewUrl} brand={brand} />

          <div className="flex-1 flex flex-col gap-y-1 overflow-hidden">
            <p className="text-sm font-medium truncate">
              {ALLOWED_FILE_TYPE.find(d => d.type === selectedFile?.type)?.name}
            </p>
            <p className="text-sm text-[--colors-neutral-40]">
              {formatFileSize(selectedFile?.size ?? 0)}
            </p>
          </div>

          <DeleteWithConfirm
            confirmOnDelete={false}
            onConfirm={handleOnConfirmDelete}
          />
        </article>
      )}

      <p
        className="text-xs font-medium"
        style={{ color: colors.text.disabled }}
      >
        JPG, PNG or WEBP image
      </p>
    </article>
  )
}

export default DragAndDropFileUpload
