export const contentLimits = {
  sideGap: 15
}

// Must be in descending order
export const screenSizes = {
  // Normal sizes
  desktop: 1920,
  smallDesktop: 1480,
  bigTablet: 1200,
  mediumTablet: 950,
  tablet: 850,
  smallTablet: 750,
  bigMobile: 650,
  mediumMobile: 600,
  mobile: 550,
  smallMobile: 400,
  smallestMobile: 320
}

type SelectScreen = {
  name: keyof typeof screenSizes
  adjustment?: number
}

export const selectMaxScreen = ({ name, adjustment = 0 }: SelectScreen) => {
  return `only screen and (max-width: ${
    screenSizes[name] + adjustment
  }px)` as const
}

export const selectMinScreen = ({ name, adjustment = 0 }: SelectScreen) => {
  return `only screen and (min-width: ${
    screenSizes[name] + adjustment
  }px)` as const
}
