import { APP_USER_TYPE, TID } from '$services/api'
import { APP_LOGO } from '$services/apps'
import { create } from 'zustand'

export type Profile = {
  id: TID
  name: string
  email: string
  phone_code: string
  phone_no: string
  is_verified?: boolean
  status: 'active' | 'inactive'
  user_type: APP_USER_TYPE
  created_at: string
  last_login_at: string
  gender: string
  date_of_birth: string
  address: string
  password_updated_at: string
  location: string
  ip_address: string
  timezone: string
  applications?: {
    id: number
    name: string
    logo: APP_LOGO
    brand: string
  }[]
  type?: string
  role?: string
}

interface IProfileDataState {
  profile?: Profile
  updateProfile: (profile: IProfileDataState['profile']) => void
  updateProfilePartially: (data: Partial<IProfileDataState['profile']>) => void
}

export const useProfileDataStore = create<IProfileDataState>()(set => ({
  profile: undefined,
  updateProfile: profile => set({ profile }),
  updateProfilePartially: data =>
    set(state => ({
      profile: state.profile ? { ...state.profile, ...data } : undefined
    }))
}))
