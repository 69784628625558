import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { grid } from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button } from '@genie-fintech/ui/components'
import { Plus } from 'lucide-react'
import { useCallback } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useGlobalServiceUserService } from '$hooks/services'
import { useMount } from 'ahooks'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { flexColumn, flexRow } from '$styles/common.css'
import { pluralize } from '$app/utils'
import Card from './Card'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalServiceUserAccessApps = () => {
  const userId = useProfileDataStore(state => state.profile?.id)

  const { fetchAccessApps, fetchingAccessApps, accessApps } =
    useGlobalServiceUserService()

  useMount(() => {
    if (!userId) return
    fetchAccessApps({ userId })
  })

  useBreadcrumb([{ name: 'Access Applications' }])

  const onClickAppCard = useCallback((appId: number) => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [])

  const onAddApplication = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACH_APP, {
      params: { serviceUserId: userId }
    })
  }, [userId])

  if (fetchingAccessApps) return <Loading />

  const isEmpty = !accessApps.length && !fetchingAccessApps

  return (
    <>
      <MainHeader
        title={`Access ${pluralize(accessApps.length, 'application')} to use`}
        desc="This user can access these applications by related assigned roles and groups."
      />

      <article className={mainContent}>
        <article className={innerMainContent} style={{ gap: 24 }}>
          <article
            className={flexRow}
            style={{ gap: 8, justifyContent: 'space-between' }}
          >
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>APPLICATIONS</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                You can add more application to this user.
              </p>
            </article>

            <Button styleVariants={{ size: 'big' }} onClick={onAddApplication}>
              <Plus size={16} />
              Add Application
            </Button>
          </article>

          {isEmpty && (
            <EmptyData type="applications" onClick={onAddApplication} />
          )}

          <article className={grid}>
            {accessApps.map(v => {
              return <Card key={v.id} app={v} onClick={onClickAppCard} />
            })}
          </article>
        </article>
      </article>
    </>
  )
}

export default GlobalServiceUserAccessApps
