import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { useCountryService } from '$hooks/services'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import { getFlagUrl } from '$app/utils'
import FooterAction from '$components/FooterAction'
import { ROUTE_NAMES } from '$router/config'
import { countries, TCountryCode } from 'countries-list'
import Select from '$components/Select'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import Asterisk from '$components/Asterisk'

interface ICountryFormProps {
  defaultValues: TFormValues
}

const CountryForm = ({ defaultValues }: ICountryFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { countryId } = params

  const proxyRedirect = useRedirectProxy()

  const { timezones } = useAuthContext()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue
  } = methods

  useRedirectPrompt({ shouldPrompt: isDirty })

  const country_code = watch('country_code').value as TCountryCode

  useEffect(() => {
    if (!country_code) return
    const { name = '', currency, phone } = countries[country_code] ?? {}
    const [phone_code = ''] = phone ?? []
    const [currencyCode = ''] = currency ?? []
    setValue('name', name)
    setValue('phone_code', `+${phone_code}`)
    setValue('currency_code', currencyCode)
  }, [country_code, setValue])

  const { createCountry, creatingCountry, updateCountry, updatingCountry } =
    useCountryService()

  const title = (() => {
    if (countryId) {
      return (
        <article className="inline-flex items-center gap-2">
          <article className="w-7 h-7 rounded-full overflow-hidden border border-[--colors-neutral-10]">
            <img
              src={getFlagUrl(defaultValues.country_code.value)}
              className="rounded-full w-full h-full object-cover"
            />
          </article>
          <span>{defaultValues.name}</span>
        </article>
      )
    }
    return `Add New Country`
  })()

  const handleOnCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.COUNTRIES, { params })
  }, [proxyRedirect, params])

  const onSubmit = handleSubmit(
    ({ timezone, country_code, ...values }: TFormValues) => {
      const payload = {
        ...values,
        country_code: country_code.value,
        timezone: timezone.value
      }

      if (countryId) {
        updateCountry(countryId, payload)
        return
      }

      createCountry(payload)
    }
  )

  const isProcessing = creatingCountry || updatingCountry

  return (
    <form
      className="flex flex-col bg-[--colors-area-high] rounded-lg border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]"
      onSubmit={onSubmit}
    >
      <header className="flex items-center gap-2 justify-between px-5 py-2 border-b border-[--colors-neutral-10]">
        <article className="text-xl font-semibold text-[--colors-text-light]">
          {title}
        </article>
      </header>

      <main className="grid lg:grid-cols-[40%_60%] p-5 gap-2">
        <article className="flex flex-col gap-1">
          <p className="font-semibold text-[--colors-text-neutral]">
            COUNTRY INFO
          </p>
          <p className="text-[--colors-neutral-50] text-xs">
            View detailed information about each country to manage regional
            settings and preferences.
          </p>
        </article>

        <article className="flex flex-col gap-4 px-5 max-w-[500px]">
          <Controller
            name="country_code"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <article className="flex flex-col gap-y-1">
                <label className="text-sm font-medium">
                  Country
                  <Asterisk />
                </label>
                <Select
                  value={field.value}
                  onChange={field.onChange}
                  options={Object.entries(countries).map(([key]) => ({
                    label: `${countries[key as TCountryCode].name} (${key})`,
                    value: key
                  }))}
                  error={!!error?.message}
                  disabled={!!countryId}
                />

                {error?.message && (
                  <p className="text-xs text-[--colors-danger-default]">
                    {error.message}
                  </p>
                )}
              </article>
            )}
          />

          {!!countryId && (
            <BaseHookFieldText
              control={control}
              name="name"
              label="Country Name"
              disabled
            />
          )}

          <BaseHookFieldText
            control={control}
            name="phone_code"
            label="Phone Code"
            disabled
          />

          <BaseHookFieldText
            control={control}
            name="currency_code"
            label="Currency Code"
            disabled
          />

          <BaseHookFieldText
            control={control}
            name="currency_name"
            label="Currency Name"
            required
          />

          <BaseHookFieldText
            control={control}
            name="currency_symbol"
            label="Currency Symbol"
            required
          />

          <Controller
            name="timezone"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <article className="flex flex-col gap-y-1">
                <label className="text-sm font-medium">
                  Timezone
                  <Asterisk />
                </label>
                <Select
                  {...field}
                  options={timezones.map(({ key }) => ({
                    label: key,
                    value: key
                  }))}
                  error={!!error?.message}
                />

                {error?.message && (
                  <p className="text-xs text-[--colors-danger-default]">
                    {error.message}
                  </p>
                )}
              </article>
            )}
          />
        </article>
      </main>

      <FooterAction fullWidth={false}>
        {countryId && (
          <SaveWithShortCutButton
            disabled={!isDirty || isProcessing}
            loading={isProcessing}
          />
        )}

        {!countryId && (
          <article className="flex w-full items-center gap-2 justify-end max-w-[1292px] mx-auto">
            <Button
              disabled={isProcessing}
              styleVariants={{ type: 'outlined', kind: 'neutral' }}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={!isDirty || isProcessing}
              type="submit"
              className="!px-4"
            >
              {isProcessing && <Spinner />}
              Add
            </Button>
          </article>
        )}
      </FooterAction>
    </form>
  )
}

export default CountryForm
