import { ROUTE_NAMES } from '$router/config'
import {
  Laptop2,
  Layers,
  Lock,
  Shield,
  ShieldCheck,
  UserRoundCheck,
  Users2
} from 'lucide-react'
import { MENU_GROUP } from './types'

export const APP_USER_MENUS: MENU_GROUP = [
  {
    title: '',
    menus: [
      {
        Icon: Layers,
        label: 'Access Applications',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_ACCESS_APPS
      },
      {
        Icon: Users2,
        label: 'Profile',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL
      },
      {
        Icon: UserRoundCheck,
        label: 'Roles, Permission, Groups',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_ROLES_PERMISSION_GROUPS
      }
    ]
  },
  {
    title: 'Security',
    menus: [
      {
        Icon: Laptop2,
        label: 'Devices Login',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_DEVICES_LOGIN
      },
      {
        Icon: Lock,
        label: 'Password Setting',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING
      },
      {
        Icon: Shield,
        label: 'Multi-Factor Authentication',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_MFA
      },
      {
        Icon: ShieldCheck,
        label: 'Attack Protection',
        path: ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACK_PROTECTION
      }
    ]
  }
]

export const DASHBOARD_USER_MENUS: MENU_GROUP = [
  {
    title: '',
    menus: [
      {
        Icon: Layers,
        label: 'Access Applications',
        path: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ACCESS_APPS
      },
      {
        Icon: Users2,
        label: 'Profile',
        path: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL
      }
    ]
  },
  {
    title: 'Security',
    menus: [
      {
        Icon: Lock,
        label: 'Password Setting',
        path: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_PASSWORD_SETTING
      }
    ]
  }
]
