import { useCallback, useEffect, useRef } from 'react'
import { useRouterContext } from '$contexts/RouteContext/hooks'
import { useModal } from '$components/Modal/hooks/useModal'
import { description, flexColumn, footer, main } from './styles.css'
import { Button } from '@genie-fintech/ui/components'
import { warningIconBox } from '$styles/common.css'
import { AlertTriangle } from 'lucide-react'
import { title } from '@genie-fintech/ui/style/typography'
import { ModalV2 } from '$components/Modal-V2'
import {
  modalContainerWrapper,
  modalContentWrapper
} from '$components/Modal-V2/index.css'

const RedirectPrompt = () => {
  const confirmButtonRef = useRef<HTMLButtonElement | null>(null)
  const modalActions = useModal()

  const { openModal, closeModal } = modalActions

  const action = useRef<VoidFunction>()

  const {
    registerBeforeCallback,
    shouldBlock,
    updateShouldPrompt: updateShouldBlock
  } = useRouterContext()

  useEffect(
    () => {
      const cleanUp = registerBeforeCallback(redirect => {
        action.current = redirect
        if (shouldBlock.current) {
          openModal()
        }
        return !shouldBlock.current
      })

      return cleanUp
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openModal]
  )

  useEffect(() => {
    if (modalActions?.open) {
      setTimeout(() => {
        if (confirmButtonRef.current) confirmButtonRef.current.focus()
      }, 50)
    }
  }, [modalActions?.open])

  const onCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  const onConfirm = useCallback(() => {
    action.current?.()
    updateShouldBlock(false)
    onCancel()
  }, [onCancel, updateShouldBlock])

  return (
    <ModalV2
      {...modalActions}
      contentClassName={modalContainerWrapper}
      contentStyle={{ maxWidth: 670 }}
      isCenter
    >
      <div className={modalContentWrapper}>
        <main className={main}>
          <span className={warningIconBox}>
            <AlertTriangle size={20} />
          </span>

          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.four}>You are about to leave this page!</p>
            <p className={description}>
              Are you sure? This is an irreversible action. All changes will be
              erased and cannot be retrieved.
            </p>
          </article>
        </main>
        <footer className={footer}>
          <Button
            styleVariants={{
              size: 'small',
              kind: 'neutral',
              type: 'outlined'
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            styleVariants={{ size: 'small' }}
            onClick={onConfirm}
            buttonRef={confirmButtonRef}
          >
            Confirm
          </Button>
        </footer>
      </div>
    </ModalV2>
  )
}

export default RedirectPrompt
