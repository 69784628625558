import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useToggle } from 'ahooks'
import { PenLine } from 'lucide-react'
import { grid } from './styles.css'
import { getDateOfBirth } from '$app/utils'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import { GENDER_OPTIONS } from '$app/constants'
import { INITIAL_DEFAULT_VALUES, LABEL, schema, TFormValues } from './constants'
import { outlineNeutralButtonVariant } from '$app/constants'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import {
  useGlobalServiceUserService,
  usePasswordSettingValues
} from '$hooks/services'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'
import LoginUserType from '../../../components/LoginUserType'
import { APP_USER_TYPE } from '$services/api'
import LoginUserTypeDialog from './LoginUserTypeDialog'
import TimezoneSetting from './TimezoneSetting'
import { TTimezone } from '$contexts/AuthContext/types'
import ChangeEmailSetting from './ChangeEmailSetting'
import ChangePhoneSetting from './ChangePhoneSetting'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { flexColumn, flexRow } from '$styles/common.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalServiceUserDetail = () => {
  const [isEdit, { toggle: toggleEdit }] = useToggle()

  const [isModalOpen, { toggle: toggleModal }] = useToggle()

  const {
    updateServiceUserAsync,
    updatingServiceUser,
    updateServiceUserTypeAsync,
    updatingServiceUserType,
    updateServiceUserTimezoneAsync,
    updatingServiceUserTimezone
  } = useGlobalServiceUserService()

  const {
    fetchCurrentPasswordSettingValuesAsync,
    fetchingCurrentPasswordSettingValues,
    currentPasswordSettingValues,
    fetchDefaultPasswordSettingValuesAsync,
    fetchingDefaultPasswordSettingValues,
    defaultPasswordSettingValues
  } = usePasswordSettingValues()

  const profile = useProfileDataStore(useShallow(state => state.profile))

  const updateProfilePartially = useProfileDataStore(
    state => state.updateProfilePartially
  )

  const {
    id,
    name,
    date_of_birth,
    gender,
    address,
    user_type: currentAppUserType
  } = profile ?? {}

  useBreadcrumb([{ name: 'Profile' }])

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty }
  } = methods

  const _gender = useMemo(
    () =>
      GENDER_OPTIONS.find(d => d.value === gender) ?? {
        label: '',
        value: ''
      },
    [gender]
  )

  const onEdit = useCallback(() => {
    reset({
      name: name ?? '',
      date_of_birth: date_of_birth ?? '',
      gender: _gender,
      address: address ?? ''
    })

    toggleEdit()
  }, [reset, name, date_of_birth, _gender, address, toggleEdit])

  const onCancel = useCallback(() => {
    toggleEdit()
    reset(INITIAL_DEFAULT_VALUES)
  }, [toggleEdit, reset])

  const onSave = useCallback(
    async (values: TFormValues) => {
      if (!id) return

      const { gender, ...rest } = values

      const payload = {
        gender: gender.value,
        ...rest
      }

      await updateServiceUserAsync(id, payload)
        .then(() => {
          toast.success(`Successfully Updated!`)
          updateProfilePartially(payload)
        })
        .then(toggleEdit)
    },
    [id, updateServiceUserAsync, toggleEdit, updateProfilePartially]
  )

  const onContinue = useCallback(
    async (
      newLoginUserType: APP_USER_TYPE,
      modify_login_rules: boolean,
      callbackFn?: VoidFunction
    ) => {
      if (!id) return

      const is_internal = newLoginUserType === 'internal'
      await updateServiceUserTypeAsync(id, {
        is_internal,
        modify_login_rules
      }).then(() => {
        toast.success('Login user type updated!')
        updateProfilePartially({ user_type: newLoginUserType })
        callbackFn?.()
      })
    },
    [id, updateServiceUserTypeAsync, updateProfilePartially]
  )

  const onChangeLoginUserType = useCallback(async () => {
    if (!id) return
    toggleModal()
    fetchCurrentPasswordSettingValuesAsync({ userId: id })
    fetchDefaultPasswordSettingValuesAsync()
  }, [
    id,
    fetchCurrentPasswordSettingValuesAsync,
    fetchDefaultPasswordSettingValuesAsync,
    toggleModal
  ])

  const onUpdateTimezone = useCallback(
    async (timezone: TTimezone[number], callBackFn: VoidFunction) => {
      if (!id) return
      await updateServiceUserTimezoneAsync(id, timezone.key).then(() => {
        toast.success('Timezone updated!')
        updateProfilePartially({ timezone: timezone.key })
        callBackFn()
      })
    },
    [id, updateServiceUserTimezoneAsync, updateProfilePartially]
  )

  return (
    <>
      <MainHeader
        title="User Profile"
        desc="Manage and review user accounts for efficient system administration."
      />

      <article
        className={mainContent}
        style={{ borderBottom: `1px solid ${colors.neutral[10]}` }}
      >
        <form
          className={innerMainContent}
          style={{ gap: 24 }}
          onSubmit={handleSubmit(onSave)}
        >
          <article
            className={flexRow}
            style={{ gap: 8, justifyContent: 'space-between' }}
          >
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>INFORMATIONS</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                Some of the user informations can be editable.
              </p>
            </article>

            <article className={flexRow} style={{ gap: 12 }}>
              {isEdit && (
                <>
                  <Button
                    styleVariants={outlineNeutralButtonVariant}
                    onClick={onCancel}
                    disabled={updatingServiceUser}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    styleVariants={{ size: 'small' }}
                    disabled={updatingServiceUser || !isDirty}
                  >
                    {updatingServiceUser && <Spinner />}
                    Save Changes
                  </Button>
                </>
              )}

              {!isEdit && (
                <Button
                  styleVariants={outlineNeutralButtonVariant}
                  onClick={onEdit}
                >
                  <PenLine size={16} />
                  Edit
                </Button>
              )}
            </article>
          </article>

          <article className={grid}>
            {isEdit && (
              <>
                <article>
                  <BaseHookFieldText
                    name="name"
                    control={control}
                    label={LABEL.USERNAME}
                    required
                  />
                </article>

                <article>
                  <BaseHookFieldText
                    control={control}
                    name="date_of_birth"
                    label={LABEL.DOB}
                    inputProps={{ type: 'date' }}
                  />
                </article>

                <Controller
                  name="gender"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <article className="flex flex-col gap-y-2">
                        <label className="text-sm font-medium">Gender</label>
                        <Select
                          {...field}
                          options={GENDER_OPTIONS}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <p className="text-xs text-[--colors-danger-default]">
                            {error.message}
                          </p>
                        )}
                      </article>
                    )
                  }}
                />

                <article>
                  <Textarea
                    name="address"
                    control={control}
                    label={LABEL.ADDRESS}
                  />
                </article>
              </>
            )}

            {!isEdit && (
              <>
                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    User Name
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {name}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Date of Birth
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {getDateOfBirth(date_of_birth)}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Gender
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {_gender.label || '-'}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Address
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {address || '-'}
                  </span>
                </article>
              </>
            )}
          </article>
        </form>
      </article>

      {currentAppUserType && (
        <LoginUserType
          value={currentAppUserType}
          onChange={onChangeLoginUserType}
        />
      )}

      <TimezoneSetting
        timezone={profile?.timezone ?? ''}
        loading={updatingServiceUserTimezone}
        onUpdateTimezone={onUpdateTimezone}
      />

      <ChangePhoneSetting />

      <ChangeEmailSetting />

      {currentAppUserType && (
        <LoginUserTypeDialog
          modalOpen={isModalOpen}
          currentLoginUserType={currentAppUserType}
          onCancel={toggleModal}
          onContinue={onContinue}
          fetching={
            fetchingCurrentPasswordSettingValues ||
            fetchingDefaultPasswordSettingValues
          }
          updating={updatingServiceUserType}
          currentPasswordSettingValues={currentPasswordSettingValues}
          defaultPasswordSettingValues={defaultPasswordSettingValues}
        />
      )}
    </>
  )
}

export default GlobalServiceUserDetail
