import { useWebhookEventStore } from '$hooks/stores/useWebhookEventStore'
import { Checkbox, Radio } from '@genie-fintech/ui/components'
import {
  container,
  eventCategoryTitle,
  eventListContainer,
  subContainer
} from './styles.css'
import { useCallback } from 'react'
import { CheckedState } from '@radix-ui/react-checkbox'

type MultiValue = {
  multiple: true
  value: string[]
  onChange: (events: string[]) => void
}

type SingleValue = {
  multiple?: false
  value: string
  onChange: (event: string) => void
}

type Props = MultiValue | SingleValue

const WebhookEventList = ({ value, onChange, multiple }: Props) => {
  const webhookEventsCategories = useWebhookEventStore(
    state => state.webhookEventsByCategory
  )

  const onCheckedChange = useCallback(
    (checked: CheckedState, id: string) => {
      if (!multiple) return
      const newValue = checked ? [...value, id] : value.filter(v => v !== id)
      onChange(newValue)
    },
    [multiple, value, onChange]
  )

  return (
    <article className={container}>
      {webhookEventsCategories.map(category => (
        <article key={category.id} className={subContainer}>
          <h2 className={eventCategoryTitle}>
            {category.name.split('_').join(' ')}
          </h2>

          {multiple && (
            <article className={eventListContainer}>
              {category.webhook_events.map(v => (
                <Checkbox
                  key={v.id}
                  label={v.name}
                  boxProps={{
                    checked: value.includes(v.id),
                    onCheckedChange: checked => onCheckedChange(checked, v.id)
                  }}
                />
              ))}
            </article>
          )}

          {!multiple && (
            <Radio.Group
              asChild
              value={value}
              className={eventListContainer}
              onValueChange={onChange}
            >
              <main>
                {category.webhook_events.map(v => (
                  <Radio.Item key={v.id} label={v.name} value={v.id} />
                ))}
              </main>
            </Radio.Group>
          )}
        </article>
      ))}
    </article>
  )
}

export default WebhookEventList
