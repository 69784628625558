import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { container, grid } from './styles.css'
import { Radio } from '@genie-fintech/ui/components'
import { APP_USER_TYPE, SERVICE_USER_TYPE } from '$services/api'
import { FC, PropsWithChildren, useCallback } from 'react'
import { innerMainContent } from '$layouts/UserProfileLayout/styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

interface IProps {
  value: APP_USER_TYPE
  onChange?: (value: SERVICE_USER_TYPE) => void
}

const LoginUserType: FC<PropsWithChildren<IProps>> = ({ value, onChange }) => {
  const isMounted = useIsMounted()

  const onValueChange = useCallback(
    (value: string) => {
      onChange?.(value as SERVICE_USER_TYPE)
    },
    [onChange]
  )

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <article className={innerMainContent} style={{ gap: 24 }}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.two}>LOGIN USER TYPE</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            The login behavior may vary depending on the selected login user
            type.
          </p>
        </article>

        {onChange && (
          <Radio.Group
            asChild
            className={grid}
            value={value}
            onValueChange={onValueChange}
          >
            <main>
              <Radio.Item
                label="Internal login user"
                value={SERVICE_USER_TYPE.INTERNAL}
                styleVariants={{
                  bordered: true,
                  padded: true,
                  fullWidth: true
                }}
              />
              <Radio.Item
                label="External login user"
                value={SERVICE_USER_TYPE.EXTERNAL}
                styleVariants={{
                  bordered: true,
                  padded: true,
                  fullWidth: true
                }}
              />
            </main>
          </Radio.Group>
        )}
      </article>
    </article>
  )
}

export default LoginUserType
