import { useWebhookEventStore } from '$hooks/stores/useWebhookEventStore'
import { useCallback, useMemo } from 'react'

export const useWebhookEvents = () => {
  const webhookEventsByCategory = useWebhookEventStore(
    state => state.webhookEventsByCategory
  )

  const webhookEvents = useMemo(
    () => webhookEventsByCategory.map(v => v.webhook_events).flat(),
    [webhookEventsByCategory]
  )

  const getWebhookEventById = useCallback(
    (id: string) => webhookEvents.find(v => v.id === id),
    [webhookEvents]
  )

  return {
    webhookEvents,
    getWebhookEventById
  }
}
