import { useApiListingParams, usePermissions } from '$hooks/actions'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useEffect, useMemo, useState } from 'react'
import { useModal } from '$components/Modal/hooks/useModal'
import {
  container,
  desc,
  grid,
  subHeader,
  headingWrap,
  title,
  searchFilterWrap
} from './styles.css'
import { flexColumn, ready } from '$styles/common.css'
import CreateApplication from '$blocks/CreateApplication'
import SearchBox from '$components/SearchBox'
import Loading from '$components/Loading'
import ScrollPagination from '$components/ScrollPagination'
import AppCard from '$components/AppCard'
import { PERMISSIONS } from '$app/constants'
import Empty from '$components/Empty'
import { useRequest } from 'ahooks'
import { APPS_RESPONSE, getApplications } from '$services/apps'
import useResponsive from '$hooks/actions/useResponsive'
import usePageClass from '$components/Modal/hooks/usePageClass'
import ScrollTopButton from '$components/ScrollTopButton'

const AppList = () => {
  const responsive = useResponsive()

  const [apps, setApps] = useState<APPS_RESPONSE['data']>([])

  const isMounted = useIsMounted()

  const modalActions = useModal({
    closeOnClickOutside: false,
    isFocusMode: true
  })

  const { checkPermissions } = usePermissions()
  const CAN_CREATE_APP = checkPermissions([PERMISSIONS.CREATE_APPLICATION])

  const { runAsync, loading, data } = useRequest(getApplications, {
    manual: true
  })

  const { meta } = data ?? {}

  const perPage: number = useMemo(() => {
    if (responsive.smallDesktop) return 25
    return 24
  }, [responsive.smallDesktop])

  const { searchValue, updateSearchValue, debouncedSearchValue, pagerProps } =
    useApiListingParams({ total: meta?.total, perPage })

  const { currentPage, jump } = pagerProps

  usePageClass({ prefix: 'background-low', name: 'app-list-page' })

  useEffect(() => {
    runAsync({
      page: currentPage,
      per_page: perPage,
      q: debouncedSearchValue
    }).then(({ data }) =>
      setApps(prev => (currentPage === 1 ? data : [...prev, ...data]))
    )
  }, [runAsync, currentPage, debouncedSearchValue, perPage])

  const initialLoading = !apps.length && loading

  const isEmpty = !apps.length && !loading

  const totalApps = meta?.total

  return (
    <section className={container} data-ready={trueOrUndefined(isMounted)}>
      <header className={flexColumn} style={{ gap: 20 }}>
        <div>
          <div className={headingWrap}>
            <h2 className={title}>Applications</h2>
            <span className={ready} data-ready={trueOrUndefined(totalApps)}>
              -
            </span>
            <span className={ready} data-ready={trueOrUndefined(totalApps)}>
              {totalApps}
            </span>
          </div>
          <p className={desc}>
            Setup a mobile, web application to use Carro SSO for Authentication.
          </p>
        </div>
        <div className={subHeader}>
          <div className={searchFilterWrap}>
            <SearchBox
              value={searchValue}
              onChange={updateSearchValue}
              placeholder="Search here..."
            />
          </div>
          {CAN_CREATE_APP && <CreateApplication {...modalActions} />}
        </div>
      </header>

      <main className={flexColumn} style={{ flex: 1 }}>
        {initialLoading && <Loading />}

        {isEmpty && (
          <Empty
            title="You don't have any application yet."
            description="No Items have been added to this section"
            buttonProps={{
              label: 'Create Application',
              onClick: modalActions.openModal
            }}
          />
        )}

        <article className={grid}>
          {apps.map(appData => (
            <AppCard key={appData.id} data={appData} />
          ))}
        </article>

        <ScrollPagination loading={loading} meta={meta} onTrigger={jump} />
      </main>

      {!responsive.mediumTablet && <ScrollTopButton />}
    </section>
  )
}

export default AppList
