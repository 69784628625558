import { getAppSelectList } from '$services/api'
import { useRequest } from 'ahooks'

export const useAppListOptionService = () => {
  const {
    run: fetchAppSelectList,
    runAsync: fetchAppSelectListAsync,
    data: AppSelectListData,
    loading: fetchingAppSelectList
  } = useRequest(getAppSelectList, {
    manual: true
  })

  const { data: app_option_list = [] } = { ...AppSelectListData }

  return {
    app_option_list,
    fetchAppSelectList,
    fetchAppSelectListAsync,
    fetchingAppSelectList
  }
}
