import { FC } from 'react'
import { on, ROUTE_NAMES } from './config'
import { useRoute } from '$app/contexts/RouteContext/hooks'
import Login from '$pages/Login'
import PageLayout from '$layouts/PageLayout'
import GlobalAppUser from '$pages/GlobalAppUser/List'
import GlobalAppUserDetail from '$pages/GlobalAppUser/Detail'
import GlobalDashboardUser from '$pages/GlobalDashboardUser/List'
import GlobalDashboardUserCreate from '$pages/GlobalDashboardUser/Create'
import UpdatePassword from '$pages/UpdatePassword'
import GlobalServiceUserAddApplication from '$pages/GlobalServiceUser/AddApplication'
import GlobalDashboardAddApplication from '$pages/GlobalDashboardUser/AddApplication'
import GlobalServiceUserIndexPage from '$pages/GlobalServiceUser/IndexPage'
import GlobalDashboardUserIndexPage from '$pages/GlobalDashboardUser/IndexPage'
import ApplicationUserRoutes from './ApplicationUserRoutes'
import DashboardUserRoutes from './DashboardUserRoutes'
import ApplicationRoutes from './ApplicationRoutes'
import GlobalSettingRoutes from './GlobalSettingRoutes'

export const Router: FC = () => {
  const { is404 } = useRoute()

  if (is404) return '404'

  if (on(ROUTE_NAMES.LOGIN)) {
    return <Login />
  }

  return (
    <PageLayout>
      <ApplicationRoutes />

      {on(ROUTE_NAMES.UPDATE_PASSWORD) && <UpdatePassword />}

      {on(ROUTE_NAMES.GLOBAL_APP_USERS) && <GlobalAppUser />}

      {on(ROUTE_NAMES.GLOBAL_APP_USER_DETAIL) && <GlobalAppUserDetail />}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USERS) && <GlobalDashboardUser />}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE) && (
        <GlobalDashboardUserCreate />
      )}

      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP) && (
        <GlobalDashboardAddApplication />
      )}

      {/* SERVICE USERS ROUTES  */}
      {on(ROUTE_NAMES.GLOBAL_SERVICE_USER_INDEX) && (
        <GlobalServiceUserIndexPage />
      )}

      {on(ROUTE_NAMES.GLOBAL_SERVICE_USER_ATTACH_APP) && (
        <GlobalServiceUserAddApplication />
      )}

      <ApplicationUserRoutes />

      {/* DASHBOARD USERS ROUTES  */}
      {on(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_INDEX) && (
        <GlobalDashboardUserIndexPage />
      )}

      <DashboardUserRoutes />

      <GlobalSettingRoutes />
      {/* MORE OTHER ROUTES  */}
    </PageLayout>
  )
}
