import Table, { Column } from '$components/Table'
import ActionLink from '$components/Table/ActionLink'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { ROUTE_NAMES } from '$router/config'
import { getAppUserListByRoleId } from '$services/api'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useRequest } from 'ahooks'
import { FC, useEffect, useState } from 'react'
import { container, innerContainer } from '../styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { flexColumn, flexRow } from '$styles/common.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import SearchBox from '$components/SearchBox'
import { pluralize } from '$app/utils'
import Pagination from '$components/Pagination'
import { viewButton } from '$components/Table/styles.css'
import StatusBadge from '$components/StatusBadge'
import PreviewRoleDrawer from '$blocks/PreviewRoleDrawer'

const { colors } = themeVars

type User = Awaited<ReturnType<typeof getAppUserListByRoleId>>['data'][number]

type Props = {
  roleName: string
}
const UserTable: FC<Props> = ({ roleName }) => {
  const isMounted = useIsMounted()

  const { route } = useRouteSummary()
  const { appId, roleId } = route.params

  const [selectedUser, setSelectedUser] = useState<User>()

  const { run, loading, data } = useRequest(getAppUserListByRoleId, {
    manual: true
  })

  const { data: users = [], meta } = data ?? {}

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !roleId) return

    run({
      application_id: appId,
      roleId,
      q: debouncedSearchValue,
      page,
      per_page: perPage
    })
  }, [appId, roleId, run, debouncedSearchValue, page, perPage])

  const userColumns: Column<User>[] = [
    {
      name: 'No',
      render: ({ index }) => index + 1
    },
    {
      name: 'Username',
      dataIndex: 'name'
    },
    {
      name: 'Email',
      dataIndex: 'email'
    },
    {
      name: 'Role',
      render: ({ record }) => (
        <button
          className={viewButton}
          onClick={() => {
            setSelectedUser(record)
          }}
        >
          View
        </button>
      )
    },
    {
      name: 'Status',
      render: ({ record }) => (
        <StatusBadge
          styleVariants={{
            type: 'faint',
            size: 'tiny',
            kind: record.status === 'active' ? 'success' : 'danger'
          }}
        >
          {record.status.toLocaleUpperCase()}
        </StatusBadge>
      )
    },
    {
      name: '',
      dataIndex: 'id',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_USER_DETAIL}
          options={{
            params: { ...route.params, userId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <>
      <article className={container}>
        <article
          className={innerContainer}
          data-ready={trueOrUndefined(isMounted)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two} style={{ textTransform: 'uppercase' }}>
              {`${roleName}'s users`}
            </p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Manage and review user accounts for efficient system
              administration.
            </p>
          </article>

          <article className={flexColumn} style={{ gap: 8 }}>
            <article className={flexRow} style={{ gap: 4 }}>
              <article style={{ flex: 1, maxWidth: 560 }}>
                <SearchBox
                  value={searchValue}
                  onChange={updateSearchValue}
                  placeholder="Search here..."
                />
              </article>

              <span style={{ flex: 1 }} />

              {!!meta?.total && (
                <span
                  className={title.six}
                  style={{
                    color: colors.text.disabled,
                    textTransform: 'uppercase'
                  }}
                >
                  {pluralize(meta.total, 'user')}
                </span>
              )}
            </article>

            <Table data={users} columns={userColumns} loading={loading} />

            {!!meta?.total && (
              <Pagination meta={meta} pagerProps={pagerProps} />
            )}
          </article>
        </article>
      </article>

      <PreviewRoleDrawer
        user={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />
    </>
  )
}

export default UserTable
