import AttackProtection from '$pages/AttackProtection'
import CountryCreate from '$pages/Country/Create'
import CountryEdit from '$pages/Country/Edit'
import CountryList from '$pages/Country/List'
import PasswordPolicy from '$pages/PasswordPolicy'
import { ROUTE_NAMES } from '$router/config'
import Navigate from '$router/Navigate'

export const GLOBAL_SETTING_ROUTES: {
  name: ROUTE_NAMES
  Component: JSX.Element
  withSidebar?: boolean
}[] = [
  {
    name: ROUTE_NAMES.GLOBAL_SETTING,
    Component: (
      <Navigate to={ROUTE_NAMES.PASSWORD_POLICY} options={{ replace: true }} />
    )
  },
  {
    name: ROUTE_NAMES.COUNTRIES,
    Component: <CountryList />
  },
  {
    name: ROUTE_NAMES.COUNTRY_CREATE,
    Component: <CountryCreate />
  },
  {
    name: ROUTE_NAMES.COUNTRY_EDIT,
    Component: <CountryEdit />
  },
  {
    name: ROUTE_NAMES.PASSWORD_POLICY,
    Component: <PasswordPolicy />
  },
  {
    name: ROUTE_NAMES.ATTACK_PROTECTION,
    Component: <AttackProtection />
  }
]
