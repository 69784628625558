import { FC } from 'react'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { getIcon } from './functions'
import { Namespaces, SIZE } from './constants'

export type DeviceIconProps = {
  name: Namespaces
} & TagsNoRef<'svg'>

export const DeviceIcon: FC<DeviceIconProps> = ({ name, ...props }) => (
  <svg width={SIZE} height={SIZE} {...props}>
    <use xlinkHref={`#${getIcon(name)}`} />
  </svg>
)

export default DeviceIcon
