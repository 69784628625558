export enum WEBHOOK_EVENT_ACTION {
  CREATED = 'webhook_endpoint_created',
  TURN_OFF = 'webhook_endpoint_disabled',
  TRUN_ON = 'webhook_endpoint_enabled',
  UPDATED = 'webhook_endpoint_updated',
  DELETED = 'webhook_endpoint_deleted'
}

const WEBHOOK_EVENT_TITLES: { [key in WEBHOOK_EVENT_ACTION]: string } = {
  [WEBHOOK_EVENT_ACTION.CREATED]: 'Created the Webhooks',
  [WEBHOOK_EVENT_ACTION.TURN_OFF]: 'Turned off Webhooks URL',
  [WEBHOOK_EVENT_ACTION.TRUN_ON]: 'Turned on Webhooks URL',
  [WEBHOOK_EVENT_ACTION.UPDATED]: 'Updated the Webhooks',
  [WEBHOOK_EVENT_ACTION.DELETED]: 'Deleted the Webhooks URL'
}

export const getTitle = (action: WEBHOOK_EVENT_ACTION) => {
  return WEBHOOK_EVENT_TITLES[action]
}
