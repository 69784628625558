import { useCountryStore } from '$hooks/stores'
import { TAppRoleDetail } from '$services/api'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { grid, label, value } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { container, innerContainer } from '../styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { FC } from 'react'

const { colors } = themeVars

type Props = {
  role: TAppRoleDetail
}

// type UserColumnData = TAppRoleDetail['users'][number]

const RoleInfo: FC<Props> = ({ role }) => {
  const isMounted = useIsMounted()

  const countries = useCountryStore(state => state.countryOptions)

  return (
    <article className={container}>
      <article
        className={innerContainer}
        data-ready={trueOrUndefined(isMounted)}
      >
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.two}>ROLE INFO</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            View detailed information about each role to understand its
            permissions and responsibilities.
          </p>
        </article>

        <article className={grid}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <label className={label}>Role Name</label>
            <span className={value}>{role.name}</span>
          </article>

          <article className={flexColumn} style={{ gap: 4 }}>
            <label className={label}>Display Name</label>
            <span className={value}>{role.display_name ?? '-'}</span>
          </article>

          <article className={flexColumn} style={{ gap: 4 }}>
            <label className={label}>Country/Region</label>
            <span className={value}>
              {countries.find(d => +d.value === role.group.country_id)?.label}
            </span>
          </article>

          <article className={flexColumn} style={{ gap: 4 }}>
            <label className={label}>Group Name</label>
            <span className={value}>{role.group.name}</span>
          </article>

          <article className={flexColumn} style={{ gap: 4 }}>
            <label className={label}>Description</label>
            <span className={value}>{role.description ?? '-'}</span>
          </article>
        </article>
      </article>
    </article>
  )
}

export default RoleInfo
