import { cloneElement, createElement, forwardRef, isValidElement } from 'react'
import { buildURL, getDetail, RedirectOptions, RouteNames } from './config'
import { useRedirectProxy, useRoute } from '$contexts/RouteContext/hooks'

type TableRowProps = {
  to: RouteNames
  options?: RedirectOptions
} & React.HTMLAttributes<HTMLTableRowElement>

const TableRowLink = forwardRef<HTMLTableRowElement, TableRowProps>(
  (
    {
      children,
      to,
      options,
      onClick: onNativeClick = () => undefined,
      ...props
    },
    ref
  ) => {
    const isJSX = isValidElement(children)
    const redirect = useRedirectProxy()
    const currentRoute = useRoute()

    const redirectOptions = (() => {
      if (typeof options == 'function') {
        const { params, hash, queryParams } = currentRoute
        return options({ hash, params, queryParams })
      }
      return options
    })()

    const { pathname } = getDetail(to)
    const href = buildURL(pathname, redirectOptions).toString()

    const onClick: React.MouseEventHandler<HTMLTableRowElement> = e => {
      const hasModKeys = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey

      onNativeClick(e)

      if (e.defaultPrevented) return

      if (hasModKeys) {
        // Open in new tab when Cmd/Ctrl is pressed
        window.open(href, '_blank')
      } else {
        // Prevent default and redirect within the app
        e.preventDefault()
        redirect(to, redirectOptions)
      }
    }

    const passProps = { href, ref, ...props }

    if (isJSX) return cloneElement(children, passProps)

    return createElement(
      'tr',
      { onClick, style: { cursor: 'pointer' }, ...passProps },
      children
    )
  }
)

export default TableRowLink
