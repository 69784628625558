import { Content, Overlay, Portal, Root, Title } from '@radix-ui/react-dialog'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { toClassNames } from '@genie-fintech/ui/functions'
import { modalContent, modalContentContainer, modalOverlay } from './index.css'

type ModalProps = PropsWithChildren<{
  open: boolean
  contentClassName?: string
  onClickOverlay?: () => void
  isCenter?: boolean
  contentStyle?: React.CSSProperties
}>

export const ModalV2: FC<ModalProps> = ({
  open,
  contentClassName,
  isCenter = false,
  onClickOverlay,
  children,
  contentStyle
}) => {
  // We need to manage the internal open state to delay unmounting
  const [internalOpen, setInternalOpen] = useState<boolean>(open)

  // When the open prop changes, we need to update the internal open state for the animation
  useEffect(() => {
    if (open) {
      setInternalOpen(true) // Open immediately
    } else {
      setTimeout(() => setInternalOpen(false), 400) // Delay unmounting
    }
  }, [open])

  return (
    <Root
      open={internalOpen}
      modal
      onOpenChange={state => setInternalOpen(state)}
    >
      <Portal>
        <Overlay
          className={modalOverlay({ isCenter, isOpen: open })}
          onClick={onClickOverlay}
        />
        <Content className={toClassNames(modalContent, contentClassName)}>
          <section
            className={modalContentContainer({ isCenter, isOpen: open })}
            style={contentStyle}
          >
            <VisuallyHidden>
              <Title></Title>
            </VisuallyHidden>
            {children}
          </section>
        </Content>
      </Portal>
    </Root>
  )
}
