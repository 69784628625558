import {
  getDevicesLogs,
  getGlobalAppUserDetail,
  getGlobalAppUserList,
  putGlobalAppUserDetail
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useGlobalAppUserService = () => {
  const {
    run: fetchGlobalAppUserList,
    runAsync: fetchGlobalAppUserListAsync,
    data: GlobalAppUserListData,
    loading: fetchingGlobalAppUserList
  } = useRequest(getGlobalAppUserList, {
    manual: true
  })

  const {
    run: fetchGlobalAppUserDetail,
    runAsync: fetchGlobalAppUserDetailAsync,
    data: globalUserDetail,
    loading: fetchingGlobalAppUserDetail
  } = useRequest(getGlobalAppUserDetail, {
    manual: true
  })

  const { runAsync: updateGlobalUserAsync, loading: updatingGlobalUser } =
    useRequest(putGlobalAppUserDetail, {
      manual: true,
      onSuccess: (_, [userId]) => {
        toast.success('Successfully Updated!')
        fetchGlobalAppUserDetail(userId)
      }
    })

  const { data: list = [], meta } = { ...GlobalAppUserListData }

  const {
    run: getAppUserDeviceLogin,
    loading: isDevicesLoading,
    data: deviceLoginData
  } = useRequest(getDevicesLogs, { manual: true })

  return {
    fetchGlobalAppUserList,
    fetchGlobalAppUserListAsync,
    fetchingGlobalAppUserList,
    globalUsers: { list, meta },

    globalUser: globalUserDetail?.data,
    fetchGlobalAppUserDetail,
    fetchGlobalAppUserDetailAsync,
    fetchingGlobalAppUserDetail,

    updateGlobalUserAsync,
    updatingGlobalUser,

    getAppUserDeviceLogin,
    isDevicesLoading,
    deviceLoginData
  }
}
