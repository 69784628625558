import { Toaster } from 'sonner'
import {
  useHtmlClass,
  useIsMounted,
  useTheme,
  useTypography
} from '@genie-fintech/ui/hooks'
import { useRouteMeta } from '$hooks/actions'
import { Router } from '$router/Router'
import { Sprite } from '@genie-fintech/ui/icons'
import { Tooltip } from 'react-tooltip'
import { TOOLTIP_ID } from './constants'
import { AuthProvider } from '$contexts/AuthContext/AuthProvider'
import RedirectPrompt from '$blocks/RedirectPrompt'

import DeviceIconSprites from '$components/Icons/DeviceIcon/Sprites'
import ExtraIconSprites from '$components/Icons/ExtraIcon/Sprites'

const App = () => {
  const {
    className,
    mode: { isDarkMode, isAuto }
  } = useTheme()

  const typography = useTypography()

  const isMounted = useIsMounted()

  const tokenClasses = [className, typography.className]

  useHtmlClass(tokenClasses)

  useRouteMeta()

  if (!isMounted) return null

  return (
    <>
      <Sprite />
      <DeviceIconSprites />
      <ExtraIconSprites />

      <AuthProvider>
        <Router />
      </AuthProvider>

      <RedirectPrompt />

      <Tooltip
        id={TOOLTIP_ID}
        className="!font-medium !z-[1001] !bg-[--colors-neutral-60] !text-xs !px-2 !py-1"
      />

      <Toaster
        duration={3000}
        position="top-center"
        richColors
        theme={(() => {
          if (isAuto) return 'system'
          if (isDarkMode) return 'dark'
          return 'light'
        })()}
        toastOptions={{ className: 'p-5 whitespace-pre-wrap' }}
      />
    </>
  )
}

export default App
