import { description, flexColumn } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { GitFork, Plus } from 'lucide-react'
import {
  cardContainer,
  cardSubContainer,
  container,
  modalContent,
  header,
  urlButton,
  main,
  innerContainer
} from './styles.css'
import { useRequest } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'
import { APP_WEBHOOK, getWebhooksByAppId } from '$services/api'
import { useApiListingParams } from '$hooks/actions'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import WebhookStatusSwitch from './components/WebhookStatusSwitch'
import SendTestButton from './components/SendTestButton'
import DeleteWebhookButton from './components/DeleteWebhookButton'
import Modal from '$components/Modal'
import { useModal } from '$components/Modal/hooks/useModal'
import WebhookForm from './components/WebhookForm'
import { TField } from './components/WebhookForm/constants'
import ViewEventJson from './components/ViewEventJson'
import AuditLog from './components/AuditLog'
import { useWebhookEvents } from './useWebhookEvents'
import ScrollPagination from '$components/ScrollPagination'
import Header from '$layouts/LayoutWithSidebar/Header'
import { EventList } from './components/AuditLog/EventList'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const PER_PAGE = 15

const AppWebhooks = () => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const [appWebhooks, setAppWebhooks] = useState<APP_WEBHOOK[]>([])

  const [selectedIndex, setSelectedIndex] = useState<number>()

  const { webhookEvents } = useWebhookEvents()

  const modalProps = useModal({ isFocusMode: true, closeOnClickOutside: false })

  const { openModal, closeModal } = modalProps

  const {
    data: appWebhooksData,
    loading: fetchingAppWebhooksByAppId,
    runAsync: fetchAppWebhooksByAppIdAsync
  } = useRequest(getWebhooksByAppId, {
    manual: true
  })

  const { meta } = { ...appWebhooksData }

  const initialLoading = !appWebhooks.length && fetchingAppWebhooksByAppId

  const {
    pagerProps: { currentPage, jump }
  } = useApiListingParams({ total: meta?.total, perPage: PER_PAGE })

  const fetchAppWebhooksByPageAsync = useCallback(
    (page: number) => {
      if (!appId) return
      return fetchAppWebhooksByAppIdAsync({
        appId,
        page,
        per_page: PER_PAGE
      }).then(({ data }) =>
        setAppWebhooks(prev => (page === 1 ? data : [...prev, ...data]))
      )
    },
    [fetchAppWebhooksByAppIdAsync, appId]
  )

  useEffect(() => {
    fetchAppWebhooksByPageAsync(currentPage)
  }, [fetchAppWebhooksByPageAsync, currentPage])

  useBreadcrumb([{ name: 'Webhooks' }])

  const onAddWebhook = useCallback(() => {
    setSelectedIndex(undefined)
    openModal()
  }, [openModal])

  const onClickUrl = useCallback(
    (index: number) => {
      setSelectedIndex(index)
      openModal()
    },
    [openModal]
  )

  const onCancel = useCallback(() => {
    setSelectedIndex(undefined)
    closeModal()
  }, [closeModal])

  const onDeleteWebhook = useCallback((id: string) => {
    setAppWebhooks(prev => prev.filter(d => d.id !== id))
  }, [])

  const onSuccess = useCallback(
    async (values: TField) => {
      if (selectedIndex === undefined) {
        await fetchAppWebhooksByPageAsync(1)
        onCancel()
        return
      }

      setAppWebhooks(prev => {
        const newWebhook = [...prev]
        newWebhook[selectedIndex] = {
          ...prev[selectedIndex],
          ...values,
          webhook_events: webhookEvents.filter(d =>
            values.webhook_event_ids.includes(d.id)
          )
        }
        return newWebhook
      })

      onCancel()
    },
    [fetchAppWebhooksByPageAsync, selectedIndex, onCancel, webhookEvents]
  )

  const isEmpty = !appWebhooks.length && !fetchingAppWebhooksByAppId

  return (
    <>
      <Header style={{ gap: 8 }}>
        <GitFork size={20} />

        <span className={title.two} style={{ paddingLeft: 4 }}>
          Webhooks
        </span>

        <span style={{ flex: 1 }} />

        <AuditLog />

        <Button onClick={onAddWebhook} styleVariants={{ size: 'small' }}>
          <Plus size={16} />
          Add Webhook
        </Button>
      </Header>

      <article className={container}>
        {initialLoading && <Loading />}

        <article className={innerContainer}>
          <header className={header}>
            <article className={flexColumn} style={{ gap: 4 }}>
              <h2 className={title.two}>WEBHOOKS MANAGEMENT</h2>
              <p className={description} style={{ maxWidth: 600 }}>
                Webhooks offer instant notifications for a range of email
                events, such as bounces, opens, and clicks, facilitating
                automated responses and improving the efficiency of email
                workflows.
              </p>
            </article>
            <article>
              <ViewEventJson />
            </article>
          </header>

          <main className={main}>
            {isEmpty && <EmptyData type="webhook" onClick={onAddWebhook} />}

            {appWebhooks.map((v, k) => (
              <article key={v.id} className={cardContainer}>
                <article className={cardSubContainer}>
                  <button className={urlButton} onClick={() => onClickUrl(k)}>
                    {v.url}
                  </button>

                  <span style={{ flex: 1 }} />

                  <SendTestButton webhookId={v.id} />

                  <WebhookStatusSwitch
                    webhookId={v.id}
                    is_enabled={v.is_enabled}
                  />
                </article>

                <article
                  className={cardSubContainer}
                  style={{ alignItems: 'end' }}
                >
                  <article className={flexColumn} style={{ flex: 1, gap: 8 }}>
                    <p
                      className={footnote.two}
                      style={{ color: colors.neutral[40] }}
                    >
                      EVENTS
                    </p>

                    <EventList
                      webhook_event_ids={v.webhook_events.map(w => w.id)}
                    />
                  </article>

                  <DeleteWebhookButton
                    webhookId={v.id}
                    onDeleteCallBackFn={onDeleteWebhook}
                  />
                </article>
              </article>
            ))}

            <ScrollPagination
              loading={fetchingAppWebhooksByAppId}
              onTrigger={jump}
              meta={meta}
            />
          </main>
        </article>
      </article>

      <Modal {...modalProps} contentProps={{ className: modalContent }}>
        <WebhookForm
          webhook={
            selectedIndex === undefined ? undefined : appWebhooks[selectedIndex]
          }
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      </Modal>
    </>
  )
}

export default AppWebhooks
