import { TDeleteItemType } from '$components/DeleteDoubleConfirmPopUp/types'
import { flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { FC, PropsWithChildren } from 'react'
import { DELETE_INFO } from './constants'
import {
  footnote,
  title as titleStyle
} from '@genie-fintech/ui/style/typography'
import { container, inner } from './styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

type Props = {
  type: TDeleteItemType
}

const DangerZone: FC<PropsWithChildren<Props>> = ({ children, type }) => {
  const isMounted = useIsMounted()

  const { title, desc } = DELETE_INFO[type]

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <label className={titleStyle.two}>DANGER ZONE</label>

      <article className={inner}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={titleStyle.two}>{title.toLocaleUpperCase()}</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            {desc}
          </p>
        </article>

        {children}
      </article>
    </article>
  )
}

export default DangerZone
