import { pluralize } from '$app/utils'
import Pagination from '$components/Pagination'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppGroupService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { TAppGroupRoleResponse } from '$services/api'
import { flexColumn, flexRow } from '$styles/common.css'
import { useEffect, useState } from 'react'
import { container } from './styles.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import SearchBox from '$components/SearchBox'
import Table, { Column } from '$components/Table'
import PreviewPermissionDrawer from '$blocks/PreviewPermissionDrawer'
import { viewButton } from '$components/Table/styles.css'
import ActionLink from '$components/Table/ActionLink'

const { colors } = themeVars

type ColumnData = TAppGroupRoleResponse['data'][number]

const GroupRoles = ({ groupName }: { groupName: string }) => {
  const [selectedRole, setSelectedRole] = useState<ColumnData>()

  const {
    route: { params }
  } = useRouteSummary()

  const { appId, groupId } = params

  const {
    fetchAppGroupRole,
    fetchingAppGroupRole,
    groupRoles: { list, meta }
  } = useAppGroupService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !groupId) return
    fetchAppGroupRole({
      page,
      per_page: perPage,
      application_id: appId,
      group_id: groupId,
      q: debouncedSearchValue
    })
  }, [appId, groupId, fetchAppGroupRole, page, perPage, debouncedSearchValue])

  const columns: Column<ColumnData>[] = [
    {
      name: 'No',
      render: ({ index }) => (page - 1) * perPage + index + 1
    },
    {
      name: 'Role Name',
      dataIndex: 'name'
    },
    {
      name: 'Display Name',
      dataIndex: 'display_name',
      render: ({ value }) => value ?? '-'
    },
    {
      name: 'Permission',
      dataIndex: 'permission_count',
      render: ({ record }) => (
        <button className={viewButton} onClick={() => setSelectedRole(record)}>
          View
        </button>
      )
    },
    {
      name: '',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_ROLE_DETAIL}
          options={{
            params: { ...params, roleId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <>
      <article className={container}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.three}>{groupName}'s ROLES</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            Manage and review user accounts for efficient system administration.
          </p>
        </article>

        <article className={flexColumn} style={{ gap: 16, flex: 1 }}>
          <article className={flexRow} style={{ gap: 4 }}>
            <article style={{ flex: 1, maxWidth: 560 }}>
              <SearchBox
                value={searchValue}
                onChange={updateSearchValue}
                placeholder="Search here..."
              />
            </article>

            <span style={{ flex: 1 }} />

            {!!meta?.total && (
              <span
                className={title.six}
                style={{
                  color: colors.text.disabled,
                  textTransform: 'uppercase'
                }}
              >
                {pluralize(meta.total, 'role')}
              </span>
            )}
          </article>

          <Table columns={columns} data={list} loading={fetchingAppGroupRole} />

          {!!list.length && <Pagination meta={meta} pagerProps={pagerProps} />}
        </article>
      </article>

      <PreviewPermissionDrawer
        role={selectedRole}
        appId={appId}
        onClose={() => setSelectedRole(undefined)}
      />
    </>
  )
}

export default GroupRoles
