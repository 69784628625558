import { on, onOneOf } from '$router/config'
import React from 'react'
import { APPLICATION_USER_ROUTES } from './constants'
import UserProfileLayout from '$layouts/UserProfileLayout'
import { USER_TYPE } from '$layouts/UserProfileLayout/types'

const ApplicationUserRoutes = () => {
  const isApplicationUserRoutes = onOneOf(
    APPLICATION_USER_ROUTES.map(v => v.name)
  )

  if (!isApplicationUserRoutes) return null

  return (
    <UserProfileLayout userType={USER_TYPE.SERVICE_USER}>
      {APPLICATION_USER_ROUTES.map(
        ({ name, Component }) =>
          on(name) && <React.Fragment key={name}>{Component}</React.Fragment>
      )}
    </UserProfileLayout>
  )
}

export default ApplicationUserRoutes
