import { cn } from '$app/utils'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, borderNeutral20, flexRow } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Controller, useController, useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { useCountryStore } from '$hooks/stores'
import Loading from '$components/Loading'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import FooterAction from '$components/FooterAction'
import { useCallback } from 'react'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { useAppGroupService } from '$hooks/services'
import { TAppGroupPayload, TID } from '$services/api'
import { toClassNames } from '@genie-fintech/ui/functions'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import Phone from '$components/Phone'

const { colors } = themeVars

const AppGroupCreate = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const countryOptions = useCountryStore(state => state.countryOptions)

  const { saveGroupAsync, savingGroup } = useAppGroupService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = methods

  const { field: phoneCodeField } = useController({
    name: 'phone_code',
    control
  })
  const { field: phoneNoField } = useController({
    name: 'phone_no',
    control
  })

  useRedirectPrompt({ shouldPrompt: isDirty })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_GROUPS, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(
    (groupId: TID) => {
      redirect(ROUTE_NAMES.APP_GROUP_DETAIL, { params: { ...params, groupId } })
    },
    [params]
  )

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const { country, ...rest } = formValues

    const payload: TAppGroupPayload = {
      country_id: +country,
      ...rest
    }

    return saveGroupAsync(appId, payload).then(({ data }) => {
      onSuccess(data.id)
    })
  })

  if (!countryOptions.length) return <Loading />

  return (
    <form
      className="flex flex-col mx-auto max-w-[1056px] gap-2 p-4"
      onSubmit={onSubmit}
    >
      <article
        className={cn(
          'flex flex-col rounded-lg',
          defaultBackground,
          borderNeutral20
        )}
      >
        <header
          className="flex items-center px-12 py-3 border-b"
          style={{ borderColor: colors.neutral[10] }}
        >
          <p
            className="text-xl font-semibold"
            style={{ color: colors.text.light }}
          >
            Add New Group
          </p>
        </header>

        <article className="flex px-12 py-7">
          <article className="flex flex-col gap-6">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">COUNTRY/REGION</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                Specify country or region to tailor settings and services
                accordingly.
              </p>
            </article>

            <Controller
              name="country"
              control={control}
              render={({ field }) => {
                return (
                  <Tabs.Root
                    value={field.value}
                    onValueChange={field.onChange}
                    format={{ type: 'segmented' }}
                  >
                    <Tabs.List styleVariants={{ hAlign: 'left' }}>
                      {countryOptions.map((v, k) => (
                        <Tabs.Trigger key={k} value={`${v.value}`}>
                          {v.label}
                        </Tabs.Trigger>
                      ))}
                    </Tabs.List>
                  </Tabs.Root>
                )
              }}
            />
          </article>
        </article>
      </article>

      <article
        className={cn(
          'flex flex-col rounded-lg px-12 py-7',
          defaultBackground,
          borderNeutral20
        )}
      >
        <article className="grid lg:grid-cols-2 gap-8">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">GROUP INFO</p>
            <p className="text-xs" style={{ color: colors.neutral[50] }}>
              View detailed information about each group to manage roles and
              permissions effectively.
            </p>
          </article>

          <article className="flex flex-col gap-5">
            <BaseText
              control={control}
              name="name"
              label="Group Name"
              required
            />

            <Phone
              label="Phone Number"
              inputProps={phoneNoField}
              callingCodeProps={phoneCodeField}
            />

            <Textarea
              name="description"
              control={control}
              label="Description"
            />
          </article>
        </article>
      </article>

      <FooterAction>
        <article
          className={toClassNames(content, flexRow)}
          style={{ justifyContent: 'space-between' }}
        >
          <Button
            disabled={savingGroup}
            styleVariants={{ type: 'text' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button type="submit" disabled={!isDirty || savingGroup}>
            {savingGroup && <Spinner />}
            Create
          </Button>
        </article>
      </FooterAction>
    </form>
  )
}

export default AppGroupCreate
