import { description } from '$styles/common.css'
import { Avatar, Button } from '@genie-fintech/ui/components'
import {
  container,
  flexColumn,
  flexRow,
  formFooter,
  formMain,
  formTitle,
  title
} from './styles.css'
import { useModal } from '$components/Modal/hooks/useModal'
import Modal from '$components/Modal'
import {
  email as emailStyle,
  name as nameStyle
} from '../ProfileCard/styles.css'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { useShallow } from 'zustand/react/shallow'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Password } from '@genie-fintech/ui/components/hook-fields'
import { useCallback } from 'react'
import { useRequest } from 'ahooks'
import { putServiceUserVerifyEmail } from '$services/api'
import { toast } from 'sonner'

const { colors } = themeVars

const schema = z.object({
  password: z.string().min(1, 'Required!')
})

type TField = z.infer<typeof schema>

const defaultValues: TField = {
  password: ''
}

const VerifyForm = ({ onClose }: { onClose: VoidFunction }) => {
  const profile = useProfileDataStore(useShallow(state => state.profile))

  const updateProfilePartially = useProfileDataStore(
    state => state.updateProfilePartially
  )

  const verifyEmailRequest = useRequest(putServiceUserVerifyEmail, {
    manual: true,
    onSuccess: () => {
      updateProfilePartially({ is_verified: true })
      toast.success('Email verified successfully.')
      onClose()
    }
  })

  const verifyEmail = verifyEmailRequest.run

  const { control, handleSubmit } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const onContinue = useCallback(
    (values: TField) => {
      if (!profile?.id) return

      verifyEmail(profile.id, values)
    },
    [profile?.id, verifyEmail]
  )

  return (
    <form className={flexColumn} onSubmit={handleSubmit(onContinue)}>
      <main className={formMain}>
        <header className={flexColumn} style={{ gap: 4 }}>
          <h1 className={formTitle}>Verify Email</h1>
          <p className={description}>
            Verify this user’s email to ensure the security standard.
          </p>
        </header>

        <article className={flexColumn} style={{ gap: 20 }}>
          <article className={flexRow} style={{ gap: 8 }}>
            <Avatar size={32} />

            <article>
              <p className={nameStyle}>{profile?.name}</p>
              <p className={emailStyle}>{profile?.email}</p>
            </article>
          </article>

          <hr style={{ borderColor: colors.neutral[10] }} />

          <Password
            name="password"
            control={control}
            label="Password"
            description="Enter your login password to verify this user email."
            required
            inputProps={{
              placeholder: 'Password',
              autoComplete: 'new-password'
            }}
          />
        </article>
      </main>

      <footer className={formFooter}>
        <Button
          styleVariants={{ size: 'small', type: 'outlined', kind: 'neutral' }}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button styleVariants={{ size: 'small' }} type="submit">
          Continue
        </Button>
      </footer>
    </form>
  )
}

const VerifyEmailCard = () => {
  const modalProps = useModal({ isFocusMode: true, closeOnClickOutside: false })

  return (
    <>
      <article className={container}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title}>Verify Your Email</p>
          <p className={description}>
            By clicking the verify button to proceed the email verification
            process.
          </p>
        </article>

        <article>
          <Button
            styleVariants={{ size: 'small' }}
            onClick={modalProps.openModal}
          >
            Verify
          </Button>
        </article>
      </article>

      <Modal {...modalProps}>
        <VerifyForm onClose={modalProps.closeModal} />
      </Modal>
    </>
  )
}

export default VerifyEmailCard
