import { flexRow } from '$styles/common.css'
import { useWebhookEvents } from '../../useWebhookEvents'
import StatusBadge from '$components/StatusBadge'

export const EventList = ({
  webhook_event_ids
}: {
  webhook_event_ids?: string[]
}) => {
  const { getWebhookEventById } = useWebhookEvents()

  return (
    <article className={flexRow} style={{ gap: 8 }}>
      {webhook_event_ids?.map(id => {
        const { name } = getWebhookEventById(id) ?? {}

        if (!name) return null

        return (
          <StatusBadge
            key={id}
            styleVariants={{ kind: 'primary', type: 'faint', size: 'small' }}
          >
            {name}
          </StatusBadge>
        )
      })}
    </article>
  )
}
