import { FC } from 'react'

import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'
import BaseField, {
  BaseTextProps as BaseFieldProps
} from '@genie-fintech/ui/components/fields/BaseText'
import { Icon } from '@genie-fintech/ui/icons'

// import { CallingCodes } from '$constants/country-data'

import { select, selectContainer, selectIcon } from './styles.css'
import { hideInputAppearance } from '$styles/common.css'
import { useCountryStore } from '$hooks/stores'

type CallingCodeProps = TagsNoRef<'select'>
type InheritedProps = Omit<BaseFieldProps, 'affix' | 'type' | 'min'>

export type PhoneProps = InheritedProps & {
  callingCodeProps?: CallingCodeProps
}

export const Phone: FC<PhoneProps> = ({ callingCodeProps, ...fieldProps }) => {
  const baseFieldProps: BaseFieldProps = {
    ...fieldProps,
    inputProps: {
      type: 'number',
      min: '0',
      ...propsWithClassNames(fieldProps.inputProps, hideInputAppearance)
    }
  }

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  return (
    <BaseField
      affix={{
        pre: (
          <label
            aria-label="Calling Code"
            onClick={e => e.stopPropagation()}
            className={selectContainer}
          >
            <select {...propsWithClassNames(callingCodeProps, select)}>
              {phoneOptions.map(({ value }) => (
                <option key={value} title={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <Icon namespace="UpDown" className={selectIcon} />
          </label>
        )
      }}
      {...baseFieldProps}
    />
  )
}

export default Phone
