import { ROUTE_NAMES } from '$router/config'
import { LucideIcon } from 'lucide-react'

export type MENU = {
  path: ROUTE_NAMES
  Icon: LucideIcon
  label: string
}

export type MENU_GROUP = {
  title?: string
  menus: MENU[]
}[]

export enum USER_TYPE {
  SERVICE_USER,
  DASHBOARD_USER
}
