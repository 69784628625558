import { FC, useCallback, useEffect } from 'react'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import Drawer from '$components/Drawer'
import { getAppRoleListByUserId, TID } from '$services/api'
import { flexColumn } from '$styles/common.css'
import { drawerContent, main, textNoWrap } from './styles.css'
import { useRequest } from 'ahooks'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import Table, { Column } from '$components/Table'
import ActionLink from '$components/Table/ActionLink'
import { ROUTE_NAMES } from '$router/config'
import StatusBadge from '$components/StatusBadge'
import { useApiListingParams } from '$hooks/actions'
import Pagination from '$components/Pagination'

const { colors } = themeVars

type Props = {
  user?: { id: TID; name: string }
  onClose: VoidFunction
}

type Permission = Awaited<
  ReturnType<typeof getAppRoleListByUserId>
>['data'][number]

const PreviewRoleDrawer: FC<Props> = ({ user, onClose }) => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const { id: userId, name: userName } = user ?? {}

  const {
    run: fetchAppRoleListByUserId,
    loading: fetchingAppRoleListByUserId,
    data
  } = useRequest(getAppRoleListByUserId, {
    manual: true
  })

  const { data: roles = [], meta } = data ?? {}

  const { pagerProps, perPage } = useApiListingParams({ total: meta?.total })

  const { currentPage } = pagerProps

  useEffect(() => {
    if (!appId || !userId) return
    fetchAppRoleListByUserId({
      application_id: appId,
      userId,
      per_page: perPage,
      page: currentPage
    })
  }, [appId, userId, fetchAppRoleListByUserId, currentPage, perPage])

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open) return
      onClose()
    },
    [onClose]
  )

  const isEmpty = !roles.length && !fetchAppRoleListByUserId

  const hasData = !!roles.length && !fetchingAppRoleListByUserId

  const columns: Column<Permission>[] = [
    {
      name: 'Name',
      dataIndex: 'name',
      className: textNoWrap
    },
    {
      name: 'Country',
      dataIndex: 'country',
      render: ({ record }) => (
        <StatusBadge
          styleVariants={{ type: 'faint', size: 'tiny', kind: 'tertiary' }}
        >
          {record.country.name.toLocaleUpperCase()}
        </StatusBadge>
      )
    },
    {
      name: 'Group',
      dataIndex: 'group',
      render: ({ record }) => record.group.name
    },
    {
      name: '',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_ROLE_DETAIL}
          options={{
            params: { ...route.params, roleId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <Drawer
      open={!!userId}
      onOpenChange={onOpenChange}
      className={drawerContent}
    >
      <header className={flexColumn} style={{ gap: 4 }}>
        <p className={title.three}>
          {[userName ? `${userName}'s` : '', 'ROLES, REGIONS & GROUPS']
            .filter(Boolean)
            .join(' ')}
        </p>

        <p className={footnote.one} style={{ color: colors.neutral[60] }}>
          View the assigned group and region for each role to ensure proper
          access and alignment.
        </p>
      </header>

      <main className={main}>
        {fetchingAppRoleListByUserId && <Loading />}

        {isEmpty && <EmptyData type="permission" />}

        {hasData && (
          <>
            <Table
              data={roles}
              loading={fetchingAppRoleListByUserId}
              columns={columns}
            />

            <Pagination meta={meta} pagerProps={pagerProps} />
          </>
        )}
      </main>
    </Drawer>
  )
}

export default PreviewRoleDrawer
