import AppAdvancedSetting from '$pages/Apps/AppAdvancedSetting'
import AppBasicSetting from '$pages/Apps/AppBasicSetting'
import AppGroupCreate from '$pages/Apps/AppGroup/Create'
import AppGroupDetail from '$pages/Apps/AppGroup/Detail'
import AppGroupEdit from '$pages/Apps/AppGroup/Edit'
import AppGroupList from '$pages/Apps/AppGroup/List'
import AppHome from '$pages/Apps/AppHome'
import AppLaunchPadSetting from '$pages/Apps/AppLaunchPadSetting'
import AppPermissionCreate from '$pages/Apps/AppPermission/Create'
import AppPermissionDetail from '$pages/Apps/AppPermission/Detail'
import AppPermissionEdit from '$pages/Apps/AppPermission/Edit'
import AppPermissionList from '$pages/Apps/AppPermission/List'
import AppPermissionCSVImport from '$pages/Apps/AppPermission/AppPermissionCSVImport'
import AppRoleCreate from '$pages/Apps/AppRole/Create'
import AppRoleDetail from '$pages/Apps/AppRole/Detail'
import AppRoleEdit from '$pages/Apps/AppRole/Edit'
import AppRoleList from '$pages/Apps/AppRole/List'
import AppRoleCSVImport from '$pages/Apps/AppRole/AppRoleCSVImport'
import AppList from '$pages/Apps/List'
import AppUserCSVImport from '$pages/Apps/AppUser/AppUserCSVImport'
import { ROUTE_NAMES } from '$router/config'
import Navigate from '$router/Navigate'
import AppWebhooks from '$pages/Apps/AppWebhooks'
import AppUserList from '$pages/Apps/AppUser/List'
import AppUserDetail from '$pages/Apps/AppUser/Detail'
import AppUserCreate from '$pages/Apps/AppUser/Create'
import AppUserAssignRoles from '$pages/Apps/AppUser/AssignRoles'

export const APPLICATION_ROUTES: {
  name: ROUTE_NAMES
  Component: JSX.Element
  withSidebar?: boolean
}[] = [
  {
    name: ROUTE_NAMES.INDEX,
    Component: <Navigate to={ROUTE_NAMES.APPS} options={{ replace: true }} />
  },
  {
    name: ROUTE_NAMES.APPS,
    Component: <AppList />
  },
  {
    name: ROUTE_NAMES.APP_HOME,
    Component: <AppHome />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_BASIC_SETTING,
    Component: <AppBasicSetting />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_ADVANCED_SETTING,
    Component: <AppAdvancedSetting />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_WEBHOOK_SETTING,
    Component: <AppWebhooks />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
    Component: <AppLaunchPadSetting />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_GROUPS,
    Component: <AppGroupList />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_GROUP_DETAIL,
    Component: <AppGroupDetail />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_GROUP_CREATE,
    Component: <AppGroupCreate />
  },
  {
    name: ROUTE_NAMES.APP_GROUP_EDIT,
    Component: <AppGroupEdit />
  },
  {
    name: ROUTE_NAMES.APP_ROLES,
    Component: <AppRoleList />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_ROLE_DETAIL,
    Component: <AppRoleDetail />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_ROLE_CREATE,
    Component: <AppRoleCreate />
  },
  {
    name: ROUTE_NAMES.APP_ROLE_EDIT,
    Component: <AppRoleEdit />
  },
  {
    name: ROUTE_NAMES.APP_ROLES_CSV_IMPORT,
    Component: <AppRoleCSVImport />
  },
  {
    name: ROUTE_NAMES.APP_USERS,
    Component: <AppUserList />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_USER_DETAIL,
    Component: <AppUserDetail />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_USER_CREATE,
    Component: <AppUserCreate />
  },
  {
    name: ROUTE_NAMES.APP_USER_ASSIGN_ROLES,
    Component: <AppUserAssignRoles />
  },
  {
    name: ROUTE_NAMES.APP_USERS_CSV_IMPORT,
    Component: <AppUserCSVImport />
  },
  {
    name: ROUTE_NAMES.APP_PERMISSIONS,
    Component: <AppPermissionList />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_PERMISSION_DETAIL,
    Component: <AppPermissionDetail />,
    withSidebar: true
  },
  {
    name: ROUTE_NAMES.APP_PERMISSION_CREATE,
    Component: <AppPermissionCreate />
  },
  {
    name: ROUTE_NAMES.APP_PERMISSION_EDIT,
    Component: <AppPermissionEdit />
  },
  {
    name: ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT,
    Component: <AppPermissionCSVImport />
  }
]
