import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'
import { Icon } from '@genie-fintech/ui/icons'
import { ACL_OPTIONS } from '$app/constants'
import { useMount } from 'ahooks'
import { TAppAdvancedSettingPayload, TAppAdvancedSetting } from '$services/api'
import { useAppAdvancedSettingService, useDeleteService } from '$hooks/services'
import {
  useRedirectPrompt,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { BaseText } from '@genie-fintech/ui/components/fields'
import Switch from '$components/Switch'
import Select from '$components/Select'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { useAppDetailStore } from '$hooks/stores'
import Asterisk from '$components/Asterisk'
import DangerZone from '$components/DangerZone'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { flexColumn } from '$styles/common.css'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { Trash2 } from 'lucide-react'
import { container, grid, innerContainer } from './styles.css'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import Loading from '$components/Loading'
import FooterAction from '$components/FooterAction'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const schema = z.object({
  acl_type: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  sso_enabled: z.boolean(),
  allowed_multi_device_login: z.boolean(),
  callback_url: z.string().trim().url('Invalid URL!').or(z.literal('')),
  callbacks: z
    .object({
      callback_url: z.string().trim()
    })
    .array()
    .min(1, 'At least 1 callback url required!'),
  allowed_origin_resource: z
    .string()
    .trim()
    .url('Invalid URL!')
    .or(z.literal('')),
  allowed_origins: z
    .object({
      resource: z.string().trim()
    })
    .array()
    .min(1, 'At least 1 allowed origin required!')
})

type TFormValues = z.infer<typeof schema>

const AppAdvancedSetting = () => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
    setValue,
    clearErrors,
    trigger
  } = methods

  useRedirectPrompt({ shouldPrompt: isDirty })

  const { deleteAppAsync, deletingApp } = useDeleteService()

  const {
    fetchAppAdvancedSettingAsync,
    fetchingAppAdvancedSetting,
    updateAppAdvancedSettingAsync,
    updatingAppAdvancedSetting
  } = useAppAdvancedSettingService()

  const transformToFormValues = useCallback(
    (data: TAppAdvancedSetting): TFormValues => {
      return {
        acl_type: ACL_OPTIONS.find(d => d.value === data.acl_type) ?? {
          label: '',
          value: ''
        },
        sso_enabled: data.sso_enabled,
        allowed_multi_device_login: data.allowed_multi_device_login,
        callback_url: '',
        callbacks: data.callbacks ?? [],
        allowed_origin_resource: '',
        allowed_origins: data.allowed_origins ?? []
      }
    },
    []
  )

  useMount(() => {
    if (!appId) return
    fetchAppAdvancedSettingAsync({ application_id: appId }).then(({ data }) => {
      reset(transformToFormValues(data))
    })
  })

  useBreadcrumb([{ name: 'Advanced Setting' }])

  const callback_url_value = useWatch({ name: 'callback_url', control })

  const allowed_origin_resource_value = useWatch({
    name: 'allowed_origin_resource',
    control
  })

  const {
    fields: callbackFields,
    append: appendCallback,
    remove: removeCallback
  } = useFieldArray<TFormValues>({ name: 'callbacks', control })

  const {
    fields: allowedOriginFields,
    append: appendAllowedOrigin,
    remove: removeAllowedOrigin
  } = useFieldArray<TFormValues>({ name: 'allowed_origins', control })

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const payload: TAppAdvancedSettingPayload = {
      ...formValues,
      acl_type: formValues.acl_type.value
    }

    return updateAppAdvancedSettingAsync(appId, payload).then(() => {
      fetchAppAdvancedSettingAsync({ application_id: appId }).then(
        ({ data }) => {
          reset(transformToFormValues(data))
        }
      )
    })
  })

  const onDeleteApp = useCallback(() => {
    if (!appId) return
    deleteAppAsync({ appId })
  }, [appId, deleteAppAsync])

  const isProcessing = fetchingAppAdvancedSetting || updatingAppAdvancedSetting

  const app_name = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const callbacks_error_msg =
    errors.callbacks?.root?.message ?? errors.callbacks?.message

  const allowed_origins_error_msg =
    errors.allowed_origins?.root?.message ?? errors.allowed_origins?.message

  if (fetchingAppAdvancedSetting) return <Loading />

  return (
    <form onSubmit={onSubmit}>
      <article className={flexColumn}>
        <article className={container}>
          <article className={innerContainer}>
            <article className={grid}>
              <article className={flexColumn} style={{ gap: 4 }}>
                <p className={title.two}>{app_name}'s ADVANCED SETTING</p>
                <p
                  className={footnote.one}
                  style={{ color: colors.neutral[60] }}
                >
                  Fine-tune system functionality with detailed options in
                  advanced settings.
                </p>
              </article>

              <article className={flexColumn} style={{ gap: 8 }}>
                <Controller
                  name="acl_type"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <article className="flex flex-col gap-y-1">
                        <label className="text-sm font-medium">
                          ACL Type
                          <Asterisk />
                        </label>
                        <Select
                          {...field}
                          options={ACL_OPTIONS}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <p className="text-xs text-[--colors-danger-default]">
                            {error.message}
                          </p>
                        )}
                      </article>
                    )
                  }}
                />

                <Controller
                  name="sso_enabled"
                  control={control}
                  render={({ field }) => {
                    return (
                      <article className="flex items-center justify-between gap-x-1 border border-[--colors-neutral-10] rounded p-3">
                        <label className="text-sm font-medium">
                          Is SSO Enabled
                        </label>

                        <Switch
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      </article>
                    )
                  }}
                />

                <Controller
                  name="allowed_multi_device_login"
                  control={control}
                  render={({ field }) => {
                    return (
                      <article className="flex items-center justify-between gap-x-1 border border-[--colors-neutral-10] rounded p-3">
                        <label className="text-sm font-medium">
                          Single Device Login
                        </label>

                        <Switch
                          checked={!field.value}
                          onChange={checked => field.onChange(!checked)}
                        />
                      </article>
                    )
                  }}
                />
              </article>
            </article>
          </article>
        </article>

        <article className={container}>
          <article className={innerContainer}>
            <article className={grid}>
              <article className={flexColumn} style={{ gap: 4 }}>
                <p className={title.two}>Callbacks</p>
                <p
                  className={footnote.one}
                  style={{ color: colors.neutral[60] }}
                >
                  Links
                </p>
                {callbacks_error_msg && (
                  <p
                    className={footnote.one}
                    style={{ color: colors.danger[markedDefaultKey] }}
                  >
                    * {callbacks_error_msg}
                  </p>
                )}
              </article>
              <article className={flexColumn} style={{ gap: 8 }}>
                <article
                  style={{ display: 'flex', gap: 8, alignItems: 'start' }}
                >
                  <Controller
                    name="callback_url"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <BaseText
                          affix={{ pre: <Icon namespace="Link" /> }}
                          error={!!error?.message}
                          message={error?.message}
                          inputProps={{
                            value: field.value ?? '',
                            onChange: e => {
                              clearErrors('callback_url')
                              setValue('callback_url', e.currentTarget.value, {
                                shouldDirty: false
                              })
                            }
                          }}
                        />
                      )
                    }}
                  />

                  <Button
                    type="button"
                    onClick={async () => {
                      const isValid = await trigger(['callback_url'])
                      if (!isValid) return
                      appendCallback({ callback_url: callback_url_value })
                      setValue('callback_url', '', { shouldDirty: false })
                    }}
                    disabled={!callback_url_value?.trim()}
                  >
                    <Icon namespace="Add" />
                    Add
                  </Button>
                </article>

                {callbackFields.map((v, k) => {
                  return (
                    <article key={v.id} className="flex gap-x-2">
                      <Controller
                        name={`callbacks.${k}.callback_url`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              affix={{ pre: <Icon namespace="Link" /> }}
                              inputProps={{
                                value: field.value,
                                disabled: true
                              }}
                              error={!!error?.message}
                              message={error?.message}
                            />
                          )
                        }}
                      />

                      <article>
                        <Button
                          styleVariants={{
                            category: 'icon',
                            kind: 'neutral',
                            type: 'outlined'
                          }}
                          onClick={() => removeCallback(k)}
                        >
                          <Icon namespace="Trash" />
                        </Button>
                      </article>
                    </article>
                  )
                })}
              </article>
            </article>
          </article>
        </article>

        <article className={container}>
          <article className={innerContainer}>
            <article className={grid}>
              <article className={flexColumn} style={{ gap: 4 }}>
                <p className={title.two}>Allowed Origins</p>
                <p
                  className={footnote.one}
                  style={{ color: colors.neutral[60] }}
                >
                  Links
                </p>
                {allowed_origins_error_msg && (
                  <p
                    className={footnote.one}
                    style={{ color: colors.danger[markedDefaultKey] }}
                  >
                    * {allowed_origins_error_msg}
                  </p>
                )}
              </article>
              <article className={flexColumn} style={{ gap: 8 }}>
                <article
                  style={{ display: 'flex', gap: 8, alignItems: 'start' }}
                >
                  <Controller
                    name="allowed_origin_resource"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <BaseText
                          affix={{ pre: <Icon namespace="Link" /> }}
                          error={!!error?.message}
                          message={error?.message}
                          inputProps={{
                            value: field.value ?? '',
                            onChange: e => {
                              clearErrors('allowed_origin_resource')
                              setValue(
                                'allowed_origin_resource',
                                e.currentTarget.value,
                                {
                                  shouldDirty: false
                                }
                              )
                            }
                          }}
                        />
                      )
                    }}
                  />

                  <Button
                    type="button"
                    onClick={async () => {
                      const isValid = await trigger(['allowed_origin_resource'])
                      if (!isValid) return
                      appendAllowedOrigin({
                        resource: allowed_origin_resource_value
                      })
                      setValue('allowed_origin_resource', '', {
                        shouldDirty: false
                      })
                    }}
                  >
                    <Icon namespace="Add" />
                    Add
                  </Button>
                </article>

                {allowedOriginFields.map((v, k) => {
                  return (
                    <article key={v.id} className="flex gap-x-2">
                      <Controller
                        name={`allowed_origins.${k}.resource`}
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              affix={{ pre: <Icon namespace="Link" /> }}
                              inputProps={{
                                value: field.value,
                                disabled: true
                              }}
                              error={!!error?.message}
                              message={error?.message}
                            />
                          )
                        }}
                      />

                      <article>
                        <Button
                          styleVariants={{
                            category: 'icon',
                            kind: 'neutral',
                            type: 'outlined'
                          }}
                          onClick={() => removeAllowedOrigin(k)}
                        >
                          <Icon namespace="Trash" />
                        </Button>
                      </article>
                    </article>
                  )
                })}
              </article>
            </article>
          </article>
        </article>

        <article className={container}>
          <article className={innerContainer}>
            <DangerZone type="application">
              <DeleteDoubleConfirmPopUp
                item={{ type: 'application', name: app_name }}
                onExecute={onDeleteApp}
                loading={deletingApp}
              >
                <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                  Delete Application
                  <Trash2 size={16} />
                </Button>
              </DeleteDoubleConfirmPopUp>
            </DangerZone>
          </article>
        </article>
      </article>

      <FooterAction fullWidth={false}>
        <SaveWithShortCutButton
          disabled={!isDirty || isProcessing}
          loading={updatingAppAdvancedSetting}
        />
      </FooterAction>
    </form>
  )
}

export default AppAdvancedSetting
