import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPermissionService, useDeleteService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import PermissionInfo from './PermissionInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { ChevronLeft, PenLine, Trash2 } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'
import Header from '$layouts/LayoutWithSidebar/Header'
import { ButtonVariants } from '@genie-fintech/ui/style/element'
import { title } from '@genie-fintech/ui/style/typography'
import { container } from './styles.css'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const buttonVariants: ButtonVariants = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small'
}

const AppPermissionDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const { permission, fetchAppPermissionDetail, fetchingAppPermissionDetail } =
    useAppPermissionService()

  const { deletePermissionAsync, deletingPermission } = useDeleteService()

  useMount(() => {
    if (!appId || !permissionId) return
    fetchAppPermissionDetail({
      application_id: appId,
      permission_id: permissionId
    })
  })

  useBreadcrumb([
    { name: 'Permissions', path: ROUTE_NAMES.APP_PERMISSIONS },
    { name: permission?.name || '' }
  ])

  const onDeletePermission = useCallback(() => {
    if (!appId || !permissionId) return
    deletePermissionAsync({ appId, permissionId })
  }, [appId, permissionId, deletePermissionAsync])

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSIONS, { params })
  }, [params])

  const onEdit = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSION_EDIT, { params })
  }, [params])

  if (fetchingAppPermissionDetail || !permission) return <Loading />

  return (
    <>
      <Header style={{ gap: 8 }}>
        <Button styleVariants={buttonVariants} onClick={onBack}>
          <ChevronLeft size={16} />
          Back to listing
        </Button>

        <span className={title.two} style={{ paddingLeft: 4 }}>
          {permission.name}
        </span>

        <span style={{ flex: 1 }} />

        <Button
          styleVariants={{
            kind: 'neutral',
            type: 'outlined',
            size: 'small'
          }}
          onClick={onEdit}
        >
          <PenLine size={16} />
          Edit
        </Button>
      </Header>

      <PermissionInfo permission={permission} />

      <article className={container}>
        <article className={content}>
          <DangerZone type="permission">
            <DeleteDoubleConfirmPopUp
              item={{ name: permission.name, type: 'permission' }}
              onExecute={onDeletePermission}
              loading={deletingPermission}
            >
              <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                Delete Permission
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        </article>
      </article>
    </>
  )
}

export default AppPermissionDetail
