import { Tabs } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'
import { FC, PropsWithChildren } from 'react'
import { container, header as headerStyle, main } from './styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

interface IProps {
  header: string
  tabs: { label: string; value: string }[]
  currentTab: string
  onChangeTab: (value: string) => void
}

const VisitorCard: FC<PropsWithChildren<IProps>> = ({
  header,
  tabs,
  children,
  currentTab,
  onChangeTab
}) => {
  const isMounted = useIsMounted()

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <header className={headerStyle}>
        <p
          className={title.three}
          style={{ color: colors.text.light, textTransform: 'uppercase' }}
        >
          {header}
        </p>

        <article className="flex-1 flex justify-end">
          <Tabs.Root
            value={currentTab}
            onValueChange={onChangeTab}
            format={{ type: 'underlined' }}
          >
            <Tabs.List styleVariants={{ hAlign: 'right' }}>
              {tabs.map(v => (
                <Tabs.Trigger key={v.value} value={v.value}>
                  {v.label}
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </Tabs.Root>
        </article>
      </header>

      <main className={main}>{children}</main>
    </article>
  )
}

export default VisitorCard
