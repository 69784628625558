import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { putWebhookStatusToggle } from '$services/api'
import { Tabs } from '@genie-fintech/ui/components'
import { useRequest } from 'ahooks'
import { useCallback, useState } from 'react'

type Props = {
  webhookId: string
  is_enabled: boolean
}

enum STATUS {
  ON = 'on',
  OFF = 'off'
}

const WebhookStatusSwitch = ({ webhookId, is_enabled }: Props) => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const [status, setStatus] = useState(is_enabled ? STATUS.ON : STATUS.OFF)

  const { loading: updatingWebhookStatus, runAsync: updateWebhookStatusAsync } =
    useRequest(putWebhookStatusToggle, { manual: true })

  const onValueChange = useCallback(
    (value: string) => {
      if (!appId) return

      setStatus(value as STATUS)

      const is_enabled = value === STATUS.ON

      updateWebhookStatusAsync(appId, { id: webhookId, is_enabled }).catch(
        () => {
          setStatus(prev => (prev === STATUS.ON ? STATUS.OFF : STATUS.ON))
        }
      )
    },
    [updateWebhookStatusAsync, appId, webhookId]
  )

  const disabled = updatingWebhookStatus

  return (
    <Tabs.Root
      value={status}
      onValueChange={onValueChange}
      format={{ type: 'segmented' }}
    >
      <Tabs.List styleVariants={{ hAlign: 'right' }}>
        <Tabs.Trigger disabled={disabled} value={STATUS.OFF}>
          OFF
        </Tabs.Trigger>
        <Tabs.Trigger disabled={disabled} value={STATUS.ON}>
          ON
        </Tabs.Trigger>
      </Tabs.List>
    </Tabs.Root>
  )
}

export default WebhookStatusSwitch
