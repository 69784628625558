import Modal from '$components/Modal'
import { useModal } from '$components/Modal/hooks/useModal'
import { Button } from '@genie-fintech/ui/components'
import { ArrowUpRight, ChevronLeft } from 'lucide-react'
import { CSSProperties, useCallback, useState } from 'react'
import {
  copyContainer,
  endpointContainer,
  header,
  jsonViewContainer,
  main,
  samplePayloadContainer,
  title,
  webhookEventListContainer
} from './styles.css'
import WebhookEventList from '../WebhookEventList'
import { WEBHOOK_EVENT } from '$services/api'
import { status } from '@genie-fintech/ui/style/element'
import ReactJsonView from '@microlink/react-json-view'
import { useTheme } from '@genie-fintech/ui/hooks'
import CopyButton from '$components/CopyButon/v2'
import { footnote } from '@genie-fintech/ui/style/typography'
import { useWebhookEvents } from '../../useWebhookEvents'
import { modalContent } from '../../styles.css'

const jsonViewStyle: CSSProperties = {
  padding: 16,
  background: 'transparent',
  flex: 1
}

const ViewEventJson = () => {
  const {
    mode: { isDarkMode }
  } = useTheme()

  const { webhookEvents, getWebhookEventById } = useWebhookEvents()

  const [webhookId, setWebhookId] = useState<WEBHOOK_EVENT['id']>('')

  const modalProps = useModal({ isFocusMode: true, closeOnClickOutside: false })

  const { openModal, closeModal } = modalProps

  const onOpenModal = useCallback(() => {
    openModal()
    setWebhookId(webhookEvents[0]?.id)
  }, [openModal, webhookEvents])

  const onBack = useCallback(() => {
    setWebhookId('')
    closeModal()
  }, [closeModal])

  const { sample_payload, event_type } = getWebhookEventById(webhookId) ?? {}

  const jsonViewTheme = isDarkMode ? 'shapeshifter' : 'shapeshifter:inverted'

  return (
    <>
      <Button
        styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        onClick={onOpenModal}
      >
        View Events’ JSON
        <ArrowUpRight size={16} />
      </Button>

      <Modal {...modalProps} contentProps={{ className: modalContent }}>
        <header className={header}>
          <Button
            styleVariants={{
              kind: 'neutral',
              type: 'outlined',
              size: 'small',
              category: 'icon'
            }}
            onClick={onBack}
          >
            <ChevronLeft size={20} />
          </Button>
          <p className={title}>Events’ Payload Formats</p>
        </header>
        <main className={main}>
          <article className={webhookEventListContainer}>
            <WebhookEventList value={webhookId} onChange={setWebhookId} />
          </article>
          <article className={samplePayloadContainer}>
            <p className={endpointContainer}>
              <span
                className={status({
                  size: 'tiny',
                  kind: 'primary',
                  type: 'faint'
                })}
              >
                POST
              </span>
              <span> </span>
              <span className={footnote.two}>
                method to {event_type?.split('_').join(' ')} end point and the
                response will be same as...
              </span>
            </p>
            <article className={jsonViewContainer}>
              {sample_payload && (
                <ReactJsonView
                  src={JSON.parse(sample_payload)}
                  theme={jsonViewTheme}
                  indentWidth={2}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  displayArrayKey={false}
                  name={false}
                  style={jsonViewStyle}
                />
              )}

              {sample_payload && (
                <article className={copyContainer}>
                  <CopyButton value={sample_payload} />
                </article>
              )}
            </article>
          </article>
        </main>
      </Modal>
    </>
  )
}

export default ViewEventJson
