import { TAppGroupListResponse } from '$services/api'
import { Button } from '@genie-fintech/ui/components'
import { useCallback, useEffect } from 'react'
import { CopyButton } from '$components/CopyButon'
import { format } from 'date-fns'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useAppGroupService } from '$hooks/services'
import { flexColumn, flexRow } from '$styles/common.css'
import { useAppDetailStore } from '$hooks/stores'
import { useApiListingParams } from '$hooks/actions'
import DescriptionTableColumn from '$components/DescriptionTableColumn'
import Pagination from '$components/Pagination'
import { themeVars } from '@genie-fintech/ui/style/theme'
import Header from '$layouts/LayoutWithSidebar/Header'
import { innerMain, main } from './styles.css'
import { Folders, Plus } from 'lucide-react'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import SearchBox from '$components/SearchBox'
import Table, { Column } from '$components/Table'
import Link from '$router/Link'
import { tableCellLink } from '$components/Table/styles.css'
import EmptyData from '$components/EmptyData'
import { pluralize } from '$app/utils'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

type ColumnData = TAppGroupListResponse['data'][number]

const AppGroupList = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    fetchAppGroupList,
    fetchingAppGroupList,
    groups: { list: groupList, meta }
  } = useAppGroupService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppGroupList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppGroupList, page, perPage, debouncedSearchValue])

  useBreadcrumb([{ name: 'Groups' }])

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUP_CREATE, { params })
  }, [params])

  const columns: Column<ColumnData>[] = [
    {
      name: 'NO',
      render: ({ index }) => (page - 1) * perPage + index + 1
    },
    {
      name: 'NAME',
      dataIndex: 'name',
      render: ({ record }) => (
        <Link
          to={ROUTE_NAMES.APP_GROUP_DETAIL}
          options={{ params: { ...params, groupId: record.id } }}
          className={tableCellLink}
        >
          {record.name}
        </Link>
      )
    },
    {
      name: 'ID',
      dataIndex: 'id',
      render: ({ record }) => (
        <article className={flexRow} style={{ gap: 8 }}>
          {record.id}
          <CopyButton value={`${record.id}`} size={16} />
        </article>
      )
    },
    { name: 'COUNTRY', dataIndex: 'country' },
    { name: 'ROLES', dataIndex: 'role_count' },
    { name: 'USERS', dataIndex: 'user_count' },
    {
      name: 'CREATED AT',
      dataIndex: 'created_at',
      render: ({ record }) =>
        record.created_at ? format(record.created_at, 'dd MMM yyyy') : '-'
    },
    {
      name: 'DESCRIPTION',
      dataIndex: 'description',
      render: ({ record }) => (
        <DescriptionTableColumn desc={record.description} />
      )
    }
  ]

  const isEmpty = !groupList.length && !fetchingAppGroupList

  return (
    <>
      <Header style={{ gap: 8 }}>
        <Folders size={20} />

        <span className={title.two} style={{ paddingLeft: 4 }}>
          Groups
        </span>

        <span style={{ flex: 1 }} />

        <Button onClick={handleOnClickAddNew} styleVariants={{ size: 'small' }}>
          <Plus size={16} />
          Add Group
        </Button>
      </Header>

      <main className={main}>
        <article className={innerMain}>
          <header className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>{appName}'s GROUPS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Organize users into groups for streamlined management and
              permissions.
            </p>
          </header>

          <article className={flexColumn} style={{ gap: 16, flex: 1 }}>
            <article className={flexRow} style={{ gap: 4 }}>
              <article style={{ flex: 1, maxWidth: 560 }}>
                <SearchBox
                  value={searchValue}
                  onChange={updateSearchValue}
                  placeholder="Search here..."
                />
              </article>

              <span style={{ flex: 1 }} />

              {!!meta?.total && (
                <span
                  className={title.six}
                  style={{
                    color: colors.text.disabled,
                    textTransform: 'uppercase'
                  }}
                >
                  {pluralize(meta.total, 'group')}
                </span>
              )}
            </article>

            {isEmpty && (
              <EmptyData type="group" onClick={handleOnClickAddNew} />
            )}

            {!isEmpty && (
              <Table
                columns={columns}
                data={groupList}
                loading={fetchingAppGroupList}
              />
            )}

            {!!groupList.length && (
              <Pagination meta={meta} pagerProps={pagerProps} />
            )}
          </article>
        </article>
      </main>
    </>
  )
}

export default AppGroupList
