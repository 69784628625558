import { useController, useForm } from 'react-hook-form'
import { AUTH_TYPES, DEFAULT_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import {
  errorMessage,
  flexColumn,
  formDescription,
  formFooter,
  formTitle,
  formMain,
  formHeader,
  authTypeListContainer
} from './styles.css'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import WebhookEventList from '../WebhookEventList'
import { Button, Radio, Spinner } from '@genie-fintech/ui/components'
import { useRequest } from 'ahooks'
import {
  APP_WEBHOOK,
  postWebhook,
  putWebhook,
  WEBHOOK_PAYLOAD
} from '$services/api'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { toast } from 'sonner'
import ViewEventJson from '../ViewEventJson'
import { flexRow } from '$styles/common.css'
import { generatePassword } from '$app/utils'

type Props = {
  webhook?: APP_WEBHOOK
  onSuccess?: (values: TField) => void
  onCancel?: VoidFunction
}

const WebhookForm = ({ webhook, onSuccess, onCancel: _onCancel }: Props) => {
  const { route } = useRouteSummary()
  const { appId } = route.params

  const { loading: savingWebhook, runAsync: saveWebhookAsync } = useRequest(
    postWebhook,
    { manual: true }
  )

  const { loading: updatingWebhook, runAsync: updateWebhookAsync } = useRequest(
    putWebhook,
    { manual: true }
  )

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES
  })

  const { field: authTypeField } = useController({ name: 'auth_type', control })

  useEffect(() => {
    if (!webhook) return

    const { url, webhook_events, auth_type, auth_value } = webhook
    reset({
      url,
      auth_type,
      auth_value: auth_value ?? '',
      webhook_event_ids: webhook_events.map(({ id }) => id)
    })
  }, [webhook, reset])

  const onCancel = useCallback(() => {
    reset(DEFAULT_VALUES)
    _onCancel?.()
  }, [_onCancel, reset])

  const onGenereate = useCallback(() => {
    setValue('auth_value', generatePassword(20), { shouldDirty: true })
  }, [setValue])

  const onSubmit = useCallback(
    (value: TField) => {
      if (!appId) return

      const payload: WEBHOOK_PAYLOAD = {
        ...value,
        auth_value: value.auth_type === 'signature' ? value.auth_value : ''
      }

      if (webhook?.id) {
        updateWebhookAsync(appId, webhook.id, payload).then(() => {
          toast.success('Webhook has been updated!')
          onSuccess?.(payload)
        })
        return
      }

      saveWebhookAsync(appId, payload).then(async () => {
        toast.success('Webhook has been created!')
        onSuccess?.(payload)
      })
    },
    [appId, webhook, saveWebhookAsync, updateWebhookAsync, onSuccess]
  )

  const webhook_event_ids = watch('webhook_event_ids')

  const webhook_event_ids_error_message =
    errors.root?.webhook_event_ids?.message ?? errors.webhook_event_ids?.message

  const processing = savingWebhook || updatingWebhook

  const isEdit = !!webhook?.id

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <main className={formMain}>
        <header className={formHeader}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <h2 className={formTitle}>
              {isEdit ? 'Edit Webhooks URL' : 'Add New Webhooks URL'}
            </h2>
            <p className={formDescription}>
              Webhooks offer instant notifications for a range of email events,
              such as bounces, opens, and clicks, facilitating automated
              responses and improving the efficiency of email workflows.
            </p>
          </article>
          <article>
            <ViewEventJson />
          </article>
        </header>

        <Radio.Group
          asChild
          className={authTypeListContainer}
          value={authTypeField.value}
          onValueChange={authTypeField.onChange}
        >
          <main>
            {AUTH_TYPES.map(v => (
              <Radio.Item key={v.value} label={v.label} value={v.value} />
            ))}
          </main>
        </Radio.Group>

        {authTypeField.value === 'signature' && (
          <article className={flexRow} style={{ gap: 16, alignItems: 'start' }}>
            <article style={{ flex: 1 }}>
              <BaseText
                label="Secret"
                name="auth_value"
                control={control}
                required
              />
            </article>

            <article style={{ paddingTop: 28 }}>
              <Button
                styleVariants={{ type: 'text' }}
                onClick={onGenereate}
                disabled={processing}
              >
                Generate
              </Button>
            </article>
          </article>
        )}

        <BaseText
          label="Webhook URL"
          name="url"
          control={control}
          required
          inputProps={{ type: 'url' }}
        />

        {webhook_event_ids_error_message && (
          <p className={errorMessage}>{webhook_event_ids_error_message}</p>
        )}

        <WebhookEventList
          multiple
          value={webhook_event_ids}
          onChange={value => setValue('webhook_event_ids', value)}
        />
      </main>

      <footer className={formFooter}>
        <Button
          type="button"
          styleVariants={{
            kind: 'neutral',
            type: 'outlined',
            size: 'small'
          }}
          onClick={onCancel}
          disabled={processing}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          styleVariants={{ size: 'small' }}
          disabled={processing}
        >
          {processing && <Spinner />}
          {isEdit ? 'Save' : 'Add'}
        </Button>
      </footer>
    </form>
  )
}

export default WebhookForm
