import { on, onOneOf } from '$router/config'
import React from 'react'
import { DASHBOARD_USER_ROUTES } from './constants'
import UserProfileLayout from '$layouts/UserProfileLayout'
import { USER_TYPE } from '$layouts/UserProfileLayout/types'

const DashboardUserRoutes = () => {
  const isDashboardUserRoutes = onOneOf(DASHBOARD_USER_ROUTES.map(v => v.name))

  if (!isDashboardUserRoutes) return null

  return (
    <UserProfileLayout userType={USER_TYPE.DASHBOARD_USER}>
      {DASHBOARD_USER_ROUTES.map(
        ({ name, Component }) =>
          on(name) && <React.Fragment key={name}>{Component}</React.Fragment>
      )}
    </UserProfileLayout>
  )
}

export default DashboardUserRoutes
