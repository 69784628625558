import { z } from 'zod'

const trimmedString = z.string().trim()

export const schema = z.object({
  name: trimmedString.min(1, 'Required!'),
  date_of_birth: trimmedString,
  gender: z.object({
    value: trimmedString,
    label: trimmedString
  }),
  address: trimmedString
})

export type TFormValues = z.infer<typeof schema>

export const INITIAL_DEFAULT_VALUES: TFormValues = {
  name: '',
  date_of_birth: '',
  gender: { label: '', value: '' },
  address: ''
}

export enum LABEL {
  USERNAME = 'User Name',
  DOB = 'Date of Birth',
  GENDER = 'Gender',
  ADDRESS = 'Address'
}
