import { GENERAL_DURATION } from '@genie-fintech/ui/constants/transitions'
import { useEffect, useState } from 'react'
import { useModal } from './useModal'

const DELAY = GENERAL_DURATION

type Props = Pick<ReturnType<typeof useModal>, 'open'>

export const useDelayUnmount = ({ open }: Props) => {
  const [isUnmounted, setIsUnmounted] = useState(!open)

  useEffect(() => {
    if (open) {
      setIsUnmounted(false)
      return
    }

    const timer = setTimeout(() => {
      setIsUnmounted(true)
    }, DELAY)

    return () => {
      clearTimeout(timer)
    }
  }, [open])

  return isUnmounted
}
