import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { postSentTestWebhook } from '$services/api'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useRequest } from 'ahooks'
import { ArrowUpRight } from 'lucide-react'
import { useCallback } from 'react'
import { toast } from 'sonner'

type Props = {
  webhookId: string
}

const SendTestButton = ({ webhookId }: Props) => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const { loading: sendingTest, runAsync: sendTestAsync } = useRequest(
    postSentTestWebhook,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Test has been sent!')
      }
    }
  )

  const onSendTest = useCallback(() => {
    if (!appId) return
    sendTestAsync(appId, webhookId)
  }, [appId, sendTestAsync, webhookId])

  return (
    <Button
      styleVariants={{
        kind: 'neutral',
        type: 'text',
        size: 'small'
      }}
      disabled={sendingTest}
      onClick={onSendTest}
    >
      Send test
      {sendingTest ? <Spinner /> : <ArrowUpRight size={16} />}
    </Button>
  )
}

export default SendTestButton
