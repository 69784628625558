import AppLogo from '$components/AppLogo'
import { ROUTE_NAMES } from '$router/config'
import { APP_LAUNCHPAD_URL } from '$services/environments'

import AppEnvironment from '$components/AppEnvironment'
import { Copy, Folders, Link as LinkIcon, ListTree, Users2 } from 'lucide-react'
import { formatNumber, tooltipProps } from '$app/utils'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import {
  appInfo,
  appName,
  container,
  copyContainer,
  footer,
  footerIcon,
  footerItem,
  main,
  subContainer
} from './styles.css'
import { APPS_RESPONSE } from '$services/apps'
import CopyItem from './CopyItem'
import Link from '$router/Link'

type Props = {
  data: APPS_RESPONSE['data'][number]
}

const AppCard = ({ data }: Props) => {
  const isMounted = useIsMounted()

  return (
    <Link
      to={ROUTE_NAMES.APP_HOME}
      options={{ params: { appId: data.id } }}
      className={container}
      data-ready={trueOrUndefined(isMounted)}
    >
      <article className={main}>
        <article className={subContainer}>
          <AppLogo
            imgUrl={data.logo?.url}
            brand={data.brand}
            size="big"
            isPublished={data.is_published}
          />

          <article className={appInfo}>
            <p className={appName}>{data.name}</p>
            <AppEnvironment environment={data.environment} />
          </article>
        </article>

        <article className={copyContainer}>
          <CopyItem label="Client ID" value={data.client_id} Icon={Copy} />

          <CopyItem label="URL" value={APP_LAUNCHPAD_URL} Icon={LinkIcon} />
        </article>
      </article>

      <article className={footer}>
        <button className={footerItem} {...tooltipProps('Users')}>
          <Users2 size={16} className={footerIcon} />
          {formatNumber(data.user_count)}
        </button>

        <button className={footerItem} {...tooltipProps('Roles')}>
          <ListTree size={16} className={footerIcon} />
          {formatNumber(data.role_count)}
        </button>

        <button className={footerItem} {...tooltipProps('Groups')}>
          <Folders size={16} className={footerIcon} />
          {formatNumber(data.group_count)}
        </button>
      </article>
    </Link>
  )
}

export default AppCard
