import Loading from '$components/Loading'
import ModKey from '$components/ModKey'
import Pagination from '$components/Pagination'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useGlobalDashboardUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import TableRowLink from '$router/TableRowLink'
import { TGlobalDashboardUserListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  pageDescription,
  pageHeader
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { Plus } from 'lucide-react'
import { useCallback, useEffect, useRef } from 'react'
import { toClassNames } from '../../../../.@genie-fintech/ui/functions'
import {
  buttonContainer,
  container,
  headerStyle,
  mainStyle,
  searchBoxStyle,
  tableCellStyle,
  tableHeaderFilterStyle
} from './styles.css'
import { removeDashWithSpace } from '$app/utils'

const columns: {
  key: Exclude<keyof TGlobalDashboardUserListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'NAME' },
  { key: 'email', value: 'EMAIL' },
  { key: 'type', value: 'ACCOUNT TYPE' },
  { key: 'role', value: 'ACCOUNT ROLE' }
]
const GlobalDashboardUser = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fetchGlobalDashboardUserList,
    fetchingGlobalDashboardUserList,
    dashboardUsers: { list, meta }
  } = useGlobalDashboardUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    fetchGlobalDashboardUserList({
      page,
      per_page: perPage,
      q: debouncedSearchValue
    })
  }, [fetchGlobalDashboardUserList, page, perPage, debouncedSearchValue])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(e.currentTarget.value)
    },
    [updateSearchValue]
  )

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  const onAddUser = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE)
  }, [])

  return (
    <article className={container}>
      <header className={headerStyle}>
        <h3 className={pageHeader}>
          Total Dashboard Users - {meta?.total.toLocaleString()}
        </h3>
        <p className={pageDescription}>
          All the users of different admin or service roles in Carro SSO are
          here.
        </p>
      </header>
      <main className={mainStyle}>
        <article className={tableHeaderFilterStyle}>
          <div className={searchBoxStyle}>
            <BaseText
              inputRef={inputRef}
              containerProps={{ className: 'min-w-[250px]' }}
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : (
                  <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                    <span>
                      <ModKey />
                    </span>
                    <span>K</span>
                  </p>
                )
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: handleOnChange,
                placeholder: 'Search here...'
              }}
            />
          </div>
          <div className={buttonContainer}>
            <Button onClick={onAddUser}>
              <Plus size={16} />
              <span className="whitespace-nowrap">Add User</span>
            </Button>
          </div>
        </article>
        <article className={customTableContainer}>
          <main className={tableContainerInner}>
            <table className={table({ separator: 'line' })}>
              <thead className={customTableHead}>
                <tr className={tableRow}>
                  <th className={customTableCell} style={{ width: 0 }}>
                    NO
                  </th>
                  {columns.map((col, key) => (
                    <th key={key} className={customTableCell}>
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={tableBody}>
                {list.map((data, rowKey) => (
                  <TableRowLink
                    key={rowKey}
                    className={tableRow}
                    to={ROUTE_NAMES.GLOBAL_DASHBOARD_USER_INDEX}
                    options={{
                      params: { dashboardUserId: data.id }
                    }}
                  >
                    <td
                      className={toClassNames(tableCellStyle, customTableCell)}
                    >
                      {(page - 1) * perPage + rowKey + 1}
                    </td>
                    {columns.map((col, colKey) => (
                      <td
                        key={colKey}
                        className={toClassNames(
                          tableCellStyle,
                          customTableCell
                        )}
                      >
                        {(() => {
                          if (['type', 'role'].includes(col.key))
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {removeDashWithSpace(data[col.key])}
                              </span>
                            )

                          return data[col.key] ?? 'N/A'
                        })()}
                      </td>
                    ))}
                  </TableRowLink>
                ))}

                {!list.length && !fetchingGlobalDashboardUserList && (
                  <tr className={tableRow}>
                    <td
                      colSpan={columns.length + 1}
                      style={{ textAlign: 'center' }}
                      className={customTableCell}
                    >
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </main>
        </article>

        {fetchingGlobalDashboardUserList && <Loading />}

        {!!list.length && <Pagination meta={meta} pagerProps={pagerProps} />}
      </main>
    </article>
  )
}

export default GlobalDashboardUser
