import { useEffect } from 'react'
import { useModal } from './useModal'

type Props = Partial<ReturnType<typeof useModal>>

export const useFocusTrap = (props?: Props) => {
  const { open, containerRef } = { ...props }

  useEffect(() => {
    if (open && containerRef?.current) {
      const modalElement = containerRef.current

      if (!modalElement) return
      //add any focusable HTML element you want to include to this string
      const focusableElements = modalElement.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      )

      const firstElement = focusableElements[0] as HTMLElement

      const lastElement = focusableElements[
        focusableElements.length - 1
      ] as HTMLElement

      const handleTabKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }

      modalElement.addEventListener('keydown', handleTabKeyPress)

      return () => {
        modalElement.removeEventListener('keydown', handleTabKeyPress)
      }
    }
  }, [open, containerRef])
}
