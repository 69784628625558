import { Button, Spinner } from '@genie-fintech/ui/components'
import { container, innerContainer, line, modalFooter } from './styles.css'
import * as Dialog from '@radix-ui/react-dialog'
import { useRequest, useToggle } from 'ahooks'
import { useCallback } from 'react'
import {
  BaseText as BaseHookFieldText,
  Password
} from '@genie-fintech/ui/components/hook-fields'
import { useForm } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { putServiceUserEmail } from '$services/api'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { status } from '@genie-fintech/ui/style/element'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { flexColumn } from '$styles/common.css'

const { colors } = themeVars

const ChangeEmailSetting = () => {
  const isMouted = useIsMounted()

  const [modalOpen, { toggle }] = useToggle()

  const { control, handleSubmit, reset } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const updateProfilePartially = useProfileDataStore(
    state => state.updateProfilePartially
  )

  const profile = useProfileDataStore(useShallow(state => state.profile))

  const { id: userId = '', email, is_verified } = profile ?? {}

  const {
    runAsync: updateServiceUserEmail,
    loading: updatingServiceUserEmail
  } = useRequest(putServiceUserEmail, {
    manual: true,
    onSuccess: (_, [{ email }]) => {
      updateProfilePartially({ email })
      toggle()
      reset(DEFAULT_FORM_VALUES)
      toast.success('Email has been updated!')
    }
  })

  const onCancel = useCallback(() => {
    reset(DEFAULT_FORM_VALUES)
    toggle()
  }, [reset, toggle])

  const onContinue = useCallback(
    (values: TField) => {
      updateServiceUserEmail({ userId, ...values })
    },
    [updateServiceUserEmail, userId]
  )

  return (
    <>
      <article className={container} data-ready={trueOrUndefined(isMouted)}>
        <article className={innerContainer}>
          <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
            <p className={title.two}>ADD/EDIT EMAIL ADDRESS</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              You can update your email with anytime.
            </p>
          </article>

          <span
            className={status({
              size: 'tiny',
              type: 'faint',
              kind: is_verified ? 'success' : 'warning'
            })}
            style={{ textTransform: 'uppercase' }}
          >
            {is_verified ? 'Verified Email' : 'Unverified Email'}
          </span>

          <p className={title.five} style={{ color: colors.text.disabled }}>
            {email}
          </p>

          <Button
            onClick={toggle}
            styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
          >
            Change
          </Button>
        </article>
        {/* <Header
          title="ADD/EDIT EMAIL ADDRESS"
          desc="You can update your email with anytime."
        />

        <span
          className={status({
            size: 'tiny',
            type: 'faint',
            kind: is_verified ? 'success' : 'warning'
          })}
          style={{ textTransform: 'uppercase' }}
        >
          {is_verified ? 'Verified Email' : 'Unverified Email'}
        </span>

        <p className={value}>{email}</p>

        <Button
          onClick={toggle}
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
        >
          Change
        </Button> */}
      </article>

      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[500px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            <form onSubmit={handleSubmit(onContinue)}>
              <main className={flexColumn} style={{ padding: 32, gap: 24 }}>
                <article className={flexColumn} style={{ gap: 4, flex: 1 }}>
                  <p className={title.two}>
                    {email ? 'ENTER NEW EMAIL' : 'ADD EMAIL'}
                  </p>
                  <p
                    className={footnote.one}
                    style={{ color: colors.neutral[60] }}
                  >
                    {email
                      ? 'Enter new email to continue the changes.'
                      : 'Enter email to continue the process.'}
                  </p>
                </article>

                <article className={flexColumn} style={{ gap: 32 }}>
                  <Password
                    name="password"
                    control={control}
                    inputProps={{ autoComplete: 'new-password' }}
                    label="Password"
                    description="Enter your login password to confirm the changes."
                    required
                  />

                  <hr className={line} />

                  <article className={flexColumn} style={{ gap: 16 }}>
                    {email && (
                      <BaseText
                        label="Current Email"
                        inputProps={{ defaultValue: email ?? '' }}
                        disabled
                        required
                      />
                    )}

                    <BaseHookFieldText
                      name="email"
                      control={control}
                      label="New Email"
                      required
                    />
                  </article>
                </article>
              </main>

              <footer className={modalFooter}>
                <Button
                  type="button"
                  styleVariants={{
                    kind: 'neutral',
                    type: 'outlined',
                    size: 'small'
                  }}
                  onClick={onCancel}
                  disabled={updatingServiceUserEmail}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styleVariants={{ size: 'small' }}
                  disabled={updatingServiceUserEmail}
                >
                  {updatingServiceUserEmail && <Spinner />}
                  Continue
                </Button>
              </footer>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default ChangeEmailSetting
