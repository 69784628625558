import { useRouteSummary } from '$contexts/RouteContext/hooks'
import Link from '$router/Link'
import { MENU_GROUP } from '../../types'
import {
  activeIndicator,
  navMenu,
  navMenuContainer,
  navMenuGroup,
  navMenuTitle
} from './styles.css'

const SideMenu = ({ menus }: { menus: MENU_GROUP }) => {
  const { route } = useRouteSummary()

  const { params, queryParams, hash } = route

  return (
    <article className={navMenuContainer}>
      {menus.map((v, k) => {
        return (
          <article key={k} className={navMenuGroup}>
            <p className={navMenuTitle}>{v.title}</p>

            <article>
              {v.menus.map(menu => {
                const { Icon, path, label } = menu

                const isActive = route.name === path

                return (
                  <Link
                    key={menu.label}
                    to={path}
                    options={{ params, queryParams, hash }}
                    className={navMenu({ isActive })}
                  >
                    <Icon size={20} />
                    <span>{label}</span>
                    <span className={activeIndicator} />
                  </Link>
                )
              })}
            </article>
          </article>
        )
      })}
    </article>
  )
}

export default SideMenu
