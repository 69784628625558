import { APP_WEBHOOK_AUTH_TYPE } from '$services/api'
import { z } from 'zod'

export const schema = z
  .object({
    url: z.string().trim().min(1, 'Required!').url('Invalid URL!'),
    auth_type: z.string(),
    auth_value: z.string().trim(),
    webhook_event_ids: z.string().array().min(1, 'At least 1 event required!')
  })
  .superRefine((val, ctx) => {
    const currentAuthType = val.auth_type as APP_WEBHOOK_AUTH_TYPE
    if (currentAuthType === 'signature' && val.auth_value.length < 10) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'At least 10 characters required!',
        path: ['auth_value']
      })
    }
  })

export type TField = Omit<z.infer<typeof schema>, 'auth_type'> & {
  auth_type: APP_WEBHOOK_AUTH_TYPE
}

export const DEFAULT_VALUES: TField = {
  url: '',
  auth_type: 'app_signature',
  auth_value: '',
  webhook_event_ids: []
}

export const AUTH_TYPES: { value: APP_WEBHOOK_AUTH_TYPE; label: string }[] = [
  { value: 'app_signature', label: 'Default' },
  { value: 'signature', label: 'Signature' }
]
