import { Avatar } from '@genie-fintech/ui/components'
import {
  badgeContainer,
  container,
  email as emailStyle,
  emailVerifyBadge,
  flexColumn,
  flexRow,
  name as nameStyle,
  userId as userIdStyle,
  userIDCard
} from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { Info } from 'lucide-react'
import VerfiedBadge from '$assets/svg/VerfiedBadge'
import { footnote } from '@genie-fintech/ui/style/typography'
import CopyButton from '$components/CopyButon/v2'
import { useProfileDataStore } from '../../hooks/useProfileDataStore'
import { useShallow } from 'zustand/react/shallow'
import { status } from '@genie-fintech/ui/style/element'

const ProfileCard = () => {
  const profile = useProfileDataStore(useShallow(state => state.profile))

  return (
    <article className={container}>
      <article className={flexColumn} style={{ gap: 12 }}>
        <article className={flexRow} style={{ gap: 8 }}>
          <Avatar size={32} status={'online'} />

          <article>
            <p className={nameStyle}>{profile?.name}</p>
            <p className={emailStyle}>{profile?.email}</p>
          </article>
        </article>

        <article className={badgeContainer}>
          {!!profile?.status && (
            <span
              className={status({
                type: 'faint',
                kind: profile.status === 'active' ? 'success' : 'grey',
                size: 'tiny'
              })}
              style={{ textTransform: 'uppercase' }}
            >
              {profile.status}
            </span>
          )}

          {!!profile?.user_type && (
            <span
              className={status({
                type: 'faint',
                kind:
                  profile.user_type === 'internal' ? 'secondary' : 'primary',
                size: 'tiny'
              })}
              style={{ textTransform: 'uppercase' }}
            >
              {profile.user_type}
            </span>
          )}

          <span style={{ flex: 1 }} />

          <span
            className={emailVerifyBadge}
            data-email-verified={trueOrUndefined(profile?.is_verified)}
          >
            {profile?.is_verified ? <VerfiedBadge /> : <Info size={16} />}

            {profile?.is_verified ? 'Email verified.' : 'Unverified email!'}
          </span>
        </article>
      </article>

      <article className={userIDCard}>
        <span className={footnote.two}>User ID</span>

        <span className={userIdStyle}>{`#${profile?.id}`}</span>

        <CopyButton
          size={16}
          value={`${profile?.id ?? ''}`}
          color="text.neutral"
        />
      </article>
    </article>
  )
}

export default ProfileCard
