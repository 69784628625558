import ModKey from '$components/ModKey'
import { useFocusEventLister } from '$hooks/actions'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import { useRef } from 'react'
import { postFixTextStyle } from './styles.css'

interface ISearchBoxProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const SearchBox = ({
  value,
  onChange,
  placeholder = 'Search'
}: ISearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  return (
    <BaseText
      inputRef={inputRef}
      elementControlProps={{ style: { padding: '9px 12px' } }}
      fullWidth
      affix={{
        pre: <Icon namespace="Search" width={16} />,
        post: value ? (
          <button onClick={() => onChange('')}>
            <Icon namespace="Cross" width={18} color="text.light" />
          </button>
        ) : (
          <p className={postFixTextStyle}>
            <ModKey /> K
          </p>
        )
      }}
      inputProps={{
        type: 'text',
        value: value,
        onChange: e => onChange(e.currentTarget.value),
        placeholder
      }}
    />
  )
}

export default SearchBox
