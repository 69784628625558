import { FC, PropsWithChildren } from 'react'
import { container } from './styles.css'
import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { TagsNoRef } from '@genie-fintech/ui/types'

type Props = TagsNoRef<'footer'> & {
  fullWidth?: boolean
}

const FooterAction: FC<PropsWithChildren<Props>> = ({
  fullWidth = true,
  children,
  ...props
}) => {
  const isMounted = useIsMounted()

  return (
    <footer
      data-ready={trueOrUndefined(isMounted)}
      data-full-width={trueOrUndefined(fullWidth)}
      children={children}
      {...propsWithClassNames(props, container)}
    />
  )
}

export default FooterAction
