import { Logo } from '$assets/svg'
import { onOneOf } from '$router/config'
import Link from '$router/Link'
import { Menu } from 'lucide-react'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { MainSidebarMenu } from './constants'
import { usePermissions } from '$hooks/actions'
import { flexColumn, flexRow } from '$styles/common.css'
import {
  drawerContent,
  menuToggleButtonStyle,
  NavLinkStyle
} from './styles.css'
import { useBoolean } from 'ahooks'
import Drawer from '$components/Drawer'

const { colors } = themeVars

const SidebarToggleMenu = () => {
  const [open, { setTrue: setOpenTrue, setFalse: setOpenFalse, set: setOpen }] =
    useBoolean(false)

  const { checkPermissions } = usePermissions()

  return (
    <>
      <button className={menuToggleButtonStyle} onClick={setOpenTrue}>
        <Menu size={20} color={colors.absolute.light} />
      </button>

      <Drawer
        direction="left"
        open={open}
        onOpenChange={setOpen}
        width={230}
        className={drawerContent}
      >
        <header className={flexRow} style={{ padding: '16px 18px' }}>
          <Logo color="neutral.default" />
        </header>
        <main className={flexColumn} style={{ padding: '0 8px' }}>
          {MainSidebarMenu.filter(menu =>
            checkPermissions(menu.permissions)
          ).map((menu, key) => {
            const Icon = menu.Icon
            const isActive = onOneOf([menu.to, ...(menu.subPath ?? [])])
            return (
              <Link
                key={key}
                to={menu.to}
                onClick={setOpenFalse}
                className={NavLinkStyle({ isActive })}
              >
                <Icon size={18} />
                <p>{menu.name}</p>
              </Link>
            )
          })}
        </main>
      </Drawer>
    </>
  )
}

export default SidebarToggleMenu
