import {
  Api,
  APIWithPager,
  APIWithPagerMeta,
  APIWithSearch,
  successResolver
} from './api'
import { APP_API_VERSION } from './environments'

export type APP_LOGO = Partial<{
  key: string | null
  url: string
}> | null

export type APP_BRAND = 'captain' | 'carro' | 'genie'

export type APPS_REQUEST = APIWithPager<APIWithSearch>

export type APPS_RESPONSE = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    client_id: string
    logo: APP_LOGO
    brand: APP_BRAND
    is_published: boolean
    user_count: number
    role_count: number
    group_count: number
    environment: {
      key: number
      value: string
    } | null
  }[]
}>

export const getApplications = async (params: APPS_REQUEST) => {
  return Api.get<APPS_RESPONSE>(`/${APP_API_VERSION}/applications`, {
    params
  }).then(successResolver)
}
