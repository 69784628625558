import { ROUTE_NAMES } from '$router/config'
import GlobalDashboardUserAccessApps from '$pages/GlobalDashboardUser/AccessApps'
import GlobalDashboardPasswordSetting from '$pages/GlobalDashboardUser/PasswordSetting'
import GlobalDashboardUserInfo from '$pages/GlobalDashboardUser/UserInfo'

export const DASHBOARD_USER_ROUTES: {
  name: ROUTE_NAMES
  Component: JSX.Element
}[] = [
  {
    name: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ACCESS_APPS,
    Component: <GlobalDashboardUserAccessApps />
  },
  {
    name: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL,
    Component: <GlobalDashboardUserInfo />
  },
  {
    name: ROUTE_NAMES.GLOBAL_DASHBOARD_USER_PASSWORD_SETTING,
    Component: <GlobalDashboardPasswordSetting />
  }
]
