import { flexRow } from '$styles/common.css'
import { History } from 'lucide-react'
import { button, buttonIcon, buttonText } from './styles.css'

import { useState } from 'react'
import DrawerContent from './DrawerContent'
import Drawer from '$components/Drawer'

const AppAuditLog = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <article className={flexRow} style={{ padding: 16 }}>
        <button className={button} onClick={() => setOpen(true)}>
          <History size={20} className={buttonIcon} />
          <span className={buttonText}>Audit Log</span>
        </button>
      </article>

      <Drawer
        dismissible={false}
        direction="right"
        open={open}
        onOpenChange={setOpen}
        title="AUDIT LOG"
        withCloseButton
      >
        <DrawerContent />
      </Drawer>
    </>
  )
}

export default AppAuditLog
