import { useRoute } from '$contexts/RouteContext/hooks'
import { MENU_ITEM } from '$layouts/LayoutWithSidebar/constants'
import Link from '$router/Link'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import {
  container,
  icon,
  indicator,
  linkName,
  subContainer
} from './styles.css'

type Props = {
  menu: MENU_ITEM
}

const MenuLink = ({ menu }: Props) => {
  const { name: menuName, path, Icon, subPath = [] } = menu

  const { params, queryParams, hash, name: routeName } = useRoute()

  const active = routeName && [path, ...subPath].includes(routeName)

  return (
    <article className={container} data-active={trueOrUndefined(active)}>
      <Link
        className={subContainer}
        to={path}
        options={{ params, queryParams, hash }}
      >
        <Icon size={20} className={icon} />

        <span className={linkName({ active })}>{menuName}</span>
      </Link>

      <span className={indicator} />
    </article>
  )
}

export default MenuLink
