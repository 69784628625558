import { APIWithPagerMeta } from '$services/api'
import { flexRow } from '$styles/common.css'
import { Spinner } from '@genie-fintech/ui/components'
import { useInViewport } from 'ahooks'
import { FC, useRef } from 'react'

type Props = {
  loading: boolean
  onTrigger: (page: number) => void
  meta?: APIWithPagerMeta['meta']
}

const ScrollPagination: FC<Props> = ({ loading, onTrigger, meta }) => {
  const targetRef = useRef(null)

  const { current_page = 1, last_page = 1 } = meta ?? {}

  useInViewport(targetRef, {
    callback: entry => {
      if (loading || !entry.isIntersecting) return

      onTrigger?.(Math.min(current_page + 1, last_page))
    }
  })

  const isEmpty = !meta || !meta.total

  const isLastPage = !isEmpty && meta.to === meta.total

  if (isEmpty || isLastPage) return null

  return (
    <article
      className={flexRow}
      style={{ padding: '80px 0', justifyContent: 'center' }}
    >
      <span ref={targetRef}>
        <Spinner />
      </span>
    </article>
  )
}

export default ScrollPagination
