import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import {
  tableBodyCell,
  tableContainer,
  tableHead,
  tableHeadCell
} from './styles.css'
import { ReactNode } from 'react'
import Loading from '$components/Loading'
import { flexRow } from '$styles/common.css'
import { headline } from '@genie-fintech/ui/style/typography'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { toClassNames, trueOrUndefined } from '@genie-fintech/ui/functions'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

export interface Column<T> {
  name: string
  dataIndex?: keyof T
  className?: string
  render?: ({
    value,
    index,
    record
  }: {
    value: T[keyof T] | undefined
    index: number
    record: T
  }) => React.ReactNode
}

export interface Props<T> {
  columns: Column<T>[]
  data: T[]
  loading?: boolean
  // onRowClicked?: (row: T) => void;
}

export const Table = <T = object & { no?: number },>({
  data,
  columns,
  loading
}: Props<T>) => {
  const isMounted = useIsMounted()

  const isEmpty = data.length === 0 && !loading

  return (
    <article className={tableContainer} data-ready={trueOrUndefined(isMounted)}>
      {loading && <Loading />}

      <main className={tableContainerInner}>
        <table className={table({ separator: 'line' })}>
          <thead className={tableHead}>
            <tr className={tableRow}>
              {columns.map(column => (
                <th
                  key={column.name}
                  className={toClassNames(tableHeadCell, column.className)}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={tableBody}>
            {data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={tableRow}
                // onClick={() => onRowClicked && onRowClicked(row)}
              >
                {columns.map((column, columnIndex) => {
                  const value = column.dataIndex
                    ? row[column.dataIndex]
                    : undefined
                  return (
                    <td
                      key={columnIndex}
                      className={toClassNames(tableBodyCell, column.className)}
                    >
                      {column.render
                        ? column.render({ value, index: rowIndex, record: row })
                        : (value as ReactNode)}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </main>

      {isEmpty && (
        <article
          className={flexRow}
          style={{
            padding: '20px 0',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <p className={headline.five} style={{ color: colors.text.disabled }}>
            No Data
          </p>
        </article>
      )}
    </article>
  )
}

export default Table
