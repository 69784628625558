import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService, useDeleteService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import { ChevronLeft, Trash2 } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback, useRef } from 'react'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import Header from '$layouts/LayoutWithSidebar/Header'
import { title } from '@genie-fintech/ui/style/typography'
import { ButtonVariants } from '@genie-fintech/ui/style/element'
import HeaderDropdownAction from './HeaderDropdownAction'
import { container } from './styles.css'
import UserInfo from './UserInfo'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const buttonVariants: ButtonVariants = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small'
}

const AppUserDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const terminateButtonRef = useRef<HTMLDivElement>(null)

  const { appId, userId } = params

  const { user, fetchAppUserDetail, fetchingAppUserDetail } =
    useAppUserService()

  const { terminateAppUserAsync, terminatingAppUser } = useDeleteService()

  const isUserActive = user?.status === 'active'

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetail({
      application_id: appId,
      user_id: userId
    })
  })

  useBreadcrumb([
    { name: 'Uses', path: ROUTE_NAMES.APP_USERS },
    { name: user?.name ?? '' }
  ])

  const onTerminateUser = useCallback(() => {
    if (!appId || !userId) return
    terminateAppUserAsync({ appId, userId })
  }, [appId, userId, terminateAppUserAsync])

  const onAssignRoles = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USER_ASSIGN_ROLES, { params })
  }, [params])

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USERS, { params })
  }, [params])

  if (fetchingAppUserDetail || !user) return <Loading />

  return (
    <>
      {/* <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_USERS },
          { name: 'User Details' }
        ]}
      /> */}

      <Header style={{ gap: 8 }}>
        <Button styleVariants={buttonVariants} onClick={onBack}>
          <ChevronLeft size={16} />
          Back to listing
        </Button>

        <span className={title.two} style={{ paddingLeft: 4 }}>
          {user.name}
        </span>

        <span style={{ flex: 1 }} />

        <Button
          styleVariants={{
            kind: 'neutral',
            type: 'outlined',
            size: 'small'
          }}
          onClick={onAssignRoles}
        >
          Assign Roles
        </Button>

        <HeaderDropdownAction
          onTerminatUser={() => terminateButtonRef.current?.click()}
        />
      </Header>

      <UserInfo user={user} />

      <article className={container}>
        <article className={content}>
          <DangerZone type="app_user">
            <DeleteDoubleConfirmPopUp
              ref={terminateButtonRef}
              item={{ type: 'app_user', name: user.name }}
              onExecute={onTerminateUser}
              loading={terminatingAppUser}
              disabled={!isUserActive}
            >
              <Button
                styleVariants={{ kind: 'danger', type: 'text' }}
                disabled={!isUserActive}
              >
                {isUserActive ? 'Terminate User' : 'User Terminated'}
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        </article>
      </article>
    </>
  )
}

export default AppUserDetail
