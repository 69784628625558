import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppDetailStore } from '$hooks/stores'
import { getAppBasicSetting } from '$services/api'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useRequest } from 'ahooks'
import { FC, PropsWithChildren, useEffect } from 'react'

const AppDetail: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted()

  const { route } = useRouteSummary()
  const { appId } = route.params

  const updateAppBasicSettingStore = useAppDetailStore(
    state => state.updateAppDetail
  )

  const { run, loading } = useRequest(getAppBasicSetting, {
    manual: true,
    onSuccess: ({ data }) => {
      updateAppBasicSettingStore(data)
    }
  })

  useEffect(() => {
    if (!appId) return
    run({ application_id: appId })
  }, [appId, run])

  if (loading || !isMounted) return <Loading />

  return <>{children}</>
}

export default AppDetail
