import { MainSidebarMenu } from '$components/SideBarToggleMenu/constants'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { container, item } from './styles.css'
import Link from '$router/Link'
import { useBreadcrumbStore } from './hooks/useBreadcrumbStore'
import { ChevronRight } from 'lucide-react'

const Breadcrumb = () => {
  const { route } = useRouteSummary()

  const breadcrumb = useBreadcrumbStore(state => state.breadcrumb)

  const mainMenu = MainSidebarMenu.find(menu =>
    [menu.to, ...menu.subPath].includes(route.name!)
  )

  if (!mainMenu) return null

  const Icon = mainMenu.Icon

  return (
    <article className={container}>
      <Link to={mainMenu.to} className={item}>
        <Icon size={20} />
        <span>{mainMenu.name}</span>
      </Link>

      {breadcrumb.map((v, k) => {
        return v.path ? (
          <Link
            key={k}
            to={v.path}
            options={{ params: route.params }}
            className={item}
          >
            <ChevronRight size={16} />
            <span>{v.name}</span>
          </Link>
        ) : (
          <span key={k} className={item}>
            <ChevronRight size={16} />
            <span>{v.name}</span>
          </span>
        )
      })}
    </article>
  )
}

export default Breadcrumb
