import { description, flexColumn } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { capitalize } from 'lodash-es'
import { Plus } from 'lucide-react'
import { buttonContainerStyle, container } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { title } from '@genie-fintech/ui/style/typography'

const { colors } = themeVars

interface IEmptyDataProps {
  type: string
  onClick?: VoidFunction
}

const EmptyData = ({ type, onClick }: IEmptyDataProps) => {
  const isMounted = useIsMounted()

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      {onClick && (
        <button onClick={onClick} className={buttonContainerStyle}>
          <Plus size={32} />
          <span className="font-medium text-sm">
            Add New {capitalize(type)}
          </span>
        </button>
      )}

      <article className={flexColumn} style={{ gap: 8, alignItems: 'center' }}>
        <p className={title.one} style={{ color: colors.neutral[70] }}>
          You don't have any {type} yet.
        </p>

        <p className={description}>No Items have been added to this section</p>
      </article>
    </article>
  )
}

export default EmptyData
