import { cn } from '$app/utils'
import { forwardRef } from 'react'
import ReactSelect, { MenuPlacement, SingleValue } from 'react-select'

type TOptions = { value: string; label: string }[]

interface ISelectProps {
  value: TOptions[number]
  options: TOptions
  onChange?: (value: SingleValue<TOptions[number]>) => void
  error?: boolean
  disabled?: boolean
  type?: 'sub'
  menuPlacement?: MenuPlacement
}

const Select = forwardRef(
  (
    {
      value,
      options,
      onChange,
      error,
      type,
      disabled,
      menuPlacement = 'auto'
    }: ISelectProps,
    ref
  ) => {
    return (
      <ReactSelect
        ref={ref as never}
        value={value}
        options={options}
        isDisabled={disabled}
        onChange={onChange}
        menuPlacement={menuPlacement}
        placeholder={type === 'sub' ? '' : undefined}
        classNames={{
          control: () =>
            cn(
              '!bg-[--colors-area-high] !shadow-none',
              error && '!border-[--colors-danger-default]',
              disabled && '!bg-[--colors-alphaArea-disabled]',
              type === 'sub' && '!border-0 !bg-transparent hover:!border-0'
            ),
          singleValue: () =>
            cn(
              '!text-sm',
              disabled
                ? '!text-[--colors-text-disabled]'
                : '!text-[--colors-text-default]'
            ),
          menu: () => '!bg-[--colors-area-low] !text-xs !z-[10000]',
          option: () =>
            '!bg-[--colors-area-low] hover:!bg-[--colors-neutral-20] !text-[--colors-text-default] !z-[10000]'
        }}
      />
    )
  }
)

export default Select
