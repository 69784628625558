import { screenSizes } from '$styles/media'
import { useResponsive as useAHooksResponsive } from 'ahooks'

const useResponsive = () => {
  const responsive = useAHooksResponsive() as Record<
    keyof typeof screenSizes,
    boolean
  >
  return responsive
}

export default useResponsive
