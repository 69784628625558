import { useAuthContext } from '$contexts/AuthContext/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  avatar,
  chevronStyle,
  ProfileButtonStyle,
  ProfileContentStyle,
  profileInfo
} from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { cn } from '$app/utils'
import { borderNeutral20 } from '$styles/common.css'
import { LogOut, User2 } from 'lucide-react'
import { useCallback, useState } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useRequest } from 'ahooks'
import { logout } from '$services/api'
import { toast } from 'sonner'
import { Avatar } from '@genie-fintech/ui/components'

const { colors } = themeVars

const Profile = () => {
  const { user, updateToken } = useAuthContext()

  const [menuOpen, setMenuOpen] = useState(false)

  const logoutRequest = useRequest(logout, {
    manual: true,
    onSuccess: () => {
      updateToken(undefined)
      toast.success(`You've logged out!`)
      setMenuOpen(false)
    }
  })

  const goToProfile = useCallback(() => {
    if (!user?.id) return
    setMenuOpen(false)
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, {
      params: { dashboardUserId: user.id }
    })
  }, [user?.id])

  if (!user) return null

  return (
    <DropdownMenu.Root open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenu.Trigger asChild>
        <button className={ProfileButtonStyle}>
          <article className={avatar}>
            <Icon namespace="User" color="absolute.light" width={16} />
          </article>

          <article className={profileInfo}>
            <p className="text-sm font-medium truncate">{user.name}</p>
            <p className="text-xs capitalize">{user.role}</p>
          </article>

          <Icon
            namespace="Down"
            color="absolute.light"
            width={18}
            className={chevronStyle}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={ProfileContentStyle}>
          <article className="flex p-4 items-center gap-1.5">
            <Avatar status="online" size={32} />

            <article className="flex flex-col text-left">
              <p
                className="text-sm font-medium"
                style={{ color: colors.text.light }}
              >
                {user.name}
              </p>
              <p className="text-xs" style={{ color: colors.neutral[60] }}>
                {user.email}
              </p>
            </article>
          </article>

          <button
            className={cn(
              'flex w-full items-center px-5 py-4 border-x-0 gap-2',
              borderNeutral20
            )}
            onClick={goToProfile}
            style={{ color: colors.text.light }}
          >
            <User2 size={20} />
            <span className="text-sm font-medium">Your Profile</span>
          </button>

          <button
            className={'flex items-center px-5 py-4 gap-2'}
            style={{ color: colors.text.light }}
            onClick={logoutRequest.run}
          >
            <LogOut size={20} />
            <span className="text-sm font-medium">Log Out</span>
          </button>

          <article
            className="flex flex-col px-5 py-4 gap-0.5"
            style={{ background: colors.alphaArea.disabled }}
          >
            <p
              className="text-xs font-medium"
              style={{ color: colors.neutral[60] }}
            >
              User's Role
            </p>

            <p
              className="text-sm font-semibold capitalize"
              style={{ color: colors.text.light }}
            >
              {user.role}
            </p>
          </article>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Profile
