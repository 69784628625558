import { APP_BRANDS } from '$app/constants'
import { useCallback, useEffect, useState } from 'react'
import {
  Container,
  containerBackgroundColor,
  containerSize,
  imageSize,
  ImageStyle,
  Indicator
} from './styles.css'
import { Size } from './types'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { SIZE } from './constants'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

const { container, image } = SIZE

interface IProps {
  imgUrl?: string
  brand?: string
  isPublished?: boolean
  size?: Size
}

const AppLogo = ({ imgUrl, brand, isPublished, size = 'small' }: IProps) => {
  const [url, setUrl] = useState('')
  const [background, setBackground] = useState<string>('')

  const [loading, setLoading] = useState(true)

  const { Icon: brandIcon = '', backgroundColor: brandBGColor = '' } =
    APP_BRANDS.find(d => d.name === brand) ?? {}

  useEffect(() => {
    setUrl(imgUrl ? imgUrl : brandIcon)
    setBackground(imgUrl ? colors.area.disabled : brandBGColor)
  }, [imgUrl, brandIcon, brandBGColor])

  const onError = useCallback(() => {
    setUrl(brandIcon)
    setBackground(brandBGColor)
  }, [brandIcon, brandBGColor])

  const onLoad = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <article
      className={Container}
      style={assignInlineVars({
        [containerSize]: `${container[size]}px`,
        [containerBackgroundColor]: background
      })}
    >
      <img
        src={url}
        data-loading={trueOrUndefined(loading)}
        className={ImageStyle}
        style={{ ...assignInlineVars({ [imageSize]: `${image[size]}px` }) }}
        onError={onError}
        onLoad={onLoad}
      />

      {isPublished && (
        <span className={Indicator}>
          <span />
        </span>
      )}
    </article>
  )
}

export default AppLogo
