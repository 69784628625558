import { WEBHOOK_EVENT_BY_CATEGORY } from '$services/api'
import { create } from 'zustand'

interface IWebhookEventStore {
  webhookEventsByCategory: WEBHOOK_EVENT_BY_CATEGORY[]
  updateWebhookEvents: (
    webhookEventsByCategory: IWebhookEventStore['webhookEventsByCategory']
  ) => void
}

export const useWebhookEventStore = create<IWebhookEventStore>()(set => ({
  webhookEventsByCategory: [],
  updateWebhookEvents: webhookEventsByCategory =>
    set({ webhookEventsByCategory })
}))
