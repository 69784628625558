import { FC, useCallback, useEffect } from 'react'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { title } from '@genie-fintech/ui/style/typography'
import Drawer from '$components/Drawer'
import { getAppPermissionListByRoleId, TID } from '$services/api'
import { flexColumn } from '$styles/common.css'
import { drawerContent, main } from './styles.css'
import SearchBox from '$components/SearchBox'
import { useApiListingParams } from '$hooks/actions'
import { pluralize } from '$app/utils'
import { useRequest } from 'ahooks'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import Pagination from '$components/Pagination'
import Table, { Column } from '$components/Table'
import ActionLink from '$components/Table/ActionLink'
import { ROUTE_NAMES } from '$router/config'

const { colors } = themeVars

type Props = {
  role?: { id: TID; name: string }
  appId?: TID
  onClose: VoidFunction
}

type Permission = Awaited<
  ReturnType<typeof getAppPermissionListByRoleId>
>['data'][number]

const PreviewPermissionDrawer: FC<Props> = ({ role, appId, onClose }) => {
  const { id: roleId, name: roleName } = role ?? {}

  const {
    run: fetchAppPermissionListByRoleId,
    loading: fetchingAppPermissionListByRoleId,
    data
  } = useRequest(getAppPermissionListByRoleId, {
    manual: true
  })

  const { data: permissions = [], meta } = data ?? {}

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  console.log('PreviewPermissionDrawer')

  useEffect(() => {
    if (!appId || !roleId) return
    fetchAppPermissionListByRoleId({
      page,
      per_page: perPage,
      application_id: appId,
      roleId,
      q: debouncedSearchValue
    })
  }, [
    appId,
    debouncedSearchValue,
    fetchAppPermissionListByRoleId,
    page,
    perPage,
    roleId
  ])

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (open) return
      onClose()
    },
    [onClose]
  )

  const isEmpty = !permissions.length && !fetchingAppPermissionListByRoleId

  const hasData = !!permissions.length && !fetchingAppPermissionListByRoleId

  const columns: Column<Permission>[] = [
    {
      name: 'No',
      render: ({ index }) => (page - 1) * perPage + index + 1
    },
    {
      name: 'Name',
      dataIndex: 'name'
    },
    {
      name: 'Module Name',
      dataIndex: 'module'
    },
    {
      name: '',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_PERMISSION_DETAIL}
          options={{
            params: { appId, permissionId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <Drawer
      open={!!roleId}
      onOpenChange={onOpenChange}
      className={drawerContent}
    >
      <header className={flexColumn} style={{ gap: 4 }}>
        <p className={title.two}>
          {[roleName ? `${roleName}'s` : '', 'PERMISSIONS']
            .filter(Boolean)
            .join(' ')}
        </p>
      </header>

      <main className={main}>
        <article className={flexColumn} style={{ gap: 16 }}>
          <SearchBox
            value={searchValue}
            onChange={updateSearchValue}
            placeholder="Search here..."
          />

          {hasData && (
            <p
              className={title.six}
              style={{
                color: colors.text.disabled,
                textTransform: 'uppercase'
              }}
            >
              {pluralize(meta?.total, 'permission')}
            </p>
          )}
        </article>

        {fetchingAppPermissionListByRoleId && <Loading />}

        {isEmpty && <EmptyData type="permission" />}

        {hasData && (
          <>
            <Table
              data={permissions}
              loading={fetchingAppPermissionListByRoleId}
              columns={columns}
            />

            <Pagination meta={meta} pagerProps={pagerProps} />
          </>
        )}
      </main>
    </Drawer>
  )
}

export default PreviewPermissionDrawer
