import PopupModal from '$components/PopupModal'
import { useGlobalServiceUserService, useUserService } from '$hooks/services'
import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { flexColumn } from '$styles/common.css'
import { Button, Checkbox } from '@genie-fintech/ui/components'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useBoolean, useMount } from 'ahooks'
import { Ban, ShieldCheck, ShieldClose } from 'lucide-react'
import { useCallback } from 'react'
import { innerContainer, shield } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { useShallow } from 'zustand/react/shallow'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalServiceUserMFASetting = () => {
  const [open, { setTrue, setFalse }] = useBoolean()

  const {
    fetchServiceUserMFASetting,
    fetchingServiceUserMFASetting,
    user_mfa,
    enforceMFAAsync,
    enforcingMFA
  } = useGlobalServiceUserService()

  const { updateUserStatusAsync, updatingUserStatus } = useUserService()

  const profile = useProfileDataStore(useShallow(state => state.profile))

  const { name, status, id: userId } = profile ?? {}

  const { mfa_enforced = false } = user_mfa ?? {}

  useMount(() => {
    if (!userId) return
    fetchServiceUserMFASetting({ userId })
  })

  useBreadcrumb([{ name: 'Multi-Factor Authentication' }])

  const onMFACheckboxChange = useCallback(() => {
    setTrue()
  }, [setTrue])

  const onConfirm = useCallback(() => {
    if (!userId) return
    enforceMFAAsync(userId, { enforce_mfa: !mfa_enforced })
      .then(() => {
        fetchServiceUserMFASetting({ userId })
      })
      .then(setFalse)
  }, [
    enforceMFAAsync,
    userId,
    mfa_enforced,
    fetchServiceUserMFASetting,
    setFalse
  ])

  const onTerminateUser = useCallback(() => {
    if (!userId) return
    updateUserStatusAsync({
      isAdmin: false,
      userId: userId,
      status: 'inactive'
    })
  }, [userId, updateUserStatusAsync])

  const isUserActive = status === 'active'

  if (!user_mfa) return <Loading />

  return (
    <>
      <PopupModal open={open} onConfirm={onConfirm} onClose={setFalse} />

      <MainHeader
        title="MULTI-FACTOR AUTHENTICATION"
        desc="It provides more than one form of authentication to access an account or application."
      />

      <article
        className={mainContent}
        style={{ borderBottom: `1px solid ${colors.neutral[10]}` }}
      >
        <article className={innerContainer}>
          <article className={flexColumn} style={{ flex: 1, gap: 4 }}>
            <p className={title.two}>TURN ON/OFF MULTI-FACTOR AUTHENTICATION</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              Prevent hackers from accessing your account with an additional
              layer of security.
            </p>
          </article>

          <Checkbox
            label="Turn on multi-factor authentication"
            boxProps={{
              checked: !!mfa_enforced,
              onCheckedChange: onMFACheckboxChange
            }}
            disabled={enforcingMFA || fetchingServiceUserMFASetting}
          />

          <article
            className={shield}
            data-active={trueOrUndefined(mfa_enforced)}
          >
            {mfa_enforced ? (
              <ShieldCheck size={24} />
            ) : (
              <ShieldClose size={24} />
            )}
          </article>
        </article>
      </article>

      <article className={mainContent}>
        <article className={innerMainContent}>
          <DangerZone type="user">
            <DeleteDoubleConfirmPopUp
              item={{ name: name ?? '', type: 'user' }}
              onExecute={onTerminateUser}
              loading={updatingUserStatus}
              disabled={!isUserActive}
            >
              <Button
                styleVariants={{
                  kind: 'danger',
                  type: 'text',
                  size: 'small'
                }}
                disabled={!isUserActive}
              >
                <Ban size={20} />
                Terminate User
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        </article>
      </article>
    </>
  )
}

export default GlobalServiceUserMFASetting
