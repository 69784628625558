import { useEffect } from 'react'
import { BREADCRUMB } from '../types'
import { useBreadcrumbStore } from './useBreadcrumbStore'

export const useBreadcrumb = (data: BREADCRUMB[]) => {
  const updateBreadcrumb = useBreadcrumbStore(state => state.updateBreadcrumb)

  useEffect(() => {
    if (data.length) updateBreadcrumb(data)

    return () => {
      updateBreadcrumb([])
    }
  }, [data, updateBreadcrumb])
}
