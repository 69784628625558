import { z } from 'zod'

export const schema = z.object({
  country: z.string().trim().min(1, 'Required!'),
  name: z.string().trim().min(1, 'Requried!'),
  phone_no: z.string().trim(),
  phone_code: z.string().trim(),
  description: z.string().trim()
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  country: '1',
  name: '',
  phone_code: '',
  phone_no: '',
  description: ''
}
