import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppRoleService, useDeleteService } from '$hooks/services'
import { useMount } from 'ahooks'
import RoleInfo from './RoleInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import { redirect, ROUTE_NAMES } from '$router/config'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { Button } from '@genie-fintech/ui/components'
import { ChevronLeft, PenLine, Trash2 } from 'lucide-react'
import { useCallback } from 'react'
import Header from '$layouts/LayoutWithSidebar/Header'
import { ButtonVariants } from '@genie-fintech/ui/style/element'
import { title } from '@genie-fintech/ui/style/typography'
import { container } from './styles.css'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import PermissionTable from './PermissionTable'
import UserTable from './UserTable'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const buttonVariants: ButtonVariants = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small'
}

const AppRoleDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { roleId, appId } = params

  const { role, fetchingAppRoleDetail, fetchAppRoleDetailAsync } =
    useAppRoleService()

  const { deleteRoleAsync, deletingRole } = useDeleteService()

  useMount(() => {
    if (!appId || !roleId) return
    fetchAppRoleDetailAsync({ application_id: appId, role_id: roleId })
  })

  useBreadcrumb([
    { name: 'Roles', path: ROUTE_NAMES.APP_ROLES },
    { name: role?.name ?? '' }
  ])

  const onDeleteRole = useCallback(() => {
    if (!appId || !roleId) return
    deleteRoleAsync({ appId, roleId })
  }, [appId, roleId, deleteRoleAsync])

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLES, { params })
  }, [params])

  const onEdit = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLE_EDIT, { params })
  }, [params])

  if (fetchingAppRoleDetail || !role) return <Loading />

  return (
    <>
      <Header style={{ gap: 8 }}>
        <Button styleVariants={buttonVariants} onClick={onBack}>
          <ChevronLeft size={16} />
          Back to listing
        </Button>

        <span className={title.two} style={{ paddingLeft: 4 }}>
          {role.name}
        </span>

        <span style={{ flex: 1 }} />

        <Button
          styleVariants={{
            kind: 'neutral',
            type: 'outlined',
            size: 'small'
          }}
          onClick={onEdit}
        >
          <PenLine size={16} />
          Edit
        </Button>
      </Header>

      <RoleInfo role={role} />

      <PermissionTable />

      <UserTable roleName={role.name} />

      <article className={container}>
        <article className={content}>
          <DangerZone type="role">
            <DeleteDoubleConfirmPopUp
              item={{ name: role.name, type: 'role' }}
              onExecute={onDeleteRole}
              loading={deletingRole}
            >
              <Button styleVariants={{ kind: 'danger', type: 'text' }}>
                Delete Role
                <Trash2 size={16} />
              </Button>
            </DeleteDoubleConfirmPopUp>
          </DangerZone>
        </article>
      </article>
    </>
  )
}

export default AppRoleDetail
