import { redirect, ROUTE_NAMES } from '$app/router/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { BaseText, Password } from '@genie-fintech/ui/components/hook-fields'
import { useMount, useRequest } from 'ahooks'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { Button } from '@genie-fintech/ui/components/Button'
import { Logo } from '$assets/svg'
import { copyRight, formContainer, card, container, desc } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Spinner } from '@genie-fintech/ui/components'
import CFTurnstile from '$components/hook-fields/CFTurnstile'
import { login } from '$services/api'
import { toast } from 'sonner'
import { CF_TURNSTILE_ENABLED } from '$app/constants'
import { z } from 'zod'
import { title } from '@genie-fintech/ui/style/typography'
import { flexColumn } from '$styles/common.css'
import { NoiseOverlay } from '$layouts/LayoutWithBackground/styles.css'

const captchaString = (() => {
  const str = z.string({ message: 'Must be a valid captcha token' })

  if (!CF_TURNSTILE_ENABLED) return str.optional()

  return str.min(1, { message: 'Captcha Required' })
})()

const loginSchema = z.object({
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  password: z.string().trim().min(1, 'Requried!'),
  cf_turnstile_token: captchaString
})

type TLoginFormValues = z.infer<typeof loginSchema>

const Login = () => {
  const { token, updateToken } = useAuthContext()

  const isMounted = useIsMounted()

  const loginRequest = useRequest(login, {
    manual: true,
    onSuccess: ({ data: { name } }) => {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
      toast.success(`Welcome ${name}!\nYou're all set to explore.`)
    },
    onFinally: (_, data) => {
      updateToken(data?.token)
    }
  })

  const { handleSubmit, control } = useForm<TLoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  useMount(() => {
    // if user is already logged in, redirect to index page
    if (token) {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
    }
  })

  return (
    <article className={container} data-ready={trueOrUndefined(isMounted)}>
      <span className={NoiseOverlay} />

      <article className={card}>
        <Logo color="absolute.light" />

        <form
          className={formContainer}
          onSubmit={handleSubmit(loginRequest.run)}
        >
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.one}>Login</p>

            <p className={desc}>
              To access the Carro SSO service dashboard, please login here.
            </p>
          </article>

          <article className={flexColumn} style={{ gap: 16 }}>
            <BaseText
              label="Email"
              inputProps={{ type: 'email', autoComplete: 'email' }}
              name="email"
              control={control}
              required
            />

            <Password
              label="Password"
              name="password"
              control={control}
              required
            />

            <CFTurnstile control={control} name="cf_turnstile_token" />

            <Button type="submit" disabled={loginRequest.loading}>
              {loginRequest.loading && <Spinner />}
              Login
            </Button>
          </article>
        </form>

        <p className={copyRight}>©Carro SSO Service. All rights reserved.</p>
      </article>
    </article>
  )
}

export default Login
