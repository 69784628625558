import { Clock } from 'lucide-react'
import ProfileCard from './components/ProfileCard'
import {
  container,
  joinOn,
  joinOnLabel,
  lastSignedIn,
  main,
  noWordWrap,
  sidebar,
  sidebarFooterItemLabel
} from './styles.css'
import { FC, PropsWithChildren } from 'react'
import SideMenu from './components/SideMenu'
import { USER_TYPE } from './types'
import { useProfileData } from './hooks/useProfileData'
import { formatDateWithGMT } from '$app/utils'
import { useProfileDataStore } from './hooks/useProfileDataStore'
import { useShallow } from 'zustand/react/shallow'
import Loading from '$components/Loading'
import VerifyEmailCard from './components/VerifyEmailCard'

type Props = {
  userType: USER_TYPE
  title?: string
  desc?: string
}

const UserProfileLayout: FC<PropsWithChildren<Props>> = ({
  children,
  userType
}) => {
  const { menus, isDataFetching } = useProfileData({
    userType
  })

  const { created_at, last_login_at, is_verified } =
    useProfileDataStore(useShallow(state => state.profile)) ?? {}

  if (isDataFetching) return <Loading />

  return (
    <article className={container}>
      <aside className={sidebar}>
        <ProfileCard />

        <SideMenu menus={menus} />

        {!is_verified && <VerifyEmailCard />}

        <article className={joinOn}>
          <span className={joinOnLabel}>Joined On</span>
          <span className={noWordWrap}>{formatDateWithGMT(created_at)}</span>
        </article>

        <article className={lastSignedIn}>
          <Clock size={16} />
          <span className={sidebarFooterItemLabel}>Last Signed In</span>
          <span className={noWordWrap}>{formatDateWithGMT(last_login_at)}</span>
        </article>
      </aside>

      <main className={main} children={children} />
    </article>
  )
}

export default UserProfileLayout
