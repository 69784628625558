import { cn } from '$app/utils'
import Loading from '$components/Loading'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { useAppGroupService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, borderNeutral20, flexRow } from '$styles/common.css'
import { useMount } from 'ahooks'
import { useCallback } from 'react'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useController, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { TAppGroupPayload } from '$services/api'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import FooterAction from '$components/FooterAction'
import { Button, Spinner } from '@genie-fintech/ui/components'
import CountryBadge from '$components/CountryBadge'
import { content } from '$layouts/LayoutWithSidebar/styles.css'
import { toClassNames } from '@genie-fintech/ui/functions'
import Phone from '$components/Phone'

const { colors } = themeVars

const AppGroupEdit = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    group,
    fetchAppGroupDetailAsync,
    fetchingAppGroupDetail,
    updateGroupAsync,
    updatingGroup
  } = useAppGroupService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const { field: phoneCodeField } = useController({
    name: 'phone_code',
    control
  })
  const { field: phoneNoField } = useController({ name: 'phone_no', control })

  useRedirectPrompt({ shouldPrompt: isDirty })

  useMount(() => {
    if (!appId || !groupId) return
    fetchAppGroupDetailAsync({ application_id: appId, group_id: groupId }).then(
      ({ data }) => {
        const { country, ...rest } = data

        const modifiedData: TFormValues = {
          country: `${country.id}`,
          ...rest
        }

        reset(modifiedData)
      }
    )
  })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_GROUP_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUP_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId || !groupId) return

    const { country, ...rest } = formValues

    const payload: TAppGroupPayload = {
      country_id: +country,
      ...rest
    }

    return updateGroupAsync(appId, groupId, payload).then(onSuccess)
  })

  if (fetchingAppGroupDetail || !group) return <Loading />

  return (
    <form
      className="flex flex-col max-w-[1056px] mx-auto gap-2 p-4"
      onSubmit={onSubmit}
    >
      <article
        className={cn(
          'flex flex-col rounded-lg',
          defaultBackground,
          borderNeutral20
        )}
      >
        <header
          className="flex items-center px-12 py-3 border-b"
          style={{ borderColor: colors.neutral[10] }}
        >
          <p
            className="text-xl font-semibold"
            style={{ color: colors.text.light }}
          >
            Edit {group.name}
          </p>
        </header>
        <article className={cn('flex flex-col px-12 py-7')}>
          <article className="grid lg:grid-cols-2 gap-8">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">GROUP INFO</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                View detailed information about each group to manage roles and
                permissions effectively.
              </p>
            </article>

            <article className="flex flex-col gap-5">
              <article className="flex flex-col gap-1">
                <p className="font-medium text-sm">Country</p>
                <CountryBadge name={group.country.name} />
              </article>

              <BaseText
                control={control}
                name="name"
                label="Group Name"
                required
              />

              <Phone
                label="Phone Number"
                inputProps={phoneNoField}
                callingCodeProps={phoneCodeField}
              />

              <Textarea
                name="description"
                control={control}
                label="Description"
              />
            </article>
          </article>
        </article>
      </article>

      <FooterAction>
        <article
          className={toClassNames(content, flexRow)}
          style={{ justifyContent: 'space-between' }}
        >
          <Button
            disabled={updatingGroup}
            styleVariants={{ type: 'text' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button type="submit" disabled={!isDirty || updatingGroup}>
            {updatingGroup && <Spinner />}
            Save Changes
          </Button>
        </article>
      </FooterAction>
    </form>
  )
}

export default AppGroupEdit
