import { WEBHOOK_EVENT_HISTORY } from '$services/api'

import { getTitle, WEBHOOK_EVENT_ACTION } from './constants'
import {
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { EventList } from './EventList'
import { FC, ReactNode } from 'react'
import TimelineItem from '$components/TimelineItem'
import { flexColumn } from '$styles/common.css'
import {
  table,
  tableBodyCell,
  tableContainer,
  tableHeadCell
} from './styles.css'

type Props = {
  data: WEBHOOK_EVENT_HISTORY
}

const TableHeadRow: FC<{ elements: ReactNode[] }> = ({ elements }) => (
  <tr className={tableRow}>
    {elements.map((element, index) => (
      <td key={index} className={tableHeadCell}>
        {element}
      </td>
    ))}
  </tr>
)

const TableBodyRow: FC<{ elements: ReactNode[] }> = ({ elements }) => (
  <tr className={tableRow}>
    {elements.map((element, index) => (
      <td key={index} className={tableBodyCell}>
        {element}
      </td>
    ))}
  </tr>
)

const AuditLogItem: FC<Props> = ({ data }) => {
  const { old_value, new_value } = data.log_details ?? {}

  const isUpdatedAction = data.action === WEBHOOK_EVENT_ACTION.UPDATED

  const isSameUrl = old_value?.url === new_value?.url

  const isSameWebhookEventsIds =
    old_value?.webhook_event_ids?.join() ===
    new_value?.webhook_event_ids?.join()

  const title = (() => {
    const extra = []

    if (isUpdatedAction) {
      if (!isSameUrl) extra.push('URL')
      if (!isSameWebhookEventsIds) extra.push('Webhook Events')
    }

    return `${getTitle(data.action)} ${extra.join(' and ')}.`
  })()

  return (
    <TimelineItem
      title={title}
      date={data.created_at}
      email={data.created_by.email}
      role={data.created_by.role}
    >
      <section className={tableContainer}>
        <main className={tableContainerInner}>
          <table className={table}>
            <tbody className={tableBody}>
              {isUpdatedAction && (
                <>
                  <TableHeadRow elements={['Changed value from', 'To']} />

                  {!isSameUrl && (
                    <TableBodyRow elements={[old_value?.url, new_value?.url]} />
                  )}

                  {!isSameWebhookEventsIds && (
                    <TableBodyRow
                      elements={[
                        <EventList
                          webhook_event_ids={old_value?.webhook_event_ids}
                        />,
                        <EventList
                          webhook_event_ids={new_value?.webhook_event_ids}
                        />
                      ]}
                    />
                  )}
                </>
              )}

              {!isUpdatedAction && (
                <>
                  <TableHeadRow elements={['Webhooks URL']} />

                  <TableBodyRow
                    elements={[
                      <article className={flexColumn} style={{ gap: 12 }}>
                        {old_value?.url ?? new_value?.url}

                        <EventList
                          webhook_event_ids={
                            old_value?.webhook_event_ids ??
                            new_value?.webhook_event_ids
                          }
                        />
                      </article>
                    ]}
                  />
                </>
              )}
            </tbody>
          </table>
        </main>
      </section>
    </TimelineItem>
  )
}

export default AuditLogItem
