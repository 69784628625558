import { useInert } from '$hooks/actions'
import { useBoolean } from 'ahooks'
import { useFocusTrap } from './useFocusTrap'
import { useCallback, useId } from 'react'
import useNoScrollY from './useNoScrollY'

type Props = {
  closeOnClickOutside: boolean
  isFocusMode: boolean
}

export const useModal = (props?: Partial<Props>) => {
  const id = useId()

  const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean()

  const containerRef = useInert(!open)

  const { closeOnClickOutside = true, isFocusMode } = { ...props }

  useFocusTrap({ open, containerRef })

  useNoScrollY(`modal-${id}`, open)

  const onOverlayClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (!closeOnClickOutside) return undefined

      if (e.target === e.currentTarget) {
        closeModal()
      }
    },
    [closeOnClickOutside, closeModal]
  )

  return {
    containerRef,
    closeOnClickOutside,
    isFocusMode,
    onOverlayClick,
    open,
    openModal,
    closeModal
  }
}
