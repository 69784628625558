import Loading from '$components/Loading'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { useAppPermissionService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { useCallback } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { TAppPermissionPayload } from '$services/api'
import { cn } from '$app/utils'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import PermissionRoles from '../Detail/PermissionRoles'
import FooterAction from '$components/FooterAction'
import { Button, Spinner } from '@genie-fintech/ui/components'

const { colors } = themeVars

const AppPermissionEdit = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    permission,
    fetchAppPermissionDetailAsync,
    fetchingAppPermissionDetail,
    updatePermissionAsync,
    updatingPermission
  } = useAppPermissionService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  useRedirectPrompt({ shouldPrompt: isDirty })

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  useMount(() => {
    if (!appId || !permissionId) return
    fetchAppPermissionDetailAsync({
      application_id: appId,
      permission_id: permissionId
    }).then(({ data }) => {
      const { roles, ...rest } = data

      const modifiedData: TFormValues = {
        roles: roles.map(v => v.id),
        ...rest
      }

      reset(modifiedData)
    })
  })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_PERMISSION_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSION_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId || !permissionId) return

    const payload: TAppPermissionPayload = formValues

    return updatePermissionAsync(appId, permissionId, payload).then(onSuccess)
  })

  if (fetchingAppPermissionDetail || !permission) return <Loading />

  return (
    <form
      className="flex flex-col max-w-[1056px] mx-auto gap-2"
      onSubmit={onSubmit}
    >
      <article
        className={cn(
          'flex flex-col rounded-lg',
          defaultBackground,
          borderNeutral20
        )}
      >
        <header
          className="flex items-center px-12 py-3 border-b"
          style={{ borderColor: colors.neutral[10] }}
        >
          <p
            className="text-xl font-semibold"
            style={{ color: colors.text.light }}
          >
            Edit {permission.name}
          </p>
        </header>

        <article className="flex px-12 py-7">
          <article className="grid lg:grid-cols-2 gap-8">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">PERMISSION INFO</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                Review detailed permissions to manage user access and
                functionality within the system.
              </p>
            </article>

            <article className="flex flex-col gap-5">
              <BaseText
                control={control}
                name="name"
                label="Permission Name"
                required
              />

              <BaseText
                control={control}
                name="module_name"
                label="Module Name"
                required
              />

              <Textarea
                name="description"
                control={control}
                label="Description"
              />
            </article>
          </article>
        </article>
      </article>

      <PermissionRoles selectedRoles={selectedRoles} onChange={replace} />

      <FooterAction fullWidth>
        <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
          <Button
            disabled={updatingPermission}
            styleVariants={{ type: 'text' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button type="submit" disabled={!isDirty || updatingPermission}>
            {updatingPermission && <Spinner />}
            Save Changes
          </Button>
        </article>
      </FooterAction>
    </form>
  )
}

export default AppPermissionEdit
