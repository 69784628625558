import LoginDevices from '$blocks/LoginDevices'
import EmptyData from '$components/EmptyData'
import { useGlobalAppUserService } from '$hooks/services'
import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import { innerMainContent } from '$layouts/UserProfileLayout/styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useMount } from 'ahooks'
import { container, innerContainer } from './styles.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { flexColumn } from '$styles/common.css'
import Loading from '$components/Loading'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const GlobalServiceUserDevicesLogin = () => {
  const userId = useProfileDataStore(state => state.profile?.id)

  const isMounted = useIsMounted()

  const { getAppUserDeviceLogin, isDevicesLoading, deviceLoginData } =
    useGlobalAppUserService()

  useMount(() => {
    if (!userId) return
    getAppUserDeviceLogin(userId)
  })

  useBreadcrumb([{ name: 'Devices Login' }])

  const { data: list = [] } = { ...deviceLoginData }

  const isEmpty = !list.length && !isDevicesLoading

  const hasData = !!list.length && !isDevicesLoading

  return (
    <>
      <MainHeader
        title="MANAGE ALL DEVICES"
        desc="Manage and review user's login devices for efficient system administration."
      />

      <article className={container} data-ready={trueOrUndefined(isMounted)}>
        <article className={innerMainContent} style={{ gap: 24 }}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>LOGIN DEVICES</p>
            <p className={footnote.one} style={{ color: colors.neutral[60] }}>
              These are the devices that user logged in. There might be multiple
              activity sessions from the same devices.
            </p>
          </article>

          <article className={innerContainer}>
            {isDevicesLoading && <Loading />}

            {isEmpty && <EmptyData type="devices" />}

            {hasData && <LoginDevices devices={list} />}
          </article>
        </article>
      </article>
    </>
  )
}

export default GlobalServiceUserDevicesLogin
