import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { ChevronLeft } from 'lucide-react'
import { backButton, container, pageTitle } from './styles.css'
import SidebarToggleMenu from '$components/SideBarToggleMenu'
import { useCallback } from 'react'
import { redirect } from '$router/config'
import ThemeToggleButton from '$components/ThemeToggleButton'
import Profile from '$components/Profile'
import { useBreadcrumbStore } from '$components/Breadcrumb/hooks/useBreadcrumbStore'
import { MainSidebarMenu } from '$components/SideBarToggleMenu/constants'

const MobileNavbarContent = () => {
  const { route } = useRouteSummary()

  const breadcrumb = useBreadcrumbStore(state => state.breadcrumb)

  const hasParent = !!breadcrumb.length

  const mainMenu = MainSidebarMenu.find(menu =>
    [menu.to, ...menu.subPath].includes(route.name!)
  )

  const onBack = useCallback(() => {
    const path = breadcrumb.at(-2)?.path ?? mainMenu?.to
    if (!path) return
    redirect(path, { params: route.params })
  }, [breadcrumb, mainMenu, route.params])

  const name = breadcrumb.at(-1)?.name ?? mainMenu?.name
  return (
    <article className={container}>
      {hasParent ? (
        <button type="button" onClick={onBack} className={backButton}>
          <ChevronLeft size={24} />
        </button>
      ) : (
        <SidebarToggleMenu />
      )}

      <span className={pageTitle}>{name}</span>

      <ThemeToggleButton />

      {hasParent ? <SidebarToggleMenu /> : <Profile />}
    </article>
  )
}

export default MobileNavbarContent
