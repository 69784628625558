import { tooltipProps } from '$app/utils'
import { button } from '@genie-fintech/ui/style/element'
import { TextSelect } from 'lucide-react'

const emptyValue = ['N/A']

const DescriptionTableColumn = ({ desc }: { desc?: string }) => {
  if (!desc) return '-'

  if (emptyValue.includes(desc)) return '-'

  return (
    <button
      className={button({
        kind: 'neutral',
        type: 'text',
        size: 'small',
        category: 'icon'
      })}
      {...tooltipProps(desc)}
    >
      <TextSelect size={20} />
    </button>
  )
}

export default DescriptionTableColumn
