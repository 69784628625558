import ModKey from '$components/ModKey'
import {
  SaveKey,
  useSaveEventListener
} from '$hooks/actions/useSaveEventListener'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { FC, useRef } from 'react'
import { container } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import useResponsive from '$hooks/actions/useResponsive'
import { flexRow } from '$styles/common.css'
import { footnote } from '@genie-fintech/ui/style/typography'
import { status } from '@genie-fintech/ui/style/element'

const { colors } = themeVars

type Props = {
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
}

const SaveWithShortCutButton: FC<Props> = ({ disabled, loading }) => {
  const responsive = useResponsive()

  const saveButtonRef = useRef<HTMLButtonElement>(null)

  useSaveEventListener(() => {
    saveButtonRef.current?.click()
  })

  const isDisabled = disabled || loading

  return (
    <article className={container}>
      <Button buttonRef={saveButtonRef} type="submit" disabled={isDisabled}>
        {loading && <Spinner />}
        Save Changes
      </Button>

      <article
        className={flexRow}
        style={{ gap: 8 }}
        hidden={trueOrUndefined(!responsive.tablet)}
      >
        <p className={footnote.one} style={{ color: colors.neutral[60] }}>
          Save Changes
        </p>

        <span
          className={status({ kind: 'grey', size: 'small', type: 'faint' })}
        >
          <ModKey />
          <span>+</span>
          <span className="capitalize">{SaveKey}</span>
        </span>
      </article>
    </article>
  )
}

export default SaveWithShortCutButton
