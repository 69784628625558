import { FC, PropsWithChildren } from 'react'
import { container, nav, main } from './styles.css'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import NavbarContent from './NavbarContent'
import MobileNavbarContent from './MobileNavbarContent'

const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted()

  return (
    <article className={container}>
      <nav className={nav} data-ready={trueOrUndefined(isMounted)}>
        <NavbarContent />

        <MobileNavbarContent />
      </nav>

      <main className={main}>{children}</main>
    </article>
  )
}

export default PageLayout
