import { outlineNeutralButtonVariant } from '$app/constants'
import { getPhone } from '$app/utils'
import Loading from '$components/Loading'
import Select from '$components/Select'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useGlobalDashboardUserService } from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import MainHeader from '$layouts/UserProfileLayout/components/MainHeader'
import { useProfileDataStore } from '$layouts/UserProfileLayout/hooks/useProfileDataStore'
import {
  innerMainContent,
  mainContent
} from '$layouts/UserProfileLayout/styles.css'
import { flexColumn, flexRow } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount, useToggle } from 'ahooks'
import { PenLine } from 'lucide-react'
import { useCallback } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { grid } from './styles.css'
import { useBreadcrumb } from '$components/Breadcrumb/hooks/useBreadcrumb'

const { colors } = themeVars

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  role: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  type: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  })
})

type TFormValues = z.infer<typeof schema>

const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  type: { label: '', value: '' },
  role: { label: '', value: '' },
  email: '',
  phone_code: { label: '', value: '' },
  phone_no: ''
}

const GlobalDashboardUserInfo = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { dashboardUserId } = params

  const [isEditMode, { setLeft: setEditFalse, setRight: setEditTrue }] =
    useToggle()

  const updateProfilePartially = useProfileDataStore(
    state => state.updateProfilePartially
  )

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const {
    fetchUserRoleSelect,
    fetchUserTypeSelect,
    fetchGlobalDashboardUserDetail,
    dashboardUser,
    filter_user_roles_options,
    user_roles_options,
    user_types_options,
    updateGlobalDashboardUserAsync,
    updatingGlobalDashboardUser
  } = useGlobalDashboardUserService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  useMount(() => {
    if (!dashboardUserId) return
    fetchUserRoleSelect()
    fetchUserTypeSelect()
    fetchGlobalDashboardUserDetail(dashboardUserId)
  })

  useBreadcrumb([{ name: 'Profile' }])

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue
  } = methods

  const selectedAccountType = useWatch({ name: 'type.value', control })

  const onEdit = useCallback(() => {
    const { phone_code, phone_no, role, type } = dashboardUser ?? {}
    const modifiedRole =
      user_roles_options.find(d => d.value === role) ?? DEFAULT_FORM_VALUES.role
    const modifiedType =
      user_types_options.find(d => d.value === type) ?? DEFAULT_FORM_VALUES.type
    reset({
      ...dashboardUser,
      phone_no: phone_no ?? '',
      phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
      role: modifiedRole,
      type: modifiedType
    })
    setEditTrue()
  }, [
    setEditTrue,
    dashboardUser,
    user_roles_options,
    user_types_options,
    reset
  ])

  const onCancel = useCallback(() => {
    setEditFalse()
    reset(DEFAULT_FORM_VALUES)
  }, [setEditFalse, reset])

  const onSubmit = useCallback(
    ({ type, role, phone_code, ...rest }: TFormValues) => {
      if (!dashboardUserId) return

      const payload = {
        ...rest,
        phone_code: phone_code.value,
        role: role.value,
        type: type.value
      }

      updateGlobalDashboardUserAsync(dashboardUserId, payload).then(() => {
        fetchGlobalDashboardUserDetail(dashboardUserId)
        updateProfilePartially(payload)
        onCancel()
      })
    },
    [
      dashboardUserId,
      updateGlobalDashboardUserAsync,
      onCancel,
      updateProfilePartially,
      fetchGlobalDashboardUserDetail
    ]
  )

  if (!dashboardUser) return <Loading />

  return (
    <>
      <MainHeader
        title="User Profile"
        desc="Manage and review user accounts for efficient system administration."
      />

      <article className={mainContent}>
        <form
          className={innerMainContent}
          style={{ gap: 24 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <article
            className={flexRow}
            style={{ gap: 8, justifyContent: 'space-between' }}
          >
            <article className={flexColumn} style={{ gap: 4 }}>
              <p className={title.two}>INFORMATIONS</p>
              <p className={footnote.one} style={{ color: colors.neutral[60] }}>
                Some of the user informations can be editable.
              </p>
            </article>

            <article className={flexRow} style={{ gap: 12 }}>
              {isEditMode && (
                <>
                  <Button
                    styleVariants={outlineNeutralButtonVariant}
                    onClick={onCancel}
                    disabled={updatingGlobalDashboardUser}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    styleVariants={{ size: 'small' }}
                    disabled={updatingGlobalDashboardUser || !isDirty}
                  >
                    {updatingGlobalDashboardUser && <Spinner />}
                    Save Changes
                  </Button>
                </>
              )}

              {!isEditMode && (
                <Button
                  styleVariants={outlineNeutralButtonVariant}
                  onClick={onEdit}
                >
                  <PenLine size={16} />
                  Edit
                </Button>
              )}
            </article>
          </article>

          <article className={grid}>
            {isEditMode && (
              <>
                <article>
                  <BaseText
                    control={control}
                    name="name"
                    label="Name"
                    required
                  />
                </article>

                <article>
                  <BaseText
                    control={control}
                    name="email"
                    label="Email"
                    required
                  />
                </article>

                <article className="relative">
                  <BaseText
                    control={control}
                    name="phone_no"
                    label="Phone Number"
                    inputProps={{ className: 'pl-[100px]' }}
                  />

                  <article className="absolute bottom-0 left-0 w-[100px]">
                    <Controller
                      name="phone_code"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            value={field.value}
                            onChange={field.onChange}
                            options={phoneOptions}
                            type="sub"
                          />
                        )
                      }}
                    />
                  </article>
                </article>

                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <article className="flex flex-col gap-y-1">
                        <label className="text-sm font-medium">
                          Account Type
                        </label>
                        <Select
                          {...field}
                          options={user_types_options}
                          onChange={value => {
                            field.onChange(value)
                            setValue('role', DEFAULT_FORM_VALUES.role)
                          }}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <p className="text-xs text-[--colors-danger-default]">
                            {error.message}
                          </p>
                        )}
                      </article>
                    )
                  }}
                />

                <Controller
                  name="role"
                  control={control}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <article className="flex flex-col gap-y-1">
                        <label className="text-sm font-medium">
                          Account Role
                        </label>
                        <Select
                          {...field}
                          options={filter_user_roles_options(
                            selectedAccountType
                          )}
                          error={!!error?.message}
                        />
                        {error?.message && (
                          <p className="text-xs text-[--colors-danger-default]">
                            {error.message}
                          </p>
                        )}
                      </article>
                    )
                  }}
                />
              </>
            )}

            {!isEditMode && (
              <>
                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Name
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {dashboardUser.name}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Email
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {dashboardUser.email}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Phone Number
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {getPhone(dashboardUser.phone_code, dashboardUser.phone_no)}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Account Type
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {user_types_options.find(
                      d => d.value === dashboardUser.type
                    )?.label ?? '-'}
                  </span>
                </article>

                <article className={flexColumn} style={{ gap: 2 }}>
                  <span
                    className={footnote.two}
                    style={{ color: colors.neutral[60] }}
                  >
                    Account Role
                  </span>
                  <span
                    className={title.three}
                    style={{ color: colors.text.light }}
                  >
                    {user_roles_options.find(
                      d => d.value === dashboardUser.role
                    )?.label ?? '-'}
                  </span>
                </article>
              </>
            )}
          </article>
        </form>
      </article>

      {/* <article className={mainSubContainerStyle}>
        <form
          className="grid md:grid-cols-2 gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <article className="col-span-2 flex items-center gap-2 justify-between">
            <article className="flex flex-col gap-2">
              <p className="font-semibold">Personal Details</p>

              <p className="text-xs text-[--colors-neutral-50]">
                Your personal details can be editable on Carro SSO.
              </p>
            </article>

            <article className="flex items-center gap-2">
              {!isEditMode && (
                <Button
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                  onClick={onEdit}
                >
                  Edit
                </Button>
              )}

              {isEditMode && (
                <>
                  <Button
                    disabled={updatingGlobalDashboardUser}
                    styleVariants={{
                      kind: 'neutral',
                      type: 'outlined'
                    }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    disabled={!isDirty || updatingGlobalDashboardUser}
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              )}
            </article>
          </article>
          {isEditMode ? (
            <BaseText control={control} name="name" label="Name" required />
          ) : (
            <Info label="Name" value={dashboardUser.name} />
          )}

          {isEditMode ? (
            <BaseText control={control} name="email" label="Email" required />
          ) : (
            <Info label="Email" value={dashboardUser.email} />
          )}

          {isEditMode ? (
            <article className="relative">
              <BaseText
                control={control}
                name="phone_no"
                label="Phone Number"
                inputProps={{ className: 'pl-[100px]' }}
              />

              <article className="absolute bottom-0 left-0 w-[100px]">
                <Controller
                  name="phone_code"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        options={phoneOptions}
                        type="sub"
                      />
                    )
                  }}
                />
              </article>
            </article>
          ) : (
            <Info
              label="Phone Number"
              value={getPhone(dashboardUser.phone_code, dashboardUser.phone_no)}
            />
          )}

          {isEditMode ? (
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <article className="flex flex-col gap-y-1">
                    <label className="text-sm font-medium">Account Type</label>
                    <Select
                      {...field}
                      options={user_types_options}
                      onChange={value => {
                        field.onChange(value)
                        setValue('role', DEFAULT_FORM_VALUES.role)
                      }}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <p className="text-xs text-[--colors-danger-default]">
                        {error.message}
                      </p>
                    )}
                  </article>
                )
              }}
            />
          ) : (
            <Info
              label="Account Type"
              value={
                user_types_options.find(d => d.value === dashboardUser.type)
                  ?.label ?? ''
              }
            />
          )}

          {isEditMode ? (
            <Controller
              name="role"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <article className="flex flex-col gap-y-1">
                    <label className="text-sm font-medium">Account Role</label>
                    <Select
                      {...field}
                      options={filter_user_roles_options(selectedAccountType)}
                      error={!!error?.message}
                    />
                    {error?.message && (
                      <p className="text-xs text-[--colors-danger-default]">
                        {error.message}
                      </p>
                    )}
                  </article>
                )
              }}
            />
          ) : (
            <Info
              label="Account Role"
              value={
                user_roles_options.find(d => d.value === dashboardUser.role)
                  ?.label ?? ''
              }
            />
          )}
        </form>
      </article> */}
    </>
  )
}

export default GlobalDashboardUserInfo
