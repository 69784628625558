import { FC, PropsWithChildren, useCallback } from 'react'
import { Drawer as VaulDrawer, DialogProps } from 'vaul'
import {
  drawerContainer,
  drawerHeader,
  drawerInnerContainer,
  drawerMain,
  drawerOverlay,
  drawerWidth
} from './styles.css'
import { getDrawerEdgeStyle } from './utils'
import { Button } from '@genie-fintech/ui/components'
import { ChevronLeft } from 'lucide-react'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { toClassNames } from '@genie-fintech/ui/functions'

type Props = DialogProps & {
  title?: string
  withCloseButton?: boolean
  width?: number
  className?: string
}

const Drawer: FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const {
    title,
    withCloseButton,
    width = 600,
    direction = 'right',
    className,
    ...dialogProps
  } = props

  const showHeader = !!title || !!withCloseButton

  const { onOpenChange } = dialogProps

  const onClose = useCallback(() => {
    onOpenChange?.(false)
  }, [onOpenChange])

  return (
    <VaulDrawer.Root direction={direction} {...dialogProps}>
      <VaulDrawer.Portal>
        <VaulDrawer.Overlay className={drawerOverlay} />
        <VaulDrawer.Content
          style={{
            ...getDrawerEdgeStyle(),
            ...assignInlineVars({ [drawerWidth]: `${width}px` })
          }}
          className={drawerContainer}
          aria-describedby={undefined}
        >
          <article className={drawerInnerContainer}>
            <VaulDrawer.Title asChild>
              {showHeader ? (
                <header className={drawerHeader}>
                  {withCloseButton && (
                    <Button
                      styleVariants={{
                        kind: 'neutral',
                        type: 'outlined',
                        size: 'small'
                      }}
                      onClick={onClose}
                    >
                      <ChevronLeft size={16} />
                      Back
                    </Button>
                  )}

                  {!!title && <h1>{title}</h1>}
                </header>
              ) : (
                /** hiding browser warning */
                <span hidden>Drawer Header</span>
              )}
            </VaulDrawer.Title>

            <main
              className={toClassNames(drawerMain, className)}
              children={children}
            />
          </article>
        </VaulDrawer.Content>
      </VaulDrawer.Portal>
    </VaulDrawer.Root>
  )
}

export default Drawer
