import { pluralize } from '$app/utils'
import Pagination from '$components/Pagination'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppGroupService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { TAppGroupUserResponse } from '$services/api'
import { flexColumn, flexRow } from '$styles/common.css'
import { FC, useEffect, useState } from 'react'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { themeVars } from '@genie-fintech/ui/style/theme'
import SearchBox from '$components/SearchBox'
import Table, { Column } from '$components/Table'
import { container } from './styles.css'
import { viewButton } from '$components/Table/styles.css'
import ActionLink from '$components/Table/ActionLink'
import PreviewRoleDrawer from '$blocks/PreviewRoleDrawer'

const { colors } = themeVars

type ColumnData = TAppGroupUserResponse['data'][number]

type Props = {
  groupName: string
}

const GroupUsers: FC<Props> = ({ groupName }) => {
  const [selectedUser, setSelectedUser] = useState<ColumnData>()

  const {
    route: { params }
  } = useRouteSummary()

  const { appId, groupId } = params

  const {
    fetchAppGroupUser,
    fetchingAppGroupUser,
    groupUsers: { list, meta }
  } = useAppGroupService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !groupId) return
    fetchAppGroupUser({
      page,
      per_page: perPage,
      application_id: appId,
      group_id: groupId,
      q: debouncedSearchValue
    })
  }, [appId, groupId, fetchAppGroupUser, page, perPage, debouncedSearchValue])

  const columns: Column<ColumnData>[] = [
    {
      name: 'No',
      render: ({ index }) => (page - 1) * perPage + index + 1
    },
    {
      name: 'Username',
      dataIndex: 'name'
    },
    {
      name: 'Email',
      dataIndex: 'email'
    },
    {
      name: 'Role',
      dataIndex: 'role_count',
      render: ({ record }) => (
        <button className={viewButton} onClick={() => setSelectedUser(record)}>
          View
        </button>
      )
    },
    {
      name: '',
      render: ({ record }) => (
        <ActionLink
          to={ROUTE_NAMES.APP_USER_DETAIL}
          options={{
            params: { ...params, userId: record.id }
          }}
        />
      )
    }
  ]

  return (
    <>
      <article className={container}>
        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={title.three}>{groupName}'s USERS</p>
          <p className={footnote.one} style={{ color: colors.neutral[60] }}>
            Define and assign roles to control access and permissions within the
            system.
          </p>
        </article>

        <article className={flexColumn} style={{ gap: 16, flex: 1 }}>
          <article className={flexRow} style={{ gap: 4 }}>
            <article style={{ flex: 1, maxWidth: 560 }}>
              <SearchBox
                value={searchValue}
                onChange={updateSearchValue}
                placeholder="Search here..."
              />
            </article>

            <span style={{ flex: 1 }} />

            {!!meta?.total && (
              <span
                className={title.six}
                style={{
                  color: colors.text.disabled,
                  textTransform: 'uppercase'
                }}
              >
                {pluralize(meta.total, 'user')}
              </span>
            )}
          </article>

          <Table columns={columns} data={list} loading={fetchingAppGroupUser} />

          {!!list.length && <Pagination meta={meta} pagerProps={pagerProps} />}
        </article>
      </article>

      <PreviewRoleDrawer
        user={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />
    </>
  )
}

export default GroupUsers
