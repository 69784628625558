import { GENDER_OPTIONS } from '$app/constants'
import { cn, pluralize } from '$app/utils'
import FooterAction from '$components/FooterAction'
import Loading from '$components/Loading'
import Select from '$components/Select'
import UserQueryMessage from '$components/UserQueryMessage'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import {
  useAppUserService,
  useGlobalAppUserService,
  usePasswordSettingValues
} from '$hooks/services'
import { useCountryStore } from '$hooks/stores'
import {
  DEFAULT_FORM_VALUES,
  schema,
  TFormValues
} from '$pages/Apps/AppUser/constants'
import UserRoles from '$pages/Apps/AppUser/Create/Form/UserRoles'
import { ROUTE_NAMES } from '$router/config'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText, BaseTextarea } from '@genie-fintech/ui/components/fields'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { useBoolean, useMount, useToggle } from 'ahooks'
import { ArrowRight } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import {
  Controller,
  useController,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form'
import { TSavedUserInfo } from '../types'
import UpdatePasswordBlock from '$blocks/UpdatePassword'
import { usePasswordPolicyStore } from '$hooks/stores/usePasswordPolicyStore'
import { toast } from 'sonner'
import LoginUserType from '$components/LoginUserType'
import { SERVICE_USER_TYPE } from '$services/api'
import { footnote, title } from '@genie-fintech/ui/style/typography'

const { colors } = themeVars

interface IProps {
  userId: string
  email: string
  onQueryAgain: VoidFunction
  onSuccess: (value: TSavedUserInfo) => void
}

const DefaultValueItem = ({
  label,
  value
}: {
  label: string
  value: string
}) => {
  return (
    <article>
      <p className={footnote.two} style={{ color: colors.neutral[60] }}>
        {label}
      </p>
      <p className={title.three} style={{ color: colors.text.light }}>
        {value}
      </p>
    </article>
  )
}

const UserForm = ({ userId, email, onQueryAgain, onSuccess }: IProps) => {
  const [step, setStep] = useState(1)

  const [password, setPassword] = useState('')

  const [hasError, { setTrue, setFalse }] = useBoolean()

  const [isNextSignIn, { toggle }] = useToggle(true)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const { fetchGlobalAppUserDetailAsync, fetchingGlobalAppUserDetail } =
    useGlobalAppUserService()

  const {
    saveUserAsync,
    savingUser,
    attachRoleToUserAsync,
    attachingRoleToUser
  } = useAppUserService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    fetchDefaultPasswordSettingValuesAsync,
    defaultPasswordSettingValues
  } = usePasswordSettingValues()

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { isDirty, errors }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  const name = useWatch({ name: 'name', control })

  const passwordRules = usePasswordPolicyStore(state => state.passwordRules)

  const { field: userTypeField } = useController({ name: 'user_type', control })

  useRedirectPrompt({ shouldPrompt: isDirty })

  useMount(() => {
    if (!userId) {
      fetchDefaultPasswordSettingValuesAsync()
      reset({ ...DEFAULT_FORM_VALUES, email })
      return
    }

    fetchGlobalAppUserDetailAsync(userId).then(({ data }) => {
      const { gender, phone_code, phone_no, date_of_birth, address, ...rest } =
        data

      const modifiedData: TFormValues = {
        roles: [],
        gender: GENDER_OPTIONS.find(d => d.value === gender) ?? {
          label: '',
          value: ''
        },
        address: address ?? '',
        date_of_birth: date_of_birth ?? '',
        phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
        phone_no: phone_no ?? '',
        ...rest
      }

      reset(modifiedData)
    })
  })

  const onChangePassword = useCallback(
    (pwd: string) => {
      setPassword(pwd)
      setFalse()
    },
    [setFalse]
  )

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USERS, { params })
  }, [proxyRedirect, params])

  const onBack = useCallback(() => {
    if (step === 1) {
      onQueryAgain()
    }
    setStep(prev => prev - 1)
  }, [step, onQueryAgain])

  const isPwdValid = useMemo(
    () =>
      userId
        ? true
        : passwordRules.every(d => !!password && d.regex.test(password)),
    [userId, password, passwordRules]
  )

  const onNext = useCallback(async () => {
    const isValid = await trigger(['name', 'email'])

    if (!isPwdValid) {
      toast.error('Password must be valid with all password policies')
      setTrue()
      return
    }

    if (isValid && isPwdValid) setStep(prev => prev + 1)
  }, [trigger, isPwdValid, setTrue])

  const onSubmit = handleSubmit(
    ({ gender, phone_code, roles, user_type, ...rest }) => {
      if (!appId) return

      if (userId) {
        attachRoleToUserAsync(appId, userId, roles).then(() => {
          onSuccess({ name: name, password, id: userId })
        })
        return
      }

      saveUserAsync(appId, {
        password,
        gender: gender.value,
        phone_code: phone_code.value,
        roles,
        should_reset_password: isNextSignIn,
        is_internal: user_type === SERVICE_USER_TYPE.INTERNAL,
        ...rest
      }).then(({ data }) => {
        onSuccess({ name: name, password, id: data.id })
      })
    }
  )

  const {
    access_token_expires_in,
    password_expiration_days,
    password_history_depth,
    mfa_enabled
  } =
    defaultPasswordSettingValues.find(d => d.type === userTypeField.value) ?? {}

  const isProcessing = savingUser || attachingRoleToUser

  if (fetchingGlobalAppUserDetail) return <Loading />

  return (
    <>
      <form className="flex flex-col gap-1.5 mb-[60px]" onSubmit={onSubmit}>
        {step === 1 && (
          <>
            <article
              className={cn(
                'flex flex-col rounded-lg',
                defaultBackground,
                borderNeutral20
              )}
            >
              <header
                className="flex px-12 py-3 border-b"
                style={{ borderColor: colors.neutral[10] }}
              >
                <p
                  className="text-xl font-semibold"
                  style={{ color: colors.text.light }}
                >
                  Add New User
                </p>
              </header>
              <article className="flex flex-col px-12 py-7 gap-10">
                <UserQueryMessage
                  title={
                    userId
                      ? 'This user is already in the Carro SSO system.'
                      : 'This user is a new user for Carro SSO system.'
                  }
                  type="success"
                >
                  <Button
                    onClick={onQueryAgain}
                    styleVariants={{ kind: 'success', size: 'small' }}
                  >
                    Change email or query again
                    <ArrowRight size={16} />
                  </Button>
                </UserQueryMessage>

                <article className="grid md:grid-cols-2 gap-16">
                  <article className="flex flex-col gap-1">
                    <p className="font-semibold">USER INFO</p>
                    <p
                      className="text-xs"
                      style={{ color: colors.neutral[50] }}
                    >
                      Access detailed information about each user to manage
                      their settings and permissions.
                    </p>
                  </article>

                  <article className="flex flex-col gap-5">
                    <article className="flex flex-col gap-5">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              label="Name"
                              required
                              disabled={!!userId}
                              error={!!error?.message}
                              message={error?.message}
                              inputProps={{
                                ...field,
                                type: 'text',
                                disabled: !!userId
                              }}
                            />
                          )
                        }}
                      />

                      <Controller
                        name="date_of_birth"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseText
                              label="Date of Birth"
                              disabled={!!userId}
                              error={!!error?.message}
                              message={error?.message}
                              inputProps={{
                                ...field,
                                type: 'date',
                                disabled: !!userId
                              }}
                            />
                          )
                        }}
                      />

                      <Controller
                        name="gender"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <article className="flex flex-col gap-y-1">
                              <label className="text-sm font-medium">
                                Gender
                              </label>
                              <Select
                                {...field}
                                options={GENDER_OPTIONS}
                                error={!!error?.message}
                                disabled={!!userId}
                              />
                              {error?.message && (
                                <p className="text-xs text-[--colors-danger-default]">
                                  {error.message}
                                </p>
                              )}
                            </article>
                          )
                        }}
                      />

                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => {
                          return (
                            <article className="flex flex-col gap-1">
                              <BaseText
                                label="Email"
                                required
                                disabled
                                inputProps={{
                                  value: field.value,
                                  readOnly: true,
                                  type: 'email',
                                  disabled: !!userId
                                }}
                              />

                              <article className="flex justify-end">
                                <Button
                                  styleVariants={{ type: 'text' }}
                                  onClick={onQueryAgain}
                                >
                                  Change Email
                                  <ArrowRight size={16} />
                                </Button>
                              </article>
                            </article>
                          )
                        }}
                      />

                      <article className="relative">
                        <Controller
                          name="phone_no"
                          control={control}
                          render={({ field }) => {
                            return (
                              <BaseText
                                label="Phone Number"
                                disabled={!!userId}
                                inputProps={{
                                  ...field,
                                  disabled: !!userId,
                                  className: 'pl-[100px]'
                                }}
                              />
                            )
                          }}
                        />

                        <article className="absolute bottom-0 left-0 w-[100px]">
                          <Controller
                            name="phone_code"
                            control={control}
                            render={({ field }) => {
                              return (
                                <Select
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={phoneOptions}
                                  type="sub"
                                  disabled={!!userId}
                                />
                              )
                            }}
                          />
                        </article>
                      </article>

                      <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <BaseTextarea
                              textareaProps={{ ...field }}
                              label="Address"
                              error={!!error?.message}
                              message={error?.message}
                              disabled={!!userId}
                            />
                          )
                        }}
                      />
                    </article>
                  </article>
                </article>
              </article>
            </article>

            {
              <LoginUserType
                value={userTypeField.value as SERVICE_USER_TYPE}
                onChange={userId ? undefined : userTypeField.onChange}
              >
                {userId ? null : (
                  <article
                    className={cn(
                      'flex flex-col rounded gap-6 p-8',
                      defaultBackground,
                      borderNeutral20
                    )}
                  >
                    <h3
                      className={title.two}
                      style={{
                        color: colors.text.disabled,
                        textTransform: 'uppercase'
                      }}
                    >
                      DEFAULT RULES FOR {userTypeField.value} USERS
                    </h3>

                    <article className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                      <DefaultValueItem
                        label="Password Expiry Every"
                        value={
                          password_expiration_days
                            ? pluralize(password_expiration_days, 'day')
                            : 'No Expired'
                        }
                      />

                      <DefaultValueItem
                        label="Daily Login Required"
                        value={
                          access_token_expires_in
                            ? pluralize(access_token_expires_in, 'day')
                            : 'No Required'
                        }
                      />

                      <DefaultValueItem
                        label="Previous Passwords can not be Reused"
                        value={pluralize(password_history_depth, 'Password')}
                      />

                      <DefaultValueItem
                        label="Multi-factor Authentication"
                        value={mfa_enabled ? 'Enabled' : 'Disabled'}
                      />
                    </article>
                  </article>
                )}
              </LoginUserType>
            }

            {!userId && (
              <UpdatePasswordBlock
                onChangePwd={onChangePassword}
                pwd={password}
                nextSignIn={isNextSignIn}
                onChangeNextSignIn={toggle}
                error={hasError}
              />
            )}
          </>
        )}

        {step === 2 && (
          <UserRoles
            roles={selectedRoles}
            onChange={replace}
            errorMessage={errors.roles?.message}
            appId={appId}
          />
        )}

        {step < 3 && (
          <FooterAction fullWidth>
            <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={isProcessing}
                onClick={onBack}
              >
                Back
              </Button>

              <article className="flex-1 flex justify-end gap-2">
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'text' }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                {step === 1 && (
                  <Button disabled={!isDirty && !userId} onClick={onNext}>
                    Next
                  </Button>
                )}

                {step === 2 && (
                  <Button type="submit" disabled={isProcessing}>
                    {isProcessing && <Spinner />}
                    Create
                  </Button>
                )}
              </article>
            </article>
          </FooterAction>
        )}
      </form>
    </>
  )
}

export default UserForm
